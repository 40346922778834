import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output, SimpleChanges,
} from '@angular/core';
import {SurveyService} from '../../../services/surveys.service';
import {NgForm} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ToastService} from '../../../services/general/toast.service';
import {ActivatedRoute, Route, Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray} from '@angular/cdk/drag-drop';
import {OrganizationService} from '../../../services/organization.service';
import {UserService} from '../../../services/user.service';
import {AlertService} from '../../../services/general/alert.service';

@Component({
  selector: 'app-survey-details',
  templateUrl: './survey-details.component.html',
  styleUrls: ['./survey-details.component.scss'],
})
export class SurveyDetailsComponent implements OnInit {
  @Input() projectId: any;
  @Input() surveyId: any;
  @Input() surveyObj: any;
  @Input() isResponse = false;
  @Output() surveyAdded = new EventEmitter();
  @Output() canceled = new EventEmitter();
  @Output() surveyChanged = new EventEmitter();


  constructor(
    public surveyService: SurveyService,
    public translateService: TranslateService,
    public toast: ToastService,
    public route: ActivatedRoute,
    public organizationService: OrganizationService,
    public userService: UserService,
    public alert: AlertService,
    public router: Router,
  ) {
  }

  loading = false;
  createMode = true;
  survey = {
    id: null,
    title: '',
    description: '',
    edit_after_submit: false,
    multiple_user_responses_is_enabled: false,
    visibility_type: 'PRIVATE',
    attachments_enabled: true,
    questions: [],
    members: [],
    attachments: [],
    email_template: null,
    is_draft: false,
    organization_project_id: null,
  };
  sections = null;
  questions_filters = {
    selected_section: null,
    only_not_answered: false,
    accordions_opened: true,
  };
  previewMode = false;
  email_templates = [];
  private questionIdCounter = 1;
  private memberIdCounter = 1;

  ngOnInit(): void {
    this.init();
  }

  init() {
    this.getSections();
    if (this.userService?.currentOrganization?.id) {
      this.organizationService.allOrganizationTemplatesEmail(this.userService?.currentOrganization?.id, (data) => {
        this.email_templates = data;
      });
    }


    if (this.isResponse) {
      this.createMode = false;
    }

    if (this.surveyObj) {
      this.survey = this.surveyObj;
      return;
    }

    if (!this.projectId) {
      this.canceled.emit();
    }

    if (this.surveyId) {
      this.createMode = false;
      this.getSurvey();
    } else {
      this.addQuestion();
      this.survey.members.push({
        id: this.memberIdCounter++,
        name: '',
        email: '',
      });
      this.surveyChanged.emit(this.survey);
    }
  }

  handleSurveyChanged() {
    this.surveyChanged.emit(this.survey);
  }

  getSurvey() {
    this.loading = true;
    this.surveyService.surveyDetails(this.surveyId, (result) => {
      this.survey = result;
      this.survey.attachments = result.attachment_files?.concat(result.attachment_pubmeds);
      const maxQuestionId = result.questions.reduce((maxId, question) => {
        return Math.max(maxId, question.id);
      }, 1);
      this.questionIdCounter = maxQuestionId + 1;
      this.surveyChanged.emit(this.survey);
      if (result?.is_draft) {
        this.createMode = true;
      }
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  get surveyIsPrivate() {
    return this.survey.visibility_type === 'PRIVATE';
  }

  get membersAreValid() {
    if (this.surveyIsPrivate) {
      return !(this.survey.members.length === 0 || this.survey.members.some((member) => !member.name || !member.email));
    }
    return true;
  }

  surveyIsValid() {
    const status = this.surveyService.surveyIsValid(this.survey);
    // @ts-ignore
    if (!status?.valid) {
      // @ts-ignore
      this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant(status?.message));
      return false;
    }

    return true;
  }

  onSubmit(form: NgForm, is_draft = false) {
    const projectId = this.projectId || this.survey.organization_project_id;
    this.loading = true;

    if (is_draft) {
      if (typeof this.survey?.title === 'string' && this.survey.title.trim().length > 0) {
        this.survey.is_draft = true;
        this.surveyService.createSurvey(projectId, this.survey, (result) => {
          this.toast.success(
            this.translateService.instant('generic.success'),
            this.translateService.instant('generic.draft-saved-successfully')
          );
          this.surveyAdded.emit(result);
          this.survey.id = result.id;
          this.surveyId = result.id;
          this.router.navigate(['/surveys', result.id]);
          this.loading = false;
        }, () => {
          this.loading = false;
        });
      } else {
        this.loading = false;
        this.toast.error(
          this.translateService.instant('generic.warning'),
          this.translateService.instant('modals.modal-survey-details.draft-title-is-mandatory')
        );
      }
    } else {
      this.survey.is_draft = false;

      if (!this.surveyIsValid()) {
        this.loading = false;
        this.survey.is_draft = true;
        return;
      }

      if (!this.membersAreValid) {
        this.toast.error(
          this.translateService.instant('generic.warning'),
          this.translateService.instant('components.decision-table.new-decision-table.compile-proposal.fourth-error')
        );
        this.loading = false;
        this.survey.is_draft = true;
        return;
      }

      if (!form.form.valid) {
        this.toast.error(
          this.translateService.instant('generic.warning'),
          this.translateService.instant('pages.surveys.survey-details.not-valid')
        );
        this.loading = false;
        this.survey.is_draft = true;
        return;
      }

      const createSurveyCallback = (result) => {
        this.toast.success(
          this.translateService.instant('generic.success'),
          this.translateService.instant('modals.modal-survey-details.survey-created-successfully')
        );
        this.toast.success(
          this.translateService.instant('generic.success'),
          this.translateService.instant('modals.modal-survey-details.survey-team-members-invited')
        );
        this.surveyAdded.emit(result);
        this.loading = false;
      };

      const handleSurveyCreationFailure = () => {
        this.survey.is_draft = true;
        this.loading = false;
      };

      if (!this.survey.email_template) {
        this.alert.showQuestion(
          this.translateService.instant('generic.warning'),
          this.translateService.instant('pages.template.no-selected-template'),
          (ok) => {
            this.surveyService.createSurvey(projectId, this.survey, createSurveyCallback, handleSurveyCreationFailure);
          }, handleSurveyCreationFailure
        );
      } else {
        this.surveyService.createSurvey(projectId, this.survey, createSurveyCallback, handleSurveyCreationFailure);
      }
    }
  }

  deleteSurvey() {
    this.loading = true;
    this.surveyService.deleteSurvey(this.survey.id, (result) => {
      this.loading = false;
      this.canceled.emit(this.survey);
      this.toast.success(
        this.translateService.instant('generic.success'),
        this.translateService.instant('modals.modal-survey-details.survey-deleted-successfully'),
      );
    }, () => {
      this.loading = false;
    });
  }

  drop(event: CdkDragDrop<object[]>) {
    moveItemInArray(this.survey?.questions, event.previousIndex, event.currentIndex);
  }

  addQuestion(question = null) {
    if (question) {
      this.survey.questions?.push({
        ...question,
        id: this.questionIdCounter++,
      });
    } else {
      this.survey.questions?.push({
        id: this.questionIdCounter++,
        question: 'Question ' + (this.survey?.questions?.length + 1),
        required: true,
        typology: this.surveyService.questionTypologies[0].id,
        choices: [],
        description: '',
        attachments: [],
        response: {
          customs: {},
          attachments: [],
        },
        customs: {
          feedback_is_mandatory: false,
          use_description: false,
          use_slider: false,
          tricolor: false,
          min: null,
          max: null,
          step: 1,
          use_custom_min_max_labels: false,
        }
      });
    }
  }

  getSections() {
    const sections = [{
      id: 'DETAILS',
      title: 'modals.modal-survey-details'
    }];

    if (this.surveyId) {
      sections.push({
        id: 'MEMBERS',
        title: 'generic.team'
      });
    }

    sections.push({
      id: 'QUESTIONS',
      title: 'generic.questions'
    });

    this.sections = sections;
  }

  removeQuestion(id) {
    this.survey.questions = this.survey.questions.filter(question => question.id !== id);
  }

  copyLink() {
    const path = environment.logoutReturnUrl + '#/' + 'surveys/' + this.surveyId + '/response';
    navigator.clipboard.writeText(path);
    this.toast.success(this.translateService.instant('generic.success'), this.translateService.instant('generic.link-copied'));
  }

  downloadResponsesCSV() {
    this.loading = true;
    this.surveyService.downloadResponsesCSV(this.survey.id, (response) => {
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  get question_sections() {
    return [...new Set(this.survey.questions.map(question => question?.customs?.section))].filter(section => section);
  }

  get filteredQuestions() {
    let questions = this.survey.questions;

    if (this.questions_filters.selected_section) {
      questions = questions.filter(question => question?.customs?.section === this.questions_filters.selected_section);
    }

    if (this.questions_filters.only_not_answered) {
      questions = questions.filter(question => !this.surveyService.surveyQuestionAnswerIsValid(question)?.valid);
    }

    return questions;
  }

  enablePreviewMode() {
    this.survey?.questions.forEach(question => {
      if (question?.response === undefined) {
        question.response = {
          customs: {},
        };
      }
    });
    this.isResponse = true;
    this.createMode = false;
    this.previewMode = true;
    this.sections = this.sections.filter(section => section.id !== 'DETAILS');
  }

  disablePreviewMode() {
    this.isResponse = false;
    this.createMode = true;
    this.previewMode = false;
    this.sections.unshift({
      id: 'DETAILS',
      title: 'modals.modal-survey-details'
    });
  }

  get currentUserInTeam() {
    return this.survey?.members?.find((member) => member?.user_id === this.userService.userDetails.user_id);
  }
}
