import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  ViewChild,
  ElementRef
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {ScriptLoaderService} from 'angular-google-charts';
import {CriteriaService} from 'src/app/services/config/criteria.service';
import {SuggestionService} from 'src/app/services/config/suggestion.service';
import {UnitsService} from 'src/app/services/config/units.service';
import {HelperService} from 'src/app/services/general/helper.service';
import {ToastService} from 'src/app/services/general/toast.service';
import {Router} from '@angular/router';

import * as _ from 'lodash';
import {DecisionService} from '../../../../services/decision.service';
import {ModalProposalRenameComponent} from '../../../../modals/modal-proposal-rename/modal-proposal-rename.component';
import swal from 'sweetalert2';
import {UserService} from "../../../../services/user.service";
import {ModalShowEvidencesComponent} from "../../../../modals/modal-show-evidences/modal-show-evidences.component";
import {OrganizationService} from 'src/app/services/organization.service';
import {faBullhorn, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {ManagementPlanService} from "../../../../services/managementplan.service";

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-multiobjective-new-proposal',
  templateUrl: './multiobjective-new-proposal.component.html',
  styleUrls: ['./multiobjective-new-proposal.component.scss'],
})
export class MultiobjectiveNewProposalComponent implements OnInit, OnChanges {
  @Input() proposal: any;
  @Input() framework: any;
  @Input() decision_table: any;
  @Input() current_organization: any;
  @Input() showPrivateInfo: any;
  @Input() isGeneratingPdf: any;
  @Output() saveAndAddEvent = new EventEmitter();
  @Output() saveAndBackEvent = new EventEmitter();
  @Output() addNewEvidenceEvent = new EventEmitter();
  @Output() addAttachmentListEvent = new EventEmitter();

  loadingGraphics = true;
  loading = false;
  isInEvalutationSession = false;

  /** Il model che contiene tutti i dati visualizzati */
  currentProposal: any = {
    framework: {
      primary_target: { evaluation_criteria: null, title: '', type: 'primary' },
      secondary_targets: [],
      criteria: [],
    },
  };

  displayedColumns: string[] = [
    'name',
    'sentiment',
    'feedback',
    'score',
    'confidence',
    'evidence',
    'attachment_events',
  ];
  displayedColumnsEvidence: string[] = ['type', 'name', 'link'];
  displayedColumnsMultipleChoice: string[] = [];

  tasksDisplayedColumns: string[] = [
    'action',
    'end_date',
    'responsible',
    'status',
  ];
  // displayedColumnsMultipleChoice: string[] = [
  //   'name',
  //   'sentiment',
  //   'feedback',
  //   'draft_action',
  //   'option_value',
  //   'evidence',
  //   'attachment_events',
  // ];

  chartOptionsContradiction = {
    height: 200,
    enableInteractivity: false,
    displayAnnotations: true,
    hAxis: {
      textPosition: 'none',
      maxValue: 100,
      gridlines: {
        count: 0,
        color: 'transparent',
        format: 'percent',
      },
    },
    animation: {
      startup: true,
    },
  };

  chartOptionsTarget = {
    height: 200,
    enableInteractivity: false,
    animation: {
      startup: true,
    },
    legend: {},
    min: 0,
    max: 0,
    hAxis: {
      title: 'range values',
      viewWindow: {
        min: 0,
        max: 0,
      },
      ticks: [], // display labels every 10
    },
  };

  constructor(
    private toast: ToastService,
    private loaderService: ScriptLoaderService,
    private criteriaService: CriteriaService,
    private unitService: UnitsService,
    private suggestionService: SuggestionService,
    public dialog: MatDialog,
    private translateService: TranslateService,
    private decisionService: DecisionService,
    private router: Router,
    private userService: UserService,
    private organizationService: OrganizationService,
    private mgplanService: ManagementPlanService
  ) {}

  @ViewChild('pdf') pdf: ElementRef;

  ngOnInit(): void {
    //  inizializzo i componenti
    if (this.proposal && this.decision_table && this.framework) {
      this.currentProposal = JSON.parse(JSON.stringify(this.proposal));
      this.getProposalData();
      if (
        this.currentProposal.evaluation_session !== null &&
        this.currentProposal.evaluation_session_id !== null
      ) {
        this.isInEvalutationSession = true;
      }
      this.proposal.criteria.forEach((criterion) => {
        criterion.initialValue = criterion.value;
        criterion.originalValue = criterion.value;
      });
    }

    //  inizializzo il componente per i grafici
    this.loaderService.loadChartPackages('corechart').subscribe(() => {
      this.loadingGraphics = false;
    });

    if (!this.isEnel()) {
      this.displayedColumnsMultipleChoice = [
        'name',
        'sentiment',
        'feedback',
        'draft_action',
        'option_value',
        'evidence',
        'attachment_events',
      ];
    } else {
      this.displayedColumnsMultipleChoice = [
        'item_id',
        'section',
        'sub_section',
        'check_n',
      ];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    //  sovrascrivo solo se passo il framework all'inizializzazione del componente
    if (this.currentProposal && this.currentProposal.framework) {
      this.currentProposal = JSON.parse(JSON.stringify(this.proposal));
    }

    this.showPrivateInfo = JSON.parse(JSON.stringify(this.showPrivateInfo));
  }

  private addPageToPdf(pdf: any, elementId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // addPageToPdf(pdf: any, elementId: string) {
      const element = document.getElementById(elementId);
      if (element) {
        html2canvas(element, { scale: 1, useCORS: true }).then((canvas) => {
          const imgData = canvas.toDataURL('image/jpeg', 1); // Compress image to JPEG with 50% quality
          const imgWidth = pdf.internal.pageSize.getWidth();
          const pageHeight = pdf.internal.pageSize.getHeight();
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          let heightLeft = imgHeight;
          let position = 0;

          const addFooter = (pdf, pageHeight) => {
            const footerText1 = 'Data chiusura Sessione: 29/05/25 18:00 UTC+0';
            const footerText2 = 'Data stampa report: 21/05/25 17:00 UTC+0';
            const footerX = pdf.internal.pageSize.getWidth() - 10;
            const footerY1 = pageHeight - 12;
            const footerY2 = pageHeight - 10;

            pdf.setFontSize(4);
            pdf.setFont('helvetica', 'bold');
            pdf.text(footerText1, footerX, footerY1, { align: 'right' });
            pdf.text(footerText2, footerX, footerY2, { align: 'right' });
          };

          // Add the first page
          pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
          addFooter(pdf, pageHeight);
          heightLeft -= pageHeight;

          // Add new pages while there's content left
          while (heightLeft > 0) {
            if (heightLeft > pageHeight) {
              position = heightLeft - imgHeight;
              pdf.addPage();
              pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
              addFooter(pdf, pageHeight);
              heightLeft -= pageHeight;
            } else {
              pdf.addPage();
              pdf.addImage(
                imgData,
                'JPEG',
                0,
                heightLeft - imgHeight,
                imgWidth,
                imgHeight
              );
              heightLeft = 0;
            }
          }

          resolve();
        });
      } else {
        reject(`Element with ID '${elementId}' not found.`);
      }
    });
  }

  async PDFReport() {
    // const content = this.pdf.nativeElement.innerHTML;

    const pdf = new jsPDF('p', 'mm', 'a4');

    await this.addPageToPdf(pdf, 'to-print');

    pdf.save('report.pdf');
  }

  getProposalData() {
    this.prepareProposalValues();
    this.init();
  }

  proposalIsRevisioned(proposal) {
    return proposal.revisions.length > 0;
  }

  init() {
    if (this.loadingGraphics) {
      setTimeout(() => {
        this.init();
      }, 200);
      return;
    } else {
      if (!this.isEnel()) {
        this.prepareChartContradiction();
        this.prepareTargetChartData();
        this.prepareCriteriaChartData();
        this.prepareChartMultipleChoiceCriteria();
      }
    }
  }

  /**
   * Setta i valori corretti dei pesi assegnati ai criteri, solo per la proposta corrente
   */
  prepareProposalValues() {
    this.currentProposal.criteria.forEach((proposal_criteria) => {
      //  filtro per la proposta attuale
      proposal_criteria.proposal_evaluations =
        proposal_criteria?.proposal_evaluations?.filter(
          (x) => x.proposal_id == this.currentProposal.id
        );

      //  assegno il giusto peso espresso al membro del team corretto che lo ha valutato
      proposal_criteria.response.forEach((criteria_response) => {
        criteria_response.evaluation_proposal =
          proposal_criteria?.proposal_evaluations?.find(
            (x) => x.team_member_id == criteria_response.team_member_id
          );
      });
    });

    this.currentProposal.criteria.forEach((criterion) => {
      if (this.criteriaService.criteriaIsMultipleChoice(criterion.typology)) {
        criterion.options.forEach((option) => (option.responses = []));
        criterion.response.forEach((response) => {
          //  setto al criterio l'ozione selezionata
          response.option_selected = criterion.options.find(
            (option) => option.id === response.option_value
          ).title;
          response.option_selected_index =
            criterion.options.findIndex(
              (option) => option.id === response.option_value
            ) + 1;

          const option = criterion.options.find(
            (option) => option.id === response.option_value
          );
          if (option) {
            if (!option.responses) {
              option.responses = [];
            }
            option.responses.push(response);
          }
        });
      }
    });
  }

  /**
   * Prepara i grafici da disegnare per il grado di certezza e contraddizione ma solo per i criteri qualitativi
   */
  prepareChartContradiction() {
    this.currentProposal.criteria.forEach((criterio) => {
      if (
        criterio.results &&
        this.criteriaService.criteriaIsQualitative(criterio.typology)
      ) {
        this.drawChartContraddition(criterio);
      }
    });
  }

  get loggedUserDetails() {
    return this.userService.userInfo;
  }

  criterionIsChanged(criterio: any) {
    return criterio.initValue !== criterio.value;
  }

  saveCriterionValue(criterio: any) {
    criterio.initialValue = criterio.value;
    criterio.modifyEnabled = false;
    criterio.modified = true;
    this.proposal.criteria.forEach((criterion) => {
      if (criterion.id === criterio.id) {
        criterion.value = criterio.value;
        criterion.initialValue = criterio.value;
      }
    });
    this.currentProposal = JSON.parse(JSON.stringify(this.proposal));
  }

  /** Disegna i grafici di contraddizione e certezza */
  drawChartContraddition(criterio: any) {
    const data = google.visualization.arrayToDataTable([
      ['Element', 'Density', { role: 'style' }],
      [
        this.translateService.instant('generic.certainty-degree'),
        {
          v: criterio.results.certainty_degree,
          f: criterio.results.certainty_degree + '%',
        },
        '#1b61a1',
      ],
      [
        this.translateService.instant('generic.contradiction-degree'),
        {
          v: criterio.results.contradiction_degree,
          f: criterio.results.contradiction_degree + '%',
        },
        '#ff0000',
      ],
    ]);

    const view = new google.visualization.DataView(data);
    view.setColumns([
      0,
      1,
      {
        calc: 'stringify',
        sourceColumn: 1,
        type: 'string',
        role: 'annotation',
      },
      2,
    ]);

    const chart = new google.visualization.BarChart(
      document.getElementById('chart_div_' + criterio.title)
    );
    chart.draw(view, this.chartOptionsContradiction);
  }

  /** Preparo i dati formattando gli obiettivi secondari uno dopo l'altro per rappresentare i pesi degli obiettivi secondari sul principale */
  prepareTargetChartData() {
    if (!this.currentProposal?.decisionTableResult) {
      return;
    }

    //  se esiste l'indice overall allora lo mostro sull'obiettivo principale
    if (
      this.currentProposal.decisionTableResult.results_on_target?.index_overall
    ) {
      this.currentProposal.primary_target.index_overall =
        this.currentProposal.decisionTableResult.results_on_target.index_overall;
    }

    //  recupero i punteggi per i pesi standard
    const groupedSTD = HelperService.groupBy(
      this.currentProposal.decisionTableResult.results_on_target,
      'weight_system'
    );

    //  raggruppo per target tutti i punteggi del sistema di peso "standard"
    const grouppedByTarget = HelperService.groupBy(
      groupedSTD['Expert (STD)'],
      'target'
    );

    //  aggiungo agli obiettivi secondari il punteggio che mi interessa rappresentare nel
    this.currentProposal.secondary_targets.forEach((secondary_target) => {
      const secondaryTargetsGroupped = grouppedByTarget[secondary_target.title];
      if (secondaryTargetsGroupped) {
        secondary_target.chartData = {
          value:
            grouppedByTarget[secondary_target.title][0].target_weight_perc_avg,
        };
      }
    });

    //  disegno il grafico
    this.chartOptionsTarget.max =
      this.currentProposal.primary_target.expected_value;
    this.chartOptionsTarget.hAxis.viewWindow.max =
      this.currentProposal.primary_target.expected_value;
    for (let i = 0; i <= this.chartOptionsTarget.max; i += 20) {
      this.chartOptionsTarget.hAxis.ticks.push(i);
    }

    this.currentProposal.secondary_targets.map((currentSecondaryTarget) => {
      this.drawChartTarget(currentSecondaryTarget);
    });
  }

  /** Preparo i dati formattando le informazioni dei criteri */
  prepareCriteriaChartData() {
    this.currentProposal.criteria.forEach((criterio) => {
      criterio.chartData = {
        avg_weight:
          _.sumBy(criterio.proposal_evaluations, function (o: any) {
            return o.weight;
          }) / criterio.proposal_evaluations.length,
      };
      if (isNaN(criterio.chartData.avg_weight)) {
        criterio.chartData.avg_weight = 0;
      }
    });
  }

  /** Disegno i grafici dei target */
  drawChartTarget(secondaryTargets: any) {
    const target = secondaryTargets;
    const arrayData = [
      ['Target', '', { role: 'style' }, { role: 'annotation' }],
    ];

    if (!target.chartData) {
      return;
    }

    arrayData.push([
      target.title,
      { v: target.chartData.value, f: target.chartData.value },
      '#DCDEFA',
      target.chartData.value,
    ]);

    //  setto il colore
    const currentArrayData = JSON.parse(JSON.stringify(arrayData));
    const index = currentArrayData.findIndex((x) => x[0] == target.title);
    if (index >= 0) {
      currentArrayData[index][2] = '#9169DB';
    }

    const data = google.visualization.arrayToDataTable(currentArrayData);
    const view = new google.visualization.DataView(data);

    setTimeout(() => {
      const chart = new google.visualization.BarChart(
        document.getElementById('chart_div_target_' + target.title)
      );

      this.chartOptionsTarget.legend = { position: 'none' };

      chart.draw(view, this.chartOptionsTarget);
      //  TODO_MICHELE: controllare perché fu fatto questo controllo
      // if (chart.draw(view, this.chartOptionsTarget) === undefined) {
      //   document.getElementById('chart_div_target_' + target.title).hidden = true;
      // }
    }, 100);
  }

  prepareChartMultipleChoiceCriteria() {
    this.currentProposal.criteria.forEach((criterio) => {
      if (this.criteriaService.criteriaIsMultipleChoice(criterio.typology)) {
        if (criterio.response.length > 0 && !this.isAlexion()) {
          this.drawMultipleChoiceChart(criterio);
        }
      }
    });
  }

  drawMultipleChoiceChart(criterio: any) {
    const totalResponses = criterio.options.reduce(
      (total, option) => total + option.responses.length,
      0
    );
    const dataArray = criterio.options.map((option) => [
      option.title,
      option.responses.length,
    ]);
    var biggest = 0;
    criterio.options.forEach((option) => {
      option.responsePercentage = (
        (option?.responses?.length / totalResponses) *
        100
      ).toFixed(2);
      if (option.responsePercentage > biggest) {
        biggest = option.responsePercentage;
      }
    });
    criterio.options.forEach((option) => {
      if (option.responsePercentage === biggest) {
        option.isBiggest = true;
      }
    });

    const str1 = this.translateService.instant(
      'components.decision-table.new-decision-table.compile-proposal.title'
    );
    const str2 = this.translateService.instant('generic.votes');

    let data = google.visualization.arrayToDataTable([
      [str1, str2],
      ...dataArray,
    ]);

    var chart = new google.visualization.PieChart(
      document.getElementById('pie_chart_' + criterio.id)
    );
    chart.draw(data, {});
  }

  proposalIsValid() {
    let valid = true;
    this.currentProposal.criteria.forEach((criterion) => {
      if (criterion.value === '') {
        valid = false;
        this.toast.error(
          'Butterfly Decisions',
          this.translateService.instant(
            'components.proposals.criteria.is-empty'
          )
        );
        return;
      }
      if (criterion.typology === 'QUANTITATIVE') {
        if (isNaN(criterion.value)) {
          valid = false;
          this.toast.error(
            'Butterfly Decisions',
            this.translateService.instant(
              'components.proposals.quantitative-numeric-value-not-valid'
            )
          );
          return;
        }
      }
    });
    return valid;
  }

  isContestProposalAccepted(proposal) {
    return (
      (proposal.is_contest_proposal && proposal.is_contest_proposal_accepted) ||
      !proposal.is_contest_proposal
    );
  }

  /**
   * Aggiunta di un nuovo
   * @param criterio
   * @param attachment_type
   */
  onClickAddNewEvidence(criterio: any, attachment_type: string) {
    this.saveCriterionValue(criterio);
    this.addNewEvidenceEvent.emit({
      criterio,
      attachment_type,
    });
  }

  showExpertEvideces(objectUser) {
    const dialogRef = this.dialog.open(ModalShowEvidencesComponent, {
      panelClass: 'medium-dialog',
      data: {
        userFullName:
          objectUser?.team_member?.name || objectUser?.team_member?.email,
        attachments: objectUser.attachment_expert_pubmed.concat(
          objectUser.attachment_expert_files
        ),
      },
    });

    dialogRef.afterClosed().subscribe((event) => {});
  }

  removeAttachment(attachment, criterion) {
    this.proposal.criteria.forEach((criterionTmp) => {
      if (criterion.id === criterionTmp.id) {
        criterionTmp.attachment = criterionTmp?.attachment?.filter((item) => {
          if (attachment.type === 'PUBMED') {
            return item.PMID !== attachment.PMID;
          } else {
            return item.id !== attachment.id;
          }
        });
        criterionTmp.attachment_pubmed =
          criterionTmp?.attachment_pubmed?.filter(
            (item) => item.PMID !== attachment.PMID
          );
        criterionTmp.attachment_files = criterionTmp?.attachment_files?.filter(
          (item) => item.id !== attachment.id
        );
      }
    });
    this.currentProposal = JSON.parse(JSON.stringify(this.proposal));
  }

  /**
   * Evento di apertura della lista di allegati cliccati dall'esperto
   */
  onClickAttachmentEventList(response: any) {
    this.addAttachmentListEvent.emit({
      criteria_id: response.criteria_id,
      team_member_id: response.team_member_id,
    });
  }

  isEdited() {
    let edited = false;
    this.proposal.criteria.forEach((criterion) => {
      if (criterion.originalValue !== criterion.initialValue) {
        edited = true;
      }
    });
    return edited;
  }

  /**
   * Evento di modifica di una descrizione di un criterio
   */
  onClickModifyDescription(criterio: any) {
    criterio.modifyEnabled = true;
    criterio.initialValue = criterio.value;
  }

  /**
   * Evento di cancellazione di una descrizione di un criterio
   * @param criterio
   */
  onClickCancelDescription(criterio: any) {
    criterio.value = criterio.initialValue;
    criterio.modifyEnabled = false;
    criterio.criterioModified = false;
  }

  onClickSaveAs(title) {
    const dialogRef = this.dialog.open(ModalProposalRenameComponent, {
      panelClass: 'medium-dialog',
      data: {
        proposalTitle: title,
      },
    });

    dialogRef.afterClosed().subscribe((event) => {
      if (event != null) {
        this.currentProposal.title = event;
        this.loading = true;
        this.decisionService.addNewRevision(
          this.decision_table.id,
          this.currentProposal,
          (result) => {
            this.loading = false;
            this.saveAndAddEvent.emit(result);
          },
          (error) => (this.loading = false)
        );
      }
    });
  }

  public deleteProposal() {
    if (this.proposal.evaluation_session_id == null) {
      swal
        .fire({
          title: this.translateService.instant(
            'components.decision-table.show-decision-table.proposal.delete-alert-title'
          ),
          text: this.translateService.instant(
            'components.decision-table.show-decision-table.proposal.delete-alert-text'
          ),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#9169db',
          confirmButtonText: this.translateService.instant(
            'components.decision-table.show-decision-table.proposal.delete-alert-confirm-button-text'
          ),
          cancelButtonText: this.translateService.instant(
            'components.decision-table.show-decision-table.proposal.delete-alert-cancel-button-text'
          ),
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.loading = true;
            this.decisionService.deleteProposal(
              this.decision_table.id,
              this.proposal,
              (result: any) => {
                this.loading = false;
                this.toast.success(
                  this.translateService.instant(
                    'components.decision-table.show-decision-table.proposal.success-delete-proposal'
                  )
                );
                this.saveAndAddEvent.emit({
                  is_deleted: true,
                  proposal_id: this.currentProposal.id,
                });
              },
              () => {
                this.loading = false;
                this.toast.error(
                  this.translateService.instant(
                    'components.decision-table.show-decision-table.proposal.error-delete-proposal'
                  )
                );
              }
            );
          }
        });
    }
  }

  updateProposal() {
    if (this.proposalIsValid()) {
      this.loading = true;
      this.decisionService.updateProposal(
        this.decision_table.id,
        this.currentProposal,
        (result) => {
          this.loading = false;
          this.saveAndBackEvent.emit(this.currentProposal);
          return;
        },
        (error) => (this.loading = false)
      );
    }
  }

  saveAndBack() {
    this.saveAndBackEvent.emit();
  }

  saveAndAdd() {
    if (this.isEdited() && this.proposalIsValid()) {
      this.loading = true;
      this.decisionService.addNewRevision(
        this.decision_table.id,
        this.currentProposal,
        (result) => {
          this.loading = false;
          this.saveAndAddEvent.emit(result);
        },
        (error) => (this.loading = false)
      );
    } else {
      //  TODO_TRADUZIONI
      // this.alert.showAlert(
      //   'Attenzione',
      //   'La proposta sarà salvata come nuova'
      // );
    }
    // this.saveAndAddEvent.emit();
  }

  getCriteriaService() {
    return this.criteriaService;
  }

  getUnitsService() {
    return this.unitService;
  }

  getSuggestionService() {
    return this.suggestionService;
  }

  isEnel() {
    return this.current_organization?.name === 'ENEL';
  }

  isAlexion() {
    return this.current_organization?.customs?.profile === 'alexion';
  }

  taskStatus(row) {
    if (row.has_completed) {
      return 'COMPLETED';
    } else {
      if (!row?.end_date) {
        return 'PENDING';
      }
      const dateArr = row?.end_date.split('-');
      const end_date = new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
      const today = new Date();
      if (end_date > today) {
        return 'PENDING';
      } else {
        return 'OVERDUE';
      }
    }
  }

  taskStatusColor(row) {
    const status = this.taskStatus(row);

    if (status === 'COMPLETED') {
      return '#00bc1d';
    }
    if (status === 'OVERDUE') {
      return '#ff0000';
    }

    return '#FFAD4A';
  }

  taskStatusTooltip(row) {
    const status = this.taskStatus(row);
    if (status === 'COMPLETED') {
      return this.translateService.instant('generic.done');
    }
    if (status === 'OVERDUE') {
      return this.translateService.instant('generic.overdue');
    }

    return this.translateService.instant('generic.to-complete');
  }

  checkTaskCompleted(task) {
    task.has_completed = true;
    this.mgplanService.updateTask(
      task,
      () => {
        this.toast.success(
          this.translateService.instant('generic.management-plan'),
          this.translateService.instant(
            'components.management-alert.updated-task'
          )
        );
      },
      () => {
        task.has_completed = false;
        this.toast.error(
          this.translateService.instant('generic.management-plan'),
          this.translateService.instant(
            'components.management-alert.not-updated-task'
          )
        );
      }
    );
  }

  protected readonly faBullhorn = faBullhorn;
  protected readonly faEnvelope = faEnvelope;
}
