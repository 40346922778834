import {Injectable} from '@angular/core';
import {BackendService} from './general/backend.service';
import {TranslateService} from '@ngx-translate/core';
import {upperCase} from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class SurveyService {
  constructor(private backend: BackendService, private translateService: TranslateService) {
  }

  private OPENED_QUESTION = 'OPENED';
  private QUANTITATIVE_QUESTION = 'QUANTITATIVE';
  private CHECKBOX_QUESTION = 'CHECKBOX';
  private MULTIPLE_CHOICE_QUESTION = 'MULTIPLE_CHOICE';
  private CONSENSUS_QUESTION = 'CONSENSUS';

  public questionTypologies = [
    {
      id: this.OPENED_QUESTION,
      title: this.translateService.instant('service.survey-service.question-typologies.opened.title'),
      description: this.translateService.instant('service.survey-service.question-typologies.opened.description'),
    },
    {
      id: this.QUANTITATIVE_QUESTION,
      title: this.translateService.instant('service.survey-service.question-typologies.quantitative.title'),
      description: this.translateService.instant('service.survey-service.question-typologies.quantitative.description'),
    },
    {
      id: this.CHECKBOX_QUESTION,
      title: this.translateService.instant('service.survey-service.question-typologies.checkbox.title'),
      description: this.translateService.instant('service.survey-service.question-typologies.checkbox.description'),
    },
    {
      id: this.MULTIPLE_CHOICE_QUESTION,
      title: this.translateService.instant('service.survey-service.question-typologies.multiple-choice.title'),
      description: this.translateService.instant('service.survey-service.question-typologies.multiple-choice.description'),
    },
    {
      id: this.CONSENSUS_QUESTION,
      title: this.translateService.instant('service.survey-service.question-typologies.consensus.title'),
      description: this.translateService.instant('service.survey-service.question-typologies.consensus.description'),
    },
  ];

  public selectOptions = [{
    key: 'use_description',
    label: 'modals.modal-survey-details.add-description',
    enabledTypologies: [
      this.CONSENSUS_QUESTION,
      this.MULTIPLE_CHOICE_QUESTION,
      this.CHECKBOX_QUESTION,
      this.OPENED_QUESTION,
      this.QUANTITATIVE_QUESTION,
    ]
  },
    {
      key: 'feedback_is_mandatory',
      label: 'modals.modal-survey-details.mandatory-feedback',
      enabledTypologies: [
        this.MULTIPLE_CHOICE_QUESTION,
        this.CHECKBOX_QUESTION,
        this.QUANTITATIVE_QUESTION,
        this.OPENED_QUESTION,
      ]
    }];

  public isQuestionType(type: string, questionType: string): boolean {
    if (type == null || typeof type === 'undefined') {
      return false;
    }
    return type.toUpperCase() === questionType.toUpperCase();
  }

  public isCheckboxQuestion(type: string): boolean {
    return this.isQuestionType(type, this.CHECKBOX_QUESTION);
  }

  public isConsensusQuestion(type: string): boolean {
    return this.isQuestionType(type, this.CONSENSUS_QUESTION);
  }

  public isQuantitativeQuestion(type: string): boolean {
    return this.isQuestionType(type, this.QUANTITATIVE_QUESTION);
  }

  public isOpenedQuestion(type: string): boolean {
    return this.isQuestionType(type, this.OPENED_QUESTION);
  }

  public isMultipleChoiceQuestion(type: string): boolean {
    return this.isQuestionType(type, this.MULTIPLE_CHOICE_QUESTION);
  }

  public surveyQuestionAnswerIsValid(question) {

    if (this.isOpenedQuestion(question.typology)) {
      if (!question?.response?.answer) {
        if (question.required) {
          return {valid: false, message: 'pages.surveys.response.opened.not-answer'};
        } else {
          question.response.status = 'ABSTAINED';
        }
      }
    }

    if (!this.isConsensusQuestion(question.typology) && question?.customs?.feedback_is_mandatory) {
      if (!question?.response?.feedback) {
        return {valid: false, message: 'modals.modal-survey-details.errors.feedback-is-mandatory'};
      }
    }

    if (this.isQuantitativeQuestion(question.typology)) {
      if (!question?.response?.answer) {
        if (question.required) {
          return {valid: false, message: 'pages.surveys.response.quantitative.not-answer'};
        } else {
          question.response.status = 'ABSTAINED';
        }
      } else {
        const answerAsNumber = parseFloat(question.response.answer);

        if (
          isNaN(answerAsNumber) ||
          answerAsNumber < parseFloat(question.customs.min) ||
          answerAsNumber > parseFloat(question.customs.max)
        ) {
          return {valid: false, message: 'pages.surveys.response.no-quantitative-answer-in-range'};
        }
      }
    }

    if (this.isMultipleChoiceQuestion(question.typology)) {
      if (!question?.response?.choice) {
        if (question.required) {
          return {valid: false, message: 'generic.proposal-option-valid'};
        } else {
          question.response.status = 'ABSTAINED';
        }
      }
    }

    if (this.isCheckboxQuestion(question.typology)) {
      if (!question?.choices.some((choice) => choice.checked)) {
        if (question.required) {
          return {valid: false, message: 'pages.surveys.response.no-checkbox-setted'};
        } else {
          question.response.status = 'ABSTAINED';
        }
      }
    }

    if (this.isConsensusQuestion(question.typology)) {
      if (question.required) {
        if (question.response.answer !== 'AGREE' && question.response.answer !== 'DISAGREE') {
          return {valid: false, message: 'pages.surveys.response.consensus.not-answer'};
        }
        if (!question.response.feedback) {
          return {valid: false, message: 'pages.surveys.response.no-feedback-error'};
        }
        if (!question.response.customs.confidence) {
          return {valid: false, message: 'pages.surveys.response.consensus.not-confidence'};
        }
      } else {
        if (question.response.answer !== 'AGREE' && question.response.answer !== 'DISAGREE' && question.response.answer !== 'ABSTAINED') {
          return {valid: false, message: 'pages.surveys.response.consensus.not-answer'};
        }
      }
    }

    return {valid: true};
  }

  public surveyAnswerIsValid(survey): object {
    for (const question of survey.questions) {
      const is_valid_response = this.surveyQuestionAnswerIsValid(question);
      if (!is_valid_response?.valid) {
        return is_valid_response;
      }
    }
    return {valid: true};
  }

  public surveyIsValid(survey): object {
    for (const question of survey.questions) {
      if (!question?.question) {
        return {valid: false, message: 'modals.modal-survey-details.errors.title-is-required'};
      }

      if (this.isMultipleChoiceQuestion(question.typology) || this.isCheckboxQuestion(question.typology)) {
        if (question?.choices?.length > 1) {
          for (const choice of question.choices) {
            if (!choice?.title || choice?.title === '') {
              return {valid: false, message: 'modals.modal-survey-details.errors.choice-not-valid'};
            }
          }
        } else {
          return {valid: false, message: 'modals.modal-survey-details.errors.multiple-choices-length-errors'};
        }
      }

      if (this.isConsensusQuestion(question.typology) || this.isQuantitativeQuestion(question.typology)) {
        if (question?.customs?.use_custom_min_max_labels) {
          if (!question?.customs?.min_label || !question?.customs?.max_label) {
            return {valid: false, message: 'modals.modal-survey-details.errors.no-max-min-labels-error'};
          }
        }
      }

      if (this.isQuantitativeQuestion(question.typology)) {
        if (!question?.customs?.max || !question.customs.min) {
          return {valid: false, message: 'modals.modal-survey-details.errors.max-min-error'};
        }
        if (question?.customs?.min >= question?.customs?.max) {
          return {valid: false, message: 'modals.modal-survey-details.errors.min-error'};
        }
        if (!question?.customs?.measure_unit) {
          return {valid: false, message: 'modals.modal-survey-details.errors.not-measure-unit'};
        }
      }
    }
    return {valid: true};
  }

  public surveyDetails(survey_id: string, success_callback = (data: any) => {
  },                   error_callback = (error: any) => {
  }) {

    this.backend.get(`surveys/${survey_id}/`).subscribe(result => {
      success_callback(result);
    }, (error) => {
      console.log(error);
      this.backend.showErrors(error);
      error_callback(error);
    });
  }

  public getSurveysByProjectId(project_id: string, success_callback = (data: any) => {
  },                           error_callback = (error: any) => {
  }) {

    this.backend.get(`project/${project_id}/surveys/`).subscribe(result => {
      success_callback(result);
    }, (error) => {
      console.log(error);
      this.backend.showErrors(error);
      error_callback(error);
    });
  }

  public changeSurveyStatus(survey_id: string, model: any, success_callback = (data: any) => {
  },                        error_callback = (error: any) => {
  }) {

    this.backend.post(`surveys/${survey_id}/change-status/`, model).subscribe(result => {
      success_callback(result);
    }, (error) => {
      console.log(error);
      this.backend.showErrors(error);
      error_callback(error);
    });
  }


  public createSurvey(project_id: string, model: any, success_callback = (data: any) => {
  },                  error_callback = (error: any) => {
  }) {

    this.backend.post(`project/${project_id}/surveys/`, model).subscribe(result => {
      success_callback(result);
    }, (error) => {
      console.log(error);
      this.backend.showErrors(error);
      error_callback(error);
    });
  }

  public deleteSurvey(survey_id: string, success_callback = (data: any) => {
  },                  error_callback = (error: any) => {
  }) {

    this.backend.post(`surveys/${survey_id}/delete`, survey_id).subscribe(result => {
      success_callback(result);
    }, (error) => {
      console.log(error);
      this.backend.showErrors(error);
      error_callback(error);
    });
  }

  public createSurveyAnswer(survey_id: string, model: any, success_callback = (data: any) => {
  },                        error_callback = (error: any) => {
  }) {

    this.backend.post(`surveys/${survey_id}/responses/`, model).subscribe(result => {
      success_callback(result);
    }, (error) => {
      console.log(error);
      this.backend.showErrors(error);
      error_callback(error);
    });
  }

  public downloadResponsesCSV(survey_id: string, success_callback, error_callback) {
    this.backend.download(
      `surveys/${survey_id}/export/responses`,
      'surveys_responses',
      success_callback,
      error_callback
    );
  }

  public downloadSurveyTeamData(survey_id: string, success_callback, error_callback) {
    this.backend.download(
      `surveys/${survey_id}/export/team-data`,
      `Survey ${survey_id} Team Data`,
      success_callback,
      error_callback
    );
  }

  /**
   * Avvia una nuova sessione di decisione
   * @param organization_project_id
   * @param survey_id
   * @param model
   * @param success_callback
   * @param error_callback
   */
  public addNewSurveyTeamMember(
    survey_id: string,
    model: any,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend
      .post(`surveys/${survey_id}/add-new-survey-team-member`, model)
      .subscribe(
        (result) => {
          success_callback(result);
        },
        (error) => {
          console.log(error);
          this.backend.showErrors(error);
          error_callback(error);
        }
      );
  }

  public getEmailResponsesForSurveyTeamMember(
    survey_id,
    idtm: string,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend
      .get(`surveys/${survey_id}/survey-team-member/${idtm}/email-responses/`)
      .subscribe(
        (result) => {
          success_callback(result);
        },
        (error) => {
          console.log(error);
          this.backend.showErrors(error);
          error_callback(error);
        }
      );
  }

  public resendInvitation(id_survey, id_team_member: string, success_callback = (data: any) => {
  },                      error_callback = (error: any) => {
  }) {

    this.backend.post(`surveys/${id_survey}/survey-team-member/${id_team_member}/resend-invitation/`, undefined).subscribe(result => {
        success_callback(result);
      }, (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }
}
