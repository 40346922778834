<div class="row justify-content-center mt-5 mb-3">
  <ng-container *ngIf="!loading; else elseBlock">
    <button
      class="size-small danger text-white"
      mat-flat-button
      (click)="generateAIAnalysis()"
    >{{ 'modal.modal-ai-analysis.title' | translate }}
    </button>
    <button mat-raised-button color="primary"
            class="action-button ml-2"
            (click)="copyText(analysis)"
            *ngIf="analysis">
      {{ 'generic.copy-text' | translate }}
    </button>
  </ng-container>

  <ng-template #elseBlock>
    <mat-spinner diameter="40"></mat-spinner>
  </ng-template>
</div>


<div class="row ai-text-container" *ngIf="analysis">
  <markdown [data]="analysis"></markdown>
</div>
