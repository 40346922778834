import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {ScriptLoaderService} from 'angular-google-charts';
import {CriteriaService} from 'src/app/services/config/criteria.service';
import {SuggestionService} from 'src/app/services/config/suggestion.service';
import {UnitsService} from 'src/app/services/config/units.service';
import {HelperService} from 'src/app/services/general/helper.service';
import {ToastService} from 'src/app/services/general/toast.service';

import * as _ from 'lodash';
import {DecisionService} from '../../../../services/decision.service';
import {Router} from '@angular/router';
import {ModalProposalRenameComponent} from '../../../../modals/modal-proposal-rename/modal-proposal-rename.component';
import swal from 'sweetalert2';
import {UserService} from "../../../../services/user.service";
import {ModalShowEvidencesComponent} from "../../../../modals/modal-show-evidences/modal-show-evidences.component";
import {ResponseTypesService} from "../../../../services/config/response-types.service";

@Component({
  selector: 'app-multicriteria-new-proposal',
  templateUrl: './multicriteria-new-proposal.component.html',
  styleUrls: ['./multicriteria-new-proposal.component.scss'],
})
export class MulticriteriaNewProposalComponent implements OnInit, OnChanges {
  @Input() proposal: any;
  @Input() framework: any;
  @Input() decision_table: any;
  @Input() showPrivateInfo: any;
  @Output() saveAndAddEvent = new EventEmitter();
  @Output() saveAndBackEvent = new EventEmitter();
  @Output() addNewEvidenceEvent = new EventEmitter();
  @Output() addAttachmentListEvent = new EventEmitter();

  loadingGraphics = true;

  loading = false;

  isInEvalutationSession = false;

  /** Il model che contiene tutti i dati visualizzati */
  currentProposal: any = {
    framework: {
      primary_target: {evaluation_criteria: null, title: '', type: 'primary'},
      secondary_targets: [],
      criteria: [],
    },
  };

  displayedColumns: string[] = [
    'name',
    'sentiment',
    'feedback',
    'truefalse',
    'confidence',
    'evidence',
    'attachment_events',
  ];
  displayedColumnsEvidence: string[] = ['type', 'name', 'link'];
  displayedColumnsMultipleChoice: string[] = [
    'name',
    'sentiment',
    'feedback',
    'draft_action',
    'option_value',
    'evidence',
    'attachment_events',
  ];

  chartOptionsContradiction = {
    height: 200,
    enableInteractivity: false,
    displayAnnotations: true,
    hAxis: {
      textPosition: 'none',
      maxValue: 100,
      gridlines: {
        count: 0,
        color: 'transparent',
        format: 'percent',
      },
    },
    animation: {
      startup: true,
    },
  };

  chartOptionsTarget = {
    height: 200,
    enableInteractivity: false,
    animation: {
      startup: true,
    },
  };

  constructor(
    private toast: ToastService,
    private loaderService: ScriptLoaderService,
    private criteriaService: CriteriaService,
    private unitService: UnitsService,
    private suggestionService: SuggestionService,
    public dialog: MatDialog,
    private translateService: TranslateService,
    private decisionService: DecisionService,
    private router: Router,
    private userService: UserService,
    private responsesTypeService: ResponseTypesService,
  ) {
  }

  ngOnInit(): void {
    //  inizializzo i componenti
    if (this.proposal && this.decision_table && this.framework) {
      this.currentProposal = JSON.parse(JSON.stringify(this.proposal));
      this.getProposalData();
    }
    if (
      this.currentProposal.evaluation_session !== null &&
      this.currentProposal.evaluation_session_id !== null
    ) {
      this.isInEvalutationSession = true;
    }
    this.proposal.criteria.forEach((criterion) => {
      criterion.initialValue = criterion.value;
      criterion.originalValue = criterion.value;
    });
    //  inizializzo il componente per i grafici
    this.loaderService.loadChartPackages('corechart').subscribe(() => {
      this.loadingGraphics = false;
    });
  }

  proposalIsRevisioned(proposal) {
    return proposal.revisions.length > 0;
  }

  ngOnChanges(changes: SimpleChanges) {

    //  sovrascrivo solo se passo il framework all'inizializzazione del componente
    if (this.currentProposal && this.currentProposal.framework) {
      this.currentProposal = JSON.parse(JSON.stringify(this.proposal));
    }

    this.showPrivateInfo = JSON.parse(JSON.stringify(this.showPrivateInfo))
  }

  getProposalData() {
    this.prepareProposalValues();
    this.init();
  }

  init() {
    if (this.loadingGraphics) {
      setTimeout(() => {
        this.init();
      }, 200);
      return;
    } else {
      this.prepareChartContradiction();
      this.prepareTargetChartData();
      this.prepareCriteriaChartData();
      this.prepareChartMultipleChoiceCriteria();
    }
  }

  /**
   * Setta i valori corretti dei pesi assegnati ai criteri, solo per la proposta corrente
   */
  prepareProposalValues() {
    this.currentProposal.criteria.forEach((proposal_criteria) => {
      //  filtro per la proposta attuale
      proposal_criteria.proposal_evaluations =
        proposal_criteria.proposal_evaluations.filter(
          (x) => x.proposal_id == this.currentProposal.id
        );

      //  assegno il giusto peso espresso al membro del team corretto che lo ha valutato
      proposal_criteria.response.forEach((criteria_response) => {
        criteria_response.evaluation_proposal =
          proposal_criteria.proposal_evaluations.find(
            (x) => x.team_member_id == criteria_response.team_member_id
          );
      });
    });

    this.currentProposal.criteria.forEach((criterion) => {
      if (this.criteriaService.criteriaIsMultipleChoice(criterion.typology)) {
        criterion.options.forEach((option) => option.responses = []);
        criterion.response.forEach((response) => {

          //  setto al criterio l'ozione selezionata
          response.option_selected = criterion.options.find((option) => option.id === response.option_value)?.title;
          response.option_selected_index = criterion.options.findIndex((option) => option?.id === response?.option_value) + 1;

          const option = criterion.options.find((option) => option?.id === response?.option_value);
          if (option) {
            if (!option.responses) {
              option.responses = [];
            }
            option.responses.push(response);
          }
        });
      }
    })
  }

  isContestProposalAccepted(proposal) {
    return (proposal.is_contest_proposal && proposal.is_contest_proposal_accepted) || !proposal.is_contest_proposal;
  }

  get loggedUserDetails() {
    return this.userService.userInfo;
  }

  /**
   * Prepara i grafici da disegnare per il grado di certezza e contraddizione ma solo per i criteri qualitativi
   */
  prepareChartContradiction() {
    this.currentProposal.criteria.forEach((criterio) => {
      if (
        criterio.results &&
        this.criteriaService.criteriaIsQualitative(criterio.typology)
      ) {
        this.drawChartContraddition(criterio);
      }
    });
  }

  criterionIsChanged(criterio: any) {
    return criterio.initValue !== criterio.value;
  }

  removeAttachment(attachment, criterion) {
    this.proposal.criteria.forEach((criterionTmp) => {
      if (criterion.id === criterionTmp.id) {
        criterionTmp.attachment = criterionTmp?.attachment?.filter((item) => {
          if (attachment.type === 'PUBMED') {
            return item.PMID !== attachment.PMID;
          } else {
            return item.id !== attachment.id;
          }
        });
        criterionTmp.attachment_pubmed = criterionTmp?.attachment_pubmed?.filter((item) => item.PMID !== attachment.PMID);
        criterionTmp.attachment_files = criterionTmp?.attachment_files?.filter((item) => item.id !== attachment.id);
      }
    });
    this.currentProposal = JSON.parse(JSON.stringify(this.proposal));
  }

  saveCriterionValue(criterio: any) {
    criterio.initialValue = criterio.value;
    criterio.modifyEnabled = false;
    criterio.modified = true;
    this.proposal.criteria.forEach((criterion) => {
      if (criterion.id === criterio.id) {
        criterion.value = criterio.value;
        criterion.initialValue = criterio.value;
      }
    });
    this.currentProposal = JSON.parse(JSON.stringify(this.proposal));
  }

  /** Disegna i grafici di contraddizione e certezza */
  drawChartContraddition(criterio: any) {
    const data = google.visualization.arrayToDataTable([
      ['Element', 'Density', {role: 'style'}],
      [
        this.translateService.instant('generic.certainty-degree'),
        {
          v: criterio.results.certainty_degree,
          f: criterio.results.certainty_degree + '%',
        },
        '#1b61a1',
      ],
      [
        this.translateService.instant('generic.contradiction-degree'),
        {
          v: criterio.results.contradiction_degree,
          f: criterio.results.contradiction_degree + '%',
        },
        '#ff0000',
      ],
    ]);

    const view = new google.visualization.DataView(data);
    view.setColumns([
      0,
      1,
      {
        calc: 'stringify',
        sourceColumn: 1,
        type: 'string',
        role: 'annotation',
      },
      2,
    ]);

    const chart = new google.visualization.BarChart(
      document.getElementById('chart_div_' + criterio.title)
    );
    chart.draw(view, this.chartOptionsContradiction);
  }

  /** Preparo i dati formattando gli obiettivi secondari uno dopo l'altro per rappresentare i pesi degli obiettivi secondari sul principale */
  prepareTargetChartData() {
    //  se non ci sono risultati calcolati per questa sessione non devo mostrare i grafici
    if (!this.currentProposal.decisionTableResult) {
      return;
    }

    //  recupero i punteggi per i pesi standard
    const groupedSTD = HelperService.groupBy(
      this.currentProposal.decisionTableResult.results_on_target,
      'weight_system'
    );

    //  raggruppo per target tutti i punteggi del sistema di peso "standard"
    const grouppedByTarget = HelperService.groupBy(
      groupedSTD['Expert (STD)'],
      'target'
    );

    //  aggiungo agli obiettivi secondari il punteggio che mi interessa rappresentare nel
    this.currentProposal.secondary_targets.forEach((secondary_target) => {
      secondary_target.chartData = {
        value:
        grouppedByTarget[secondary_target.title][0].target_weight_perc_avg,
      };
    });

    //  disegno il grafico
    this.drawChartTarget(this.currentProposal.secondary_targets);
  }

  /** Preparo i dati formattando le informazioni dei criteri */
  prepareCriteriaChartData() {
    this.currentProposal.criteria.forEach((criterio) => {
      criterio.chartData = {
        avg_weight:
          _.sumBy(criterio.proposal_evaluations, function (o: any) {
            return o.weight;
          }) / criterio.proposal_evaluations.length,
      };
      if (isNaN(criterio.chartData.avg_weight)) {
        criterio.chartData.avg_weight = 0;
      }
    });
  }

  /** Disegno i grafici dei target */
  drawChartTarget(secondaryTargets: any) {
    const arrayData = [['Target', '', {role: 'style'}, {role: 'annotation'}]];
    secondaryTargets.forEach((target) => {
      arrayData.push([
        target.title,
        {v: target.chartData.value, f: target.chartData.value},
        '#DCDEFA',
        target.chartData.value,
      ]);
    });

    //  setto il colore
    secondaryTargets.forEach((target) => {
      const currentArrayData = JSON.parse(JSON.stringify(arrayData));
      const index = currentArrayData.findIndex((x) => x[0] == target.title);
      if (index >= 0) {
      }
      currentArrayData[index][2] = '#9169DB';

      const data = google.visualization.arrayToDataTable(currentArrayData);
      const view = new google.visualization.DataView(data);

      setTimeout(() => {
        const chart = new google.visualization.BarChart(
          document.getElementById('chart_div_target_' + target.title)
        );
        chart.draw(view, this.chartOptionsTarget);
      }, 100);
    });
  }

  /**
   * Aggiunta di un nuovo
   * @param criterio
   * @param attachment_type
   */
  onClickAddNewEvidence(criterio: any, attachment_type: string) {
    this.saveCriterionValue(criterio)
    this.addNewEvidenceEvent.emit({
      criterio,
      attachment_type,
    });
  }

  showExpertEvideces(objectUser) {
    const dialogRef = this.dialog.open(ModalShowEvidencesComponent, {
      panelClass: 'medium-dialog',
      data: {
        userFullName: objectUser?.team_member?.name || objectUser?.team_member?.email,
        attachments: objectUser.attachment_expert_pubmed.concat(objectUser.attachment_expert_files)
      },
    });

    dialogRef.afterClosed().subscribe((event) => {
    });
  }

  /**
   * Evento di apertura della lista di allegati cliccati dall'esperto
   */
  onClickAttachmentEventList(response: any) {
    this.addAttachmentListEvent.emit({
      criteria_id: response.criteria_id,
      team_member_id: response.team_member_id,
    });
  }

  onClickSaveAs(title) {
    if (this.userService.currentOrganization.customs?.profile === 'alexion') {
      this.loading = true;
      this.decisionService.addNewRevision(
        this.decision_table.id,
        this.currentProposal,
        (result) => {
          this.loading = false;
          this.saveAndAddEvent.emit(result);
        },
        () => (this.loading = false)
      );
    } else {
      const dialogRef = this.dialog.open(ModalProposalRenameComponent, {
        panelClass: 'medium-dialog',
        data: {
          proposalTitle: title
        },
      });
      dialogRef.afterClosed().subscribe((event) => {
        if (event != null) {
          this.currentProposal.title = event;
          this.loading = true;
          this.decisionService.addNewRevision(
            this.decision_table.id,
            this.currentProposal,
            (result) => {
              this.loading = false;
              this.saveAndAddEvent.emit(result);
            },
            (error) => (this.loading = false)
          );
        }
      });
    }
  }


  /**
   * Evento di modifica di una descrizione di un criterio
   */
  onClickModifyDescription(criterio: any) {
    criterio.modifyEnabled = true;
    criterio.initialValue = criterio.value;
  }

  /**
   * Evento di cancellazione di una descrizione di un criterio
   * @param criterio
   */
  onClickCancelDescription(criterio: any) {
    criterio.value = criterio.initialValue;
    criterio.modifyEnabled = false;
    criterio.criterioModified = false;
  }


  public deleteProposal() {
    if (this.proposal.evaluation_session_id == null) {
      swal.fire({
        title: this.translateService.instant(
          'components.decision-table.show-decision-table.proposal.delete-alert-title'
        ),
        text: this.translateService.instant(
          'components.decision-table.show-decision-table.proposal.delete-alert-text'
        ),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#9169db',
        confirmButtonText: this.translateService.instant(
          'components.decision-table.show-decision-table.proposal.delete-alert-confirm-button-text'
        ),
        cancelButtonText: this.translateService.instant(
          'components.decision-table.show-decision-table.proposal.delete-alert-cancel-button-text'
        ),
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          this.decisionService.deleteProposal(
            this.decision_table.id,
            this.proposal,
            (result: any) => {
              this.loading = false;
              this.toast.success(
                this.translateService.instant(
                  'components.decision-table.show-decision-table.proposal.success-delete-proposal'
                )
              );
              this.saveAndAddEvent.emit({is_deleted: true, proposal_id: this.currentProposal.id});
            },
            () => {
              this.loading = false;
              this.toast.error(
                this.translateService.instant(
                  'components.decision-table.show-decision-table.proposal.error-delete-proposal'
                )
              );
            }
          );
        }
      });
    }
  }

  updateProposal() {
    if (this.proposalIsValid()) {
      this.loading = true;
      this.decisionService.updateProposal(
        this.decision_table.id,
        this.currentProposal,
        (result) => {
          this.loading = false;
          this.saveAndBackEvent.emit(this.currentProposal);
          return;
        },
        (error) => (this.loading = false)
      );
    }
  }

  proposalIsValid() {
    let valid = true;
    this.currentProposal.criteria.forEach((criterion) => {
      if (criterion.value === '') {
        valid = false;
        this.toast.error('Butterfly Decisions', this.translateService.instant('components.proposals.criteria.is-empty'));
        return;
      }
      if (criterion.typology === 'QUANTITATIVE') {
        if (isNaN(criterion.value)) {
          valid = false;
          this.toast.error('Butterfly Decisions', this.translateService.instant('components.proposals.quantitative-numeric-value-not-valid'));
          return;
        }
      }
    });
    return valid;
  }

  isEdited() {
    let edited = false;
    this.proposal.criteria.forEach((criterion) => {
      if (criterion.originalValue !== criterion.initialValue) {
        edited = true;
      }
    });
    return edited;
  }

  saveAndBack() {
    this.saveAndBackEvent.emit();
  }

  saveAndAdd() {
    if (this.isEdited() && this.proposalIsValid()) {
      this.loading = true;
      this.decisionService.addNewRevision(
        this.decision_table.id,
        this.currentProposal,
        (result) => {
          this.loading = false;
          this.saveAndAddEvent.emit(result);
        },
        (error) => (this.loading = false)
      );
    } else {
      //  TODO_TRADUZIONI
      // this.alert.showAlert(
      //   'Attenzione',
      //   'La proposta sarà salvata come nuova'
      // );
    }
    // this.saveAndAddEvent.emit();
  }


  getCriteriaService() {
    return this.criteriaService;
  }

  getUnitsService() {
    return this.unitService;
  }

  getSuggestionService() {
    return this.suggestionService;
  }

  getResponsesTypeService() {
    return this.responsesTypeService;
  }

  prepareChartMultipleChoiceCriteria() {
    this.currentProposal.criteria.forEach((criterio) => {
      if (
        this.criteriaService.criteriaIsMultipleChoice(criterio.typology)
      ) {
        if (criterio.response.length > 0 && !this.isAlexion()) {
          this.drawMultipleChoiceChart(criterio);
        }
      }
    });
  }

  isAlexion() {
    return this.userService.currentOrganization?.customs?.profile === 'alexion';
  }

  drawMultipleChoiceChart(criterio: any) {
    const totalResponses = criterio.options.reduce((total, option) => total + option.responses.length, 0);
    const dataArray = criterio.options.map((option) => [option.title, option.responses.length]);
    var biggest = 0;
    criterio.options.forEach((option) => {
      option.responsePercentage = ((option?.responses?.length / totalResponses) * 100).toFixed(2);
      if (option.responsePercentage > biggest) {
        biggest = option.responsePercentage;
      }
    });
    criterio.options.forEach((option) => {
      if (option.responsePercentage === biggest) {
        option.isBiggest = true;
      }
    });

    const str1 = this.translateService.instant('components.decision-table.new-decision-table.compile-proposal.title');
    const str2 = this.translateService.instant('generic.votes');

    let data = google.visualization.arrayToDataTable([
      [str1, str2],
      ...dataArray
    ]);

    var chart = new google.visualization.PieChart(document.getElementById('pie_chart_' + criterio.id));
    chart.draw(data, {});
  }
}
