import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {faBullhorn, faEnvelope, faTimes} from '@fortawesome/free-solid-svg-icons';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {SurveyData} from '../all-surveys/all-surveys.component';
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import {TranslateService} from "@ngx-translate/core";
import {AlertService} from "../../../services/general/alert.service";
import {SurveyService} from "../../../services/surveys.service";
import {ToastService} from "../../../services/general/toast.service";
import {ModalEmailResponsesComponent} from "../../../modals/modal-email-responses/modal-email-responses.component";
import {MatDialog} from "@angular/material/dialog";
import {HelperService} from "../../../services/general/helper.service";
import {faDownload} from "@fortawesome/free-solid-svg-icons/faDownload";

@Component({
  selector: 'app-survey-team',
  templateUrl: './survey-team.component.html',
  styleUrls: ['./survey-team.component.scss']
})
export class SurveyTeamComponent implements OnInit {
  @Input() members: any;
  @Input() surveyId: any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public constructor(
    private translateService: TranslateService,
    private alert: AlertService,
    private surveyService: SurveyService,
    private toast: ToastService,
    private dialog: MatDialog
  ) {
  }

  dataSource: MatTableDataSource<unknown>;
  displayedColumns = ['name', 'email', 'response_status', 'email_status'];
  singleTeamMemberToAddActive = false;
  loading = false;


  public faEnvelope = faEnvelope;
  public faBullhorn = faBullhorn;

  protected readonly faPlusCircle = faPlusCircle;
  protected readonly faTimes = faTimes;

  ngOnInit(): void {
    this.init();
  }

  init() {
    this.setDataSourceTable();
  }

  public setDataSourceTable() {
    this.dataSource = new MatTableDataSource<unknown>(this.members);
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, 100);
  }

  toggleAddNewTeamMemberMode() {
    this.singleTeamMemberToAddActive = !this.singleTeamMemberToAddActive;
  }

  onAddTeamMember(teamMember) {
    this.toggleAddNewTeamMemberMode();
    this.members.push(teamMember);
    this.init();
  }

  resendInvitation(element) {
    if (element.id) {
      this.alert.showQuestion(
        this.translateService.instant('generic.warning'),
        this.translateService.instant(
          'components.decision-table.show-decision-table.alert.resend-invitation'
        ),
        (ok) => {
          this.surveyService.resendInvitation(this.surveyId, element.id, () => {
            this.toast.success(
              this.translateService.instant(
                'components.decision-table.show-decision-table.alert.invitation-sent'
              )
            );
          });
        }
      );
    }
  }

  emailStatusByTeamMember(row) {
    if (row?.email_responses && row?.email_responses.length > 0) {
      return row.email_responses[0].event;
    }
    return '';
  }

  //  mostra la modale con i dettagli delle email inviate
  showEmailResponses(row) {

    //  se non ci sono risposte lascio perdere la visualizzazione della modale
    if (row?.email_responses && row?.email_responses.length > 0) {
      const dialogRef = this.dialog.open(ModalEmailResponsesComponent, {
        panelClass: 'medium-dialog',
        data: {
          team_member_id: row.id,
          origin_id: this.surveyId,
          origin: 'SURVEY'
        }
      });
    }
  }

  emailColorByTeamMember(row) {
    if (row?.email_responses && row?.email_responses.length > 0) {
      return HelperService.getColorByEmailEvent(row.email_responses[0].event);
    }
    return '';
  }

  downloadSurveyTeamDataCSV() {
    this.loading = true;
    this.surveyService.downloadSurveyTeamData(this.surveyId, (response) => {
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  protected readonly faDownload = faDownload;
}
