<form (keydown.enter)="$event.preventDefault()" novalidate>
  <!-- Blocco 1 - Obiettivi -->
  <div class="container-fluid accordion-container">
    <div class="container">
      <mat-accordion class="accordion" multi>
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="index">1</span>
              {{ "generic.objectives" | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="row pl-4 header-title">
            <!-- <p class="mb-0 mr-2">Tempo impiegato per la compilazione</p> -->
            <!-- <span class="main-color text-uppercase">1 ORA</span> -->
          </div>

          <div class="row">
            <div class="col-12 px-2" *ngIf="currentProposal.primary_target">
              <div class="box px-0 box-two-row">
                <mat-label class="box-title px-3">{{
                  "components.proposals.new-proposal.primary" | translate
                  }}</mat-label>
                <mat-form-field class="input-primary col-6">
                  <input
                    matInput
                    name="target_primary_title"
                    [ngModel]="currentProposal.primary_target.title"
                    placeholder="{{ 'generic.title' | translate }}"
                    readonly
                  />
                </mat-form-field>
                <mat-form-field class="datepicker-primary col-3">
                  <mat-label>{{
                    "components.proposals.new-proposal.due-date" | translate
                    }}</mat-label>
                  <input
                    matInput
                    name="target_primary_date"
                    class="ng-trim-ignore color-default"
                    [ngModel]="
                      currentProposal.primary_target.date != null
                        ? (currentProposal.primary_target.date
                          | formatdate
                          | date : 'shortDate')
                        : 'non definita'
                    "
                    placeholder="{{
                      'components.proposals.new-proposal.due-date' | translate
                    }}"
                    disabled
                  />
                </mat-form-field>
                <mat-form-field class="input-primary col-3">
                  <mat-label>{{
                    "generic.measure-unit" | translate
                    }}</mat-label>
                  <input
                    matInput
                    name="target_primary_unit"
                    [ngModel]="
                      getUnitsService().getUnitValueByKey(
                        currentProposal.primary_target.measure_unit
                      )
                    "
                    readonly
                  />
                </mat-form-field>
                <mat-form-field class="input-primary col-6">
                  <input
                    matInput
                    name="target_primary_objective"
                    placeholder="{{
                      'components.proposals.new-proposal.target-value'
                        | translate
                    }}"
                    [ngModel]="
                      getCriteriaService().getCriteriaValueByKey(
                        currentProposal.primary_target.evaluation_criteria
                      )
                    "
                    readonly
                  />
                </mat-form-field>
                <mat-form-field
                  class="input-primary col-6"
                  *ngIf="currentProposal.primary_target.expected_value"
                >
                  <input
                    matInput
                    name="proposal_target_primary_expected_value"
                    placeholder="{{
                      'components.proposals.new-proposal.desidered-value'
                        | translate
                    }}"
                    [ngModel]="currentProposal.primary_target.expected_value"
                  />
                </mat-form-field>
                <i class="icon-circle_info top-right grey-color"></i>
              </div>
            </div>

            <div
              class="col-6 px-2"
              *ngFor="
                let target of currentProposal?.secondary_targets;
                let i = index
              "
            >
              <ng-container>
                <div class="box px-0 box-two-row">
                  <mat-label class="box-title px-3">{{
                    "components.proposals.new-proposal.secondary" | translate
                    }}</mat-label>
                  <mat-form-field class="input-primary col-6">
                    <input
                      matInput
                      name="secondary_targets{{ i }}"
                      [(ngModel)]="target.title"
                      readonly
                    />
                  </mat-form-field>
                  <mat-form-field
                    class="select-primary col-6"
                    appearance="fill"
                  >
                    <mat-label>{{
                      "components.proposals.new-proposal.evaluation-criteria"
                        | translate
                      }}</mat-label>
                    <mat-select
                      name="secondary_target_evaluation{{ i }}"
                      [(ngModel)]="target.evaluation_criteria"
                      required
                    >
                      <mat-option value="" selected></mat-option>
                      <mat-option
                        *ngFor="
                          let criterion of getCriteriaService().getAllCriteriaForSelect()
                        "
                        [value]="criterion.key"
                      >{{ criterion.value }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                  <i class="icon-circle_info top-right grey-color"></i>
                </div>
              </ng-container>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <!-- Blocco 2 - Criteri -->
  <div class="container-fluid accordion-container">
    <div class="container">
      <mat-accordion class="accordion" multi>
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="index">2</span>
              {{ "generic.criteria" | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="row pl-4 header-title">
            <!-- <p class="mb-0 mr-2">Tempo impiegato per la compilazione</p> -->
            <!-- <span class="main-color text-uppercase">1 ORA</span> -->
          </div>

          <div
            class="container-fluid accordion-container mt-3 accordion-inner"
            *ngFor="let criterio of currentProposal.criteria; let i = index"
          >
            <ng-container>
              <div class="container">
                <mat-accordion class="accordion" multi>
                  <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <mat-icon
                          class="yellow-color"
                          *ngIf="
                            getSuggestionService().isAlertSuggestion(
                              criterio.results?.suggestion
                            )
                          "
                          aria-hidden="false"
                        >report_problem
                        </mat-icon
                        >
                        &nbsp;
                        {{ criterio.title }}
<!--                        <span class="float-right ml-auto font-weight-bold mr-2">-->
<!--                                        <i class="icon-circle_info mr-2"></i>-->
<!--                          {{ getCriteriaService().getCriteriaTypeValueByKey(criterio.typology) }}-->
<!--                          <span class="yellow-color" *ngIf="criterio.is_revisioned">| {{ 'generic.revisioned' | translate }}</span>-->
<!--                        </span>-->
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="row">
                      <div class="ml-auto my-3">
                        <button
                          *ngIf="criterio.modifyEnabled && criterionIsChanged(criterio)"
                          class="size-small text-white mr-2"
                          mat-flat-button
                          color="secondary"
                          (click)="saveCriterionValue(criterio)"
                          [disabled]="decision_table.closing_date != null"
                        >
                          {{ "generic.buttons.save" | translate }}
                        </button>
                        <button
                          *ngIf="!criterio.modifyEnabled && isContestProposalAccepted(proposal)"
                          class="size-small text-white"
                          mat-flat-button
                          color="secondary"
                          (click)="onClickModifyDescription(criterio)"
                          [disabled]="decision_table.closing_date != null"
                        >
                          {{ "generic.buttons.modify" | translate }}
                        </button>
                        <button
                          *ngIf="criterio.modifyEnabled"
                          class="size-small danger text-white"
                          mat-flat-button
                          (click)="onClickCancelDescription(criterio)"
                        >
                          {{ "generic.cancel" | translate }}
                        </button>
                        <button
                          *ngIf="isVirtualAssistantServiceEnabled()"
                          class="size-small danger text-white"
                          mat-flat-button
                          (click)="onClickModalMagicWriter(criterio)"
                        >
                          {{'modal.modal-virtual-assistant.title' | translate}}
                        </button>
                      </div>
                    </div>
                    <!-- Text Area -->
                    <div class="col-12 px-2">
                      <div class="box flex-column my-0 pb-0 pt-3 px-3">
                        <mat-form-field>
                          <mat-label>{{
                            "generic.description" | translate
                            }}</mat-label>
                          <textarea
                            class="description"
                            matInput
                            resizeToFitContent="true"
                            name="criterio_description{{ i }}"
                            placeholder="{{
                              'components.proposals.new-proposal.placeholders.insert-description'
                                | translate
                            }}"
                            (change)="onChangeCriterioDescription(criterio)"
                            [(ngModel)]="criterio.value"
                            [disabled]="!criterio.modifyEnabled"
                          >
                          </textarea>
                        </mat-form-field>
                      </div>
                      <!-- <div class="box flex-column my-0 pb-0 pt-3 px-3 bordered">
                                            <mat-form-field>
                                                <mat-label>Descrizione</mat-label>
                                                <textarea class="description" matInput rows="2" name="criterio_description{{i}}"
                                                    placeholder="Inserire descrizione"></textarea>
                                            </mat-form-field>
                                        </div> -->
                    </div>

                    <!-- Parte dedicata all'aggiunta degli allegati -->
                    <div class="row pt-4 pb-0 my-3">
                      <div class="col-12 mt-3">
                        <mat-label
                          class="label-box font-weight-bold"
                          *ngIf="criterio?.attachment?.length > 0"
                        >
                          {{
                          "components.proposals.new-proposal.loaded-evidences"
                            | translate
                          }}
                        </mat-label>
                        <div
                          class="attachment-row"
                          *ngFor="let attachment of criterio.attachment"
                        >
                          <span class="name">
                            <i class="icon-pdf"></i>
                            <a [href]="attachment.link" target="_blank">{{
                              attachment.title
                              }}</a>
                          </span>
                          <span *ngIf="criterio.modifyEnabled" class="size float-right"
                                (click)="removeAttachment(attachment, criterio)">
                            {{ "generic.buttons.delete" | translate }}
                            <i class="icon-cestino main-color"></i>
                          </span>
                        </div>
                        <div
                          class="col-12 links-container"
                          [hidden]="decision_table.closing_date != null || !criterio.modifyEnabled"
                        >
                          <button
                            mat-button
                            color="primary"
                            [matMenuTriggerFor]="menuAttachment"
                          >
                            <i class="icon-upload mr-2"></i>
                            {{
                            "components.proposals.new-proposal.add-new-evidences"
                              | translate
                            }}
                          </button>
                          <mat-menu #menuAttachment="matMenu">
                            <button
                              mat-menu-item
                              (click)="
                                onClickAddNewEvidence(criterio, 'PUBMED')
                              "
                            >
                              <mat-icon aria-hidden="false"
                              >file_upload
                              </mat-icon
                              >
                              Pubmed
                            </button>
                            <button
                              mat-menu-item
                              (click)="onClickAddNewEvidence(criterio, 'FILE')"
                            >
                              <mat-icon aria-hidden="false"
                              >file_upload
                              </mat-icon
                              >
                              File
                            </button>
                          </mat-menu>
                        </div>
                      </div>
                    </div>

                    <!-- Non ci sono grafici per elementi quantitativi -->
                    <div
                      class="row"
                      *ngIf="
                        getCriteriaService().criteriaIsQualitative(
                          criterio.typology
                        )
                      "
                    >
                      <div class="col-6">
                        <p class="text-center py-5" *ngIf="loadingGraphics">
                          {{
                          "components.proposals.new-proposal.loading-graph"
                            | translate
                          }}
                        </p>
                        <div
                          style="width: 100%"
                          id="chart_div_{{ criterio.title }}"
                        ></div>
                      </div>
                      <div class="col-6">
                        <p class="text-center py-5" *ngIf="loadingGraphics">
                          {{
                          "components.proposals.new-proposal.loading-graph"
                            | translate
                          }}
                        </p>
                        <div
                          style="width: 100%"
                          id="chart_feedback_div_{{ criterio.title }}"
                        ></div>
                      </div>
                    </div>

                    <!--<div class="row">
                      <mat-form-field class="input-primary col-4 mt-3">
                        <mat-label>{{
                          "generic.criterium" | translate
                          }}</mat-label>
                        <input
                          matInput
                          name="criterio_typology{{ i }}"
                          [ngModel]="
                            getCriteriaService().getCriteriaTypeValueByKey(
                              criterio.typology
                            )
                          "
                          readonly
                        />
                      </mat-form-field>
                      &lt;!&ndash; Non ci sarà suggerimento per gli elementi quantitativi &ndash;&gt;
                      <mat-form-field
                        class="input-primary col-6 mt-3"
                        *ngIf="
                          getCriteriaService().criteriaIsQualitative(
                            criterio.typology
                          ) && getSuggestionService().getConsensusSuggestValueByKey(
                              criterio.results?.suggestion
                            )
                        "
                      >
                        <mat-label>
                          {{ "generic.hint" | translate }}
                          <i class="icon-circle_info grey-color ml-2"></i>
                        </mat-label>
                        <input
                          matInput
                          name="risk_suggestion{{ i }}"
                          [ngModel]="
                            getSuggestionService().getConsensusSuggestValueByKey(
                              criterio.results?.suggestion
                            )
                          "
                          class="{{
                            getSuggestionService().getClassSuggestionForCriteria(
                              criterio.results
                            )
                          }}"
                          readonly
                        />
                      </mat-form-field>
                    </div>-->

                    <div *appShowIfOrganizationHasFeature="'virtual_assistant'">
                      <app-single-question-ai-analysis type="decision_table" [object]="this.decision_table" [question]="this.criterio"/>
                    </div>

                    <!-- Non ci sarà tabella delle risposte per elementi quantitativi -->
                    <div
                      class="row"
                      *ngIf="
                        criterio.response &&
                        getCriteriaService().criteriaIsQualitative(
                          criterio.typology
                        )
                      "
                    >
                      <!-- Tabella Feedback-->
                      <div
                        class="col-12 px-2"
                        *ngIf="criterio.response.length > 0"
                      >
                        <mat-table
                          [dataSource]="criterio.response"
                          class="table-primary"
                        >
                          <!-- Name Column -->
                          <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef>{{
                              "generic.name" | translate
                              }}</mat-header-cell>
                            <mat-cell *matCellDef="let element; let i = index">
                              <span
                                *ngIf="element?.team_member?.name && showPrivateInfo; else prova">{{ element?.team_member?.name }}</span>
                              <ng-template #prova>
                                <span>{{ "generic.user" | translate }} {{ i + 1 }}</span>
                              </ng-template>


                            </mat-cell>
                          </ng-container>

                          <!-- Sentiment Column -->
                          <ng-container matColumnDef="sentiment">
                            <mat-header-cell
                              *matHeaderCellDef
                              style="justify-content: center"
                            >{{ "generic.sentiment" | translate }}
                            </mat-header-cell>
                            <mat-cell
                              *matCellDef="let element"
                              style="justify-content: center"
                            >
                              <mat-icon
                                aria-hidden="false"
                                *ngIf="element.sentiment_score == 0"
                              >sentiment_neutral
                              </mat-icon
                              >
                              <mat-icon
                                aria-hidden="false"
                                style="color: #00bc1d"
                                *ngIf="element.sentiment_score > 0"
                              >sentiment_satisfied_alt
                              </mat-icon
                              >
                              <mat-icon
                                aria-hidden="false"
                                style="color: #ff0000"
                                *ngIf="element.sentiment_score < 0"
                              >sentiment_very_dissatisfied
                              </mat-icon
                              >
                            </mat-cell>
                          </ng-container>

                          <!-- Feedback Column -->
                          <ng-container matColumnDef="feedback">
                            <!-- <mat-header-cell *matHeaderCellDef style="flex: 0 0 50% !important">Feedback </mat-header-cell>
                                              <mat-cell *matCellDef="let element" style="flex: 0 0 50% !important"> {{element.feedback}} </mat-cell> -->
                            <mat-header-cell
                              *matHeaderCellDef
                              style="flex: 0 0 50% !important"
                            >{{
                              "generic.feedback" | translate
                              }}</mat-header-cell
                            >
                            <mat-cell
                              *matCellDef="let element"
                              style="flex: 0 0 50% !important"
                            >
                              {{ element.feedback }}
                            </mat-cell>
                          </ng-container>

                          <!-- True False Column -->
                          <ng-container matColumnDef="truefalse">
                            <mat-header-cell *matHeaderCellDef>{{
                              "components.proposals.new-proposal.true-false"
                                | translate
                              }}</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                              <mat-chip-list>
                                <mat-chip
                                  selectable="false"
                                  *ngIf="
                                    getResponsesTypeService().isConsensusAgree(
                                      element.consent_value
                                    )
                                  "
                                  style="
                                    justify-content: center;
                                    pointer-events: none;
                                    background-color: #00bc1d;
                                  "
                                  class="text-white"
                                >{{
                                  "generic.answers.true" | translate
                                  }}</mat-chip
                                >
                                <mat-chip
                                  selectable="false"
                                  *ngIf="
                                    getResponsesTypeService().isConsensusDisagree(
                                      element.consent_value
                                    )
                                  "
                                  style="
                                    justify-content: center;
                                    pointer-events: none;
                                    background-color: #ff0000;
                                  "
                                  class="text-white"
                                >{{
                                  "generic.answers.false" | translate
                                  }}</mat-chip
                                >
                                <mat-chip
                                  selectable="false"
                                  *ngIf="
                                    getResponsesTypeService().isConsensusAbstained(
                                      element.consent_value
                                    )
                                  "
                                  style="
                                    justify-content: center;
                                    pointer-events: none;
                                    background-color: rgb(167, 167, 167);
                                  "
                                  class="text-white"
                                >{{
                                  "generic.answers.abstained" | translate
                                  }}</mat-chip
                                >
                              </mat-chip-list>
                            </mat-cell>
                          </ng-container>

                          <!-- Confidence Column -->
                          <ng-container matColumnDef="confidence">
                            <mat-header-cell
                              *matHeaderCellDef
                              style="justify-content: center"
                            >{{
                              "components.proposals.new-proposal.confidence"
                                | translate
                              }}</mat-header-cell
                            >
                            <mat-cell
                              *matCellDef="let element"
                              style="justify-content: center"
                            >
                              <mat-chip-list
                              >
                                <mat-chip>{{
                                  element.confidence
                                  }}</mat-chip>
                              </mat-chip-list
                              >
                            </mat-cell>
                          </ng-container>

                          <!-- evidence Column -->
                          <ng-container matColumnDef="evidence">
                            <mat-header-cell
                              *matHeaderCellDef
                              style="justify-content: center"
                            >{{
                              "components.proposals.new-proposal.evidences"
                                | translate
                              }}</mat-header-cell
                            >
                            <mat-cell
                              *matCellDef="let element"
                              style="justify-content: center"
                              (click)="element?.attachment_expert_pubmed.length + element?.attachment_expert_files.length > 0 ? showExpertEvideces(element) : ''"
                            >
                              <mat-chip-list
                              >
                                <mat-chip>
                                  {{
                                  element?.attachment_expert_pubmed.length +
                                  element?.attachment_expert_files.length
                                  }}</mat-chip>
                              </mat-chip-list
                              >
                            </mat-cell>

                          </ng-container>

                          <!-- allegati aperti Column -->
                          <ng-container matColumnDef="attachment_events">
                            <mat-header-cell
                              *matHeaderCellDef
                              style="justify-content: center"
                            >{{
                              "components.proposals.new-proposal.attachment-opened"
                                | translate
                              }}</mat-header-cell
                            >
                            <mat-cell
                              *matCellDef="let element"
                              style="justify-content: center"
                            >
                              <mat-chip-list
                              >
                                <mat-chip
                                  style="cursor: pointer"
                                  *ngIf="element.attachment_events"
                                  (click)="element?.attachment_events.length > 0 ? onClickAttachmentEventList(element) : ''"
                                >{{
                                  element?.attachment_events.length
                                  }}</mat-chip
                                >
                              </mat-chip-list
                              >
                            </mat-cell>
                          </ng-container>

                          <mat-header-row
                            *matHeaderRowDef="displayedColumns"
                          ></mat-header-row>
                          <mat-row
                            *matRowDef="let row; columns: displayedColumns"
                          ></mat-row>
                        </mat-table>
                      </div>

                      <!-- Text Area -->
                      <!-- <div class="col-12 px-2">
                                            <div class="box flex-column my-0 pb-0 pt-3 px-3 bordered">
                                                <mat-form-field>
                                                    <mat-label>Descrizione</mat-label>
                                                    <textarea class="description" matInput rows="2" name="criterio_description{{i}}"
                                                        placeholder="Inserire descrizione"></textarea>
                                                </mat-form-field>
                                            </div>
                                        </div> -->
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </ng-container>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <!-- Buttons -->
  <div class="buttons-container container py-4">
    <button
      class="button-primary float-left"
      mat-button
      (click)="updateProposal()"
      *ngIf="!isInEvalutationSession && loggedUserDetails.id == proposal.proposed_by?.user_id"
      [disabled]="decision_table.closing_date != null"
    >
      <i class="icon-Backward-arrow-small"></i>
      {{ "generic.buttons.save-and-back" | translate }}
    </button>
    <button
      class="button-primary float-right"
      mat-button
      (click)="onClickSaveAs(currentProposal.title)"
      [disabled]="decision_table.closing_date != null"
      *ngIf="isContestProposalAccepted(proposal)"
    >
      <i class="icon-Forward-arrow-small"></i>
      <label>
        {{ "components.proposals.new-proposal.save-and-add-proposal" | translate }}
      </label>
    </button>

    <button
      class="button-danger float-right mr-5"
      mat-button
      (click)="deleteProposal()"
      *ngIf="!proposalIsRevisioned(proposal) && proposal.evaluation_session_id == null && decision_table?.closing_date == null && loggedUserDetails.id == proposal.proposed_by?.user_id"
    >
      <i class="icon-Forward-arrow-small"></i>
      <label>
        {{ "components.proposals.new-proposal.delete-proposal" | translate }}
      </label>
    </button>
  </div>
</form>

