<div class="container-fluid box-sub-header py-3 pt-lg-5">
  <button class="anonymize-button" *ngIf="suggestedProposals?.length > 0 && enabledSuggestedProposalsButton" (click)="showSuggestedProposals()">
    {{ 'components.responses.view-suggested-proposals' | translate }}
  </button>
  <div class="container">
    <!-- Sezione alert -->
    <ng-container *ngIf="!loading">
      <mat-card *ngIf="decision_table?.closing_date" class="my-3" style="background-color: red; color: white;">
        {{'components.responses.decision-table-closed' | translate}}
      </mat-card>
      <mat-card *ngIf="!evaluation_session?.collecting && !decision_table?.closing_date" class="my-3" style="background-color: red; color: white;">
        {{'components.decision-session.decision-session-closed' | translate}}
      </mat-card>
      <mat-card *ngIf="response_stamps?.completed" class="my-3" style="background-color: red; color: white;">
        {{'components.responses.responses-already-sent' | translate}}
      </mat-card>
    </ng-container>

      <mat-form-field class="col-12 col-lg-6 select-custom-button" appearance="fill">
          <mat-label>{{'generic.organization' | translate}}</mat-label>
          <input matInput name="organization_name" [ngModel]="organization?.name" placeholder="{{'generic.organization' | translate}}" readonly>
      </mat-form-field>
      <mat-form-field class="col-12 col-lg-6 datepicker-custom-button" appearance="fill">
          <mat-label>{{'components.responses.due-date' | translate}}</mat-label>
          <input matInput name="evaluation_end" [ngModel]="evaluation_session?.evaluation_end != null ? (evaluation_session?.evaluation_end| formatdate | date: 'shortDate') : translate('components.responses.undefined-due-date')" placeholder="{{'components.responses.due-date' | translate}}" readonly>
      </mat-form-field>
  </div>
</div>

<div class="container-fluid accordion-container px-0 px-md-3">
  <div class="container">
      <mat-accordion class="accordion" multi>
          <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                  <mat-panel-title>
                      <span class="index">1</span>
                      {{'generic.objectives' | translate}}
                  </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row">
                <div class="col-12 px-2" *ngIf="primary_target.length > 0">
                  <div class="box px-0 box-two-row border-0 bg-transparent" *ngFor="let primaryTarget of primary_target">
                      <mat-label class="box-title px-3">{{'generic.primary' | translate}}</mat-label>
                      <mat-form-field class="input-primary col-12 col-lg-6">
                          <input matInput [ngModel]="primaryTarget.title" placeholder="{{'generic.title' | translate}}" readonly>
                      </mat-form-field>
                      <mat-form-field class="datepicker-primary col-7 col-lg-6 px-1" appearance="fill">
                        <mat-label>{{'components.responses.reach-day' | translate}}</mat-label>
                        <input matInput [ngModel]="(primaryTarget.date| formatdate | date:'shortDate')" placeholder="{{'generic.date' | translate}}" readonly>
                      </mat-form-field>
                      <mat-form-field class="input-primary col-5 col-lg-6 pt-1">
                        <input matInput placeholder="{{'generic.unit' | translate }}" [ngModel]="getUnitsService().getUnitValueByKey(primaryTarget.measure_unit)" readonly>
                      </mat-form-field>
                      <mat-form-field class="input-primary col-6 col-lg-6 pt-1">
                        <input matInput placeholder="{{'components.responses.target-value' | translate}}" [ngModel]="getCriteriaService().getCriteriaValueByKey(primaryTarget.evaluation_criteria)" readonly>
                      </mat-form-field>
                  </div>
              </div>
            </div>
          </mat-expansion-panel>
      </mat-accordion>
  </div>
</div>

<!-- Blocco 2 -->
<div class="container-fluid accordion-container px-0 px-md-3">
  <div class="container">
      <mat-accordion class="accordion" multi>
          <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                  <mat-panel-title>
                      <span class="index">2</span>
                      {{'generic.description' | translate}}
                  </mat-panel-title>
              </mat-expansion-panel-header>

            <ng-container>
              <div class="row">
                <div class="col-12 col-lg-6 px-0 mb-0">
                  <div class="box py-0 px-2 bg-transparent border-0 mb-0">
                    <mat-label class="box-title px-3 mb-0">{{'generic.filters' | translate}}</mat-label>
                  </div>
                </div>
              </div>
              <div class="row d-flex justify-content-center">
                <section style="padding: 0.75em 0.75em 0 0.75em;">
                  <mat-checkbox class="col-3" [(ngModel)]="onlyNotResponsed">{{'generic.only-answered' | translate}}</mat-checkbox>
                </section>
                <section style="padding: 0.75em 0.75em 0 0.75em;">
                  <mat-checkbox class="col-3" [(ngModel)]="exandedPanels">{{'pages.responses.filters.show-all-open' | translate}}</mat-checkbox>
                </section>
              </div>
            </ng-container>
              <div class="row">
                <ng-container *ngFor="let proposal of proposals; let proposal_index = index">
                  <div class="col-12 col-lg-12 px-0 mb-0">
                      <div class="box py-0 px-2 bg-transparent border-0 mb-0">
                          <mat-label class="box-title px-3 mb-0" style="font-size: 16px" [class]="{'mt-5': proposal_index > 0, 'mt-4': proposal_index == 0}">{{'generic.proposal' | translate}}: {{proposal.title}}
                            <span class="float-right" *ngIf="evaluation_session?.collecting && !decision_table?.closing_date && !response_stamps?.completed && evaluation_session.settings.review_enabled">
                              <button mat-button (click)="suggestProposalOnClick(proposal)">
                                  <i class="icon-re_edit mr-2"></i>
                                {{'components.responses.revision-response' | translate}}
                              </button></span></mat-label>
                            </div>
                  </div>

                  <ng-container *ngFor="let criteria of proposal.criteria; let criteria_index = index">
                    <div class="container-fluid accordion-container mt-3 accordion-inner rounded-0 bg-white arrow-left" *ngIf="!(onlyNotResponsed && criterionIsCompleted(criteria))">
                      <div class="container" *ngIf="getCriteriaService().criteriaIsQualitative(criteria.typology)">
                        <mat-accordion class="accordion" multi [togglePosition]="'before'">
                          <mat-expansion-panel class="px-0" [expanded]="exandedPanels">
                            <mat-expansion-panel-header>
                              <mat-panel-title>
                                <span>{{criteria.title}}</span>
                                <i class="icon-circle_info text-white d-flex"></i>
                                <i class="icon-checkmark d-flex abs-tr mx-0" [ngClass]="getIconColorForCriteria(criteria)"></i>
                                <span class="ml-auto" *ngIf="criteria.answered">
                                          <i class="icon-Yes d-flex text-success"><span class="ml-2">{{'components.responses.criteria.answered' | translate}}</span></i>
                                        </span>
                              </mat-panel-title>
                            </mat-expansion-panel-header>

                            <div class="row bclaorder-bottom-grey pt-0 pb-4 my-3">
                              <small class="col-12">{{'generic.description' | translate}}</small>
                              <div class="col-12">{{criteria.value}}</div>

                              <!-- Allegati da chi ha aperto il tavolo -->
                              <div class="col-12 mt-3" *ngIf="criteria?.attachment?.length > 0">
                                <mat-label class="label-box font-weight-bold">
                                  {{'components.responses.loaded-evidences' | translate}}
                                </mat-label>
                              </div>
                              <div class="col-12 mb-3" *ngFor="let attachment of criteria?.attachment">
                                <div class="attachment-row">
                                            <span class="name">
                                              <i class="icon-pdf"></i>
                                              <a [href]="attachment.link" target="_blank" (click)="onClickAttachment(attachment)">{{attachment.title}}</a>
                                            </span>
                                </div>
                              </div>

                              <div class="col-12 mt-2">
                                <div class="d-flex ml-2 my-2 toogle-button-container justify-content-center">
                                  <mat-button-toggle-group name="fontStyle" aria-label="Font Style" *ngIf="!criteria.answered">
                                    <mat-button-toggle [disabled]="response_stamps?.completed" [checked]="getResponseType().isConsensusAgree(criteria.answer?.consent_value)" (change)="onClickAgree(criteria)" class="green">{{'components.responses.consensus.agree' | translate}}</mat-button-toggle>
                                    <mat-button-toggle [disabled]="response_stamps?.completed" [checked]="getResponseType().isConsensusDisagree(criteria.answer?.consent_value)" (change)="onClickDisagree(criteria)" class="red">{{'components.responses.consensus.disagree' | translate}}</mat-button-toggle>
                                    <mat-button-toggle *ngIf="evaluation_session.settings?.can_abstain" [disabled]="response_stamps?.completed" [checked]="getResponseType().isConsensusAbstained(criteria.answer?.consent_value)" (change)="onClickAbstained(criteria)" class="yellow">{{'components.responses.consensus.abstained' | translate}}</mat-button-toggle>
                                  </mat-button-toggle-group>
                                  <mat-button-toggle-group name="fontStyle" aria-label="Font Style" *ngIf="criteria.answered">
                                    <mat-button-toggle disabled [checked]="criteria.response.consent_value == 'AGREE'" class="green">{{'components.responses.consensus.agree' | translate}}</mat-button-toggle>
                                    <mat-button-toggle disabled [checked]="criteria.response.consent_value == 'DISAGREE'" class="red">{{'components.responses.consensus.disagree' | translate}}</mat-button-toggle>
                                    <mat-button-toggle *ngIf="evaluation_session.settings?.can_abstain" disabled [checked]="criteria.response.consent_value == 'ABSTAINED'" class="yellow">{{'components.responses.consensus.abstained' | translate}}</mat-button-toggle>
                                  </mat-button-toggle-group>
                                </div>

                              </div>

                              <!-- <div class="col-12 mt-5" *ngIf="criteria?.answer?.feedback != undefined"> -->
                              <div class="col-12 mt-5">
                                <mat-form-field class="full-width" appearance="fill">
                                  <mat-label class="label-box font-weight-bold">
                                    {{'generic.feedback' | translate}}
                                    <!-- <i class="icon-cestino grey-color abs-tr" (click)="removeFeedback(proposal_index, criteria_index)" ></i> -->
                                  </mat-label>
                                  <textarea [disabled]="response_stamps?.completed" *ngIf="!criteria.answered" class="description my-2" matInput required rows="3" [(ngModel)]="criteria.answer.feedback"></textarea>
                                  <textarea disabled *ngIf="criteria.answered" class="description my-2" matInput required rows="3" [value]="criteria.response.feedback"></textarea>
                                </mat-form-field>
                              </div>
                              <div class="col-12 links-container">
                                <!-- <button *ngIf="criteria?.answer?.feedback == undefined" mat-button color="primary" (click)="addFeedback(proposal_index, criteria_index)">
                                    <i class="icon-Add mr-2"></i>
                                    {{'components.responses.add-feedback' | translate}}
                                </button> -->

                                <div *ngIf="!criteria.answered && !response_stamps?.completed">
                                  <button mat-button color="primary" [matMenuTriggerFor]="menuAttachment">
                                    <i class="icon-upload mr-2"></i>
                                    {{'components.responses.add-evidences' | translate}}
                                  </button>
                                  <mat-menu #menuAttachment="matMenu">
                                    <button mat-menu-item (click)="onClickAddNewEvidence(proposal_index, criteria_index, 'PUBMED')">
                                      <mat-icon aria-hidden="false">file_upload</mat-icon> Pubmed
                                    </button>
                                    <button mat-menu-item (click)="onClickAddNewEvidence(proposal_index, criteria_index, 'FILE')">
                                      <mat-icon aria-hidden="false">file_upload</mat-icon> File
                                    </button>
                                  </mat-menu>
                                </div>
                              </div>
                              <div class="col-12" *ngIf="criteria?.attachment_expert?.length > 0">
                                <mat-label class="label-box font-weight-bold">
                                  {{'components.responses.evidences-loaded-by-expert' | translate}}
                                </mat-label>
                              </div>
                              <div class="col-12" *ngFor="let attachment_expert of criteria?.attachment_expert; let attachIndex = index">
                                <div class="attachment-row">
                                          <span class="name">
                                            <i class="icon-pdf"></i>
                                            <a [href]="attachment_expert.link" target="_blank">{{attachment_expert.title}}</a>
                                          </span>
                                  <span class="size float-right" *ngIf="!decision_table?.closing_date && evaluation_session?.collecting && !response_stamps?.completed">
                                            {{'generic.buttons.delete' | translate}}
                                    <i class="icon-cestino main-color" (click)="onClickRemoveEvidence(attachIndex, criteria)"></i>
                                        </span>
                                </div>
                              </div>
                            </div>
                            <div class="row border-bottom-grey pt-0 pb-4 my-3" *ngIf="criteria.answer && !isAbstained(criteria)">
                              <small class="col-12">{{'components.responses.ask-confidence' | translate}}</small>

                              <mat-slider
                                [disabled]="response_stamps?.completed"
                                *ngIf="!criteria.answered"
                                class="w-100 mt-4 cdk-focused {{ organization?.customs?.profile === 'ipsen' ? 'custom-slider' : ''}}"
                                min="0" max="100"
                                step="1"
                                [(ngModel)]="criteria.answer.confidence"
                                [thumbLabel]="true"
                                color="primary"
                                (click)="setCriteriaAnswerConfidence(criteria)">
                              </mat-slider>
                              <mat-slider *ngIf="criteria.answered" disabled class="w-100 mt-2 cdk-focused" min="0" max="100" step="1" [value]="criteria.response.confidence" [thumbLabel]="true" color="primary"></mat-slider>
                              <div class="d-flex justify-content-between w-100 mb-2 px-2">
                                <small class="float-left">{{'components.responses.absolutely-not-sure' | translate}}</small>
                                <small class="float-right">{{'components.responses.absolutely-sure' | translate}}</small>
                              </div>
                            </div>

                          </mat-expansion-panel>
                        </mat-accordion>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
                <div class="col-12 links-container mt-5" *ngIf="evaluation_session?.collecting && !decision_table?.closing_date && !response_stamps?.completed && evaluation_session.settings.provide_new_proposal_enabled">
                   <button mat-button color="primary" (click)="suggestProposalOnClick()">
                    <i class="icon-Add mr-2"></i>
                    {{'components.responses.ask-add-new-proposal' | translate}}
                  </button>
                </div>
              </div>
          </mat-expansion-panel>
      </mat-accordion>
  </div>
</div>


<div class="container-fluid mt-4">
  <!-- <div class="row justify-content-center w-100 mt-0 mb-3 mx-auto">
      <button class="px-5" mat-flat-button color="primary">Visualizza riepilogo</button>
  </div> -->
  <div class="row justify-content-center w-100 mt-0 mb-3 mx-auto" *ngIf="!response_stamps?.completed">
      <button class="px-5" mat-flat-button color="primary" [disabled]="!evaluation_session?.collecting || loading" (click)="verifySubmit()" >{{'generic.buttons.save-and-send' | translate}}</button>
  </div>
  <div class="row justify-content-center w-100 mt-0 mb-3 mx-auto">
    <div *ngIf="response_stamps">
      <span *ngIf="response_stamps?.completed"> {{'components.responses.info-sent-date' | translate}} {{response_stamps?.last_save| formatdate | date:'short'}}</span>
      <span *ngIf="!response_stamps?.completed"> {{'components.responses.info-draft-date' | translate}} {{response_stamps?.last_save| formatdate | date:'short'}}</span>
    </div>
  </div>
</div>


<!-- Buttons -->
<div class="buttons-container container py-4" *ngIf="!response_stamps?.completed">
  <button class="button-primary float-left" mat-button [disabled]="!evaluation_session?.collecting || loading" [routerLink]="['../summary']">
      <i class="icon-Backward-arrow-small"></i>
      {{'generic.buttons.go-back' | translate}}
  </button>
  <button class="button-primary float-right" mat-button [disabled]="!evaluation_session?.collecting || loading" (click)="verifySubmit(true)">
      <i class="icon-Forward-arrow-small"></i>
      {{'generic.buttons.save-draft' | translate}}
  </button>
</div>





<!-- Parte dedicata all'aggiunta degli allegati -->

<!-- <div class="row pt-4 pb-0 my-3">
  <small class="col-12">"{{'generic.description' | translate}}"</small>
  <div class="col-12">Rispetto dei tempi</div>
  <mat-slider cdk-focused class="w-100 mt-2 tricolor" min="0" max="10" step="1" value="3" color="primary" [thumbLabel]="true"></mat-slider>
  <div class="d-flex justify-content-between w-100 mb-2 px-2">
      <small class="float-left">Poco importante</small>
      <small class="float-right">Molto importante</small>
  </div>

  <div class="col-12 mt-5">
      <mat-label class="label-box font-weight-bold">
          Feedback
          <i class="icon-cestino grey-color abs-tr"></i>
      </mat-label>
      <p class="my-2">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur.
      </p>
  </div>

  <div class="col-12 mt-3 ">
      <mat-label class="label-box font-weight-bold">
          Evidenze caricate
      </mat-label>

      <div class="attachment-row">
          <span class="name">
              <i class="icon-pdf"></i>
              Product_3.jpg
          </span>
          <span class="size float-right">
              12.3 MB
              <i class="icon-Cancel main-color"></i>
          </span>
      </div>

      <div class="attachment-row">
          <span class="name">
              <i class="icon-pdf"></i>
              Product_3.jpg
          </span>
          <span class="size float-right">
              12.3 MB
              <i class="icon-Cancel main-color"></i>
          </span>
      </div>

      <div class="attachment-row">
          <span class="name">
              <i class="icon-pdf"></i>
              Product_3.jpg
          </span>
          <span class="size float-right">
              12.3 MB
              <i class="icon-Cancel main-color"></i>
          </span>
      </div>

      <div class="col-12 links-container">
          <button mat-button color="primary">
              <i class="icon-upload mr-2"></i>
              {{'components.decision-table.new-decision-table.compile-proposal.load-evidences' | translate}}
          </button>
      </div>
  </div>

</div> -->
