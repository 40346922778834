import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {UserService} from "../services/user.service";
import {lowerCase} from "lodash";

@Directive({
  selector: '[appShowIfOrganizationHasFeature]'
})
export class ShowIfOrganizationHasFeatureDirective {
  private featureName: string = null;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userService: UserService,
  ) {
  }

  @Input()
  set appShowIfOrganizationHasFeature(name: string) {
    this.featureName = name
    this.updateView();
  }

  private updateView() {
    const customs = JSON.parse(JSON.stringify(this.userService?.currentOrganization?.customs));
    if (!customs && !customs?.features) {
      this.viewContainer.clear();
    }

    if (customs?.features[this.featureName]) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
