import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router, ActivatedRoute} from '@angular/router';
import {iif, Subject, Subscription} from 'rxjs';
import {ModalNewProposalComponent} from 'src/app/modals/modal-new-proposal/modal-new-proposal.component';
import {ProcessRolesService} from 'src/app/services/config/process-roles.service';
import {ToastService} from 'src/app/services/general/toast.service';
import {HeaderTitleService} from 'src/app/services/general/header-title.service';
import {UserService} from 'src/app/services/user.service';
import {DecisionService} from 'src/app/services/decision.service';
import {Scroll} from 'src/app/_helpers/scroll/scroll.component';
import {SuggestionService} from 'src/app/services/config/suggestion.service';
import {AlertService} from 'src/app/services/general/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {ModalAddContestComponent} from '../../../modals/modal-add-contest/modal-add-contest.component';
import {ModalShowContestComponent} from '../../../modals/modal-show-contest/modal-show-contest.component';
import {OrganizationService} from 'src/app/services/organization.service';
import {
  ModalImportOrganizationChartsComponent
} from '../../../modals/modal-import-organization-charts/modal-import-organization-charts.component';

import {faEnvelope, faBullhorn, faTimes} from '@fortawesome/free-solid-svg-icons';
import {HelperService} from 'src/app/services/general/helper.service';
import {ModalEmailResponsesComponent} from 'src/app/modals/modal-email-responses/modal-email-responses.component';
import {ModalEditWeightsComponent} from '../../../modals/modal-edit-weights/modal-edit-weights.component';
import swal from 'sweetalert2';
import {AnalysisMethodsService} from '../../../services/config/analysis-methods.service';
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import {
  ModalAddInvitationPageComponent
} from '../../../modals/modal-add-invitation-page/modal-add-invitation-page.component';
import {add} from 'lodash';
import {JoinRequestService} from '../../../services/join-requests.service';
import {
  ModalSelectProcessRoleComponent
} from '../../../modals/modal-select-process-role/modal-select-process-role.component';
import {ConfigService} from '../../../services/config.service';
import {faDownload} from "@fortawesome/free-solid-svg-icons/faDownload";

@Component({
  selector: 'app-show-decision-table',
  templateUrl: './show-decision-table.component.html',
  styleUrls: ['./show-decision-table.component.scss'],
})
export class ShowDecisionTableComponent implements OnInit {


  constructor(
    private userService: UserService,
    private suggestionService: SuggestionService,
    public dialog: MatDialog,
    private activeRoute: ActivatedRoute,
    private headerService: HeaderTitleService,
    private toast: ToastService,
    private processRolesService: ProcessRolesService,
    private decisionService: DecisionService,
    private alert: AlertService,
    private translateService: TranslateService,
    private router: Router,
    private organizationService: OrganizationService,
    private analisysService: AnalysisMethodsService,
    private joinRequestsService: JoinRequestService,
    private configService: ConfigService
  ) {
  }

  public get isInformed() {
    let tmp = false;
    this.currentTeamMemberRolesOnActiveSession.forEach(team_member => {
      if (team_member.process_role === 'INFORMED') {
        tmp = true;
      }
    });
    return tmp;
  }

  public get isConsulted() {
    let tmp = null;
    this.currentTeamMemberRolesOnActiveSession.forEach(team_member => {
      if (team_member.process_role === 'CONSULTED') {
        tmp = team_member;
      }
    });
    return tmp;
  }

  //  indica quante proposte sono state valutate
  get numOfProposalEvaluated(): number {
    if (this.proposals && this.proposals.length > 0) {
      return this.proposals.filter((x) => x.evaluation_session != null).length;
    }

    return 0;
  }

  get sessionsEvalutated(): boolean {
    if (this.decision_table?.evaluation_sessions) {
      for (const session of this.decision_table.evaluation_sessions) {
        if (session.evaluation_end == null && session.collecting == true) {
          return false;
        }
      }
    }
    return true;
  }


  //  indica se al momento c'è una sessione di valutazione in corso
  get evaluationSessionActive(): boolean {
    if (this.decision_table?.evaluation_sessions) {
      for (const session of this.decision_table.evaluation_sessions) {
        if (session.collecting == true) {
          return true;
        }
      }
    }
    return false;
  }

  /** Valida il team inserito */
  get teamMembersValid(): boolean {
    for (const team_member of this.modelStartNewEvaluation.team_members) {
      if (!team_member.name || !team_member.email || !team_member.process_role) {
        return false;
      }
    }

    return true;
  }

  /** Valida il team inserito evitando i duplicati */
  get teamMemberDuplicates(): boolean {
    for (const team_member of this.modelStartNewEvaluation.team_members) {
      const sameMember = this.modelStartNewEvaluation.team_members.filter(
        (x) => x.email == team_member.email
      );

      let counter = 0;
      for (const memberCheck of sameMember) {
        if (memberCheck.process_role == team_member.process_role && counter < 2) {
          counter++;
        }
      }
      if (counter >= 2) {
        return false;
      }
    }

    return true;
  }

  @ViewChild('contestsPaginator') set contentContestPaginator(content: MatPaginator) {
    if (content) {
      this.contestsPaginator = content;
    }
  }

  @ViewChild('joinRequestsPaginator') set contentJoinRequestsPaginator(content: MatPaginator) {
    if (content) {
      this.joinRequestsPaginator = content;
    }
  }

  @ViewChild('invitationPagesPaginator') set contentInvitationPagesPaginator(content: MatPaginator) {
    if (content) {
      this.invitationPagesPaginator = content;
    }
  }

  @ViewChild('currentTeamPaginator') set contentTeamPaginator(content: MatPaginator) {
    if (content) {
      this.teamPaginator = content;
    }
  }

  get isAlexion() {
    return this.userService?.currentOrganization?.customs?.profile === 'alexion';
  }

  get team_member_in_evaluation_session() {
    return this.currentTeamMembers.find((team_member) => team_member.email === this.singleTeamMemberToAdd.email && team_member.process_role === this.singleTeamMemberToAdd.process_role);
  }

  get singleTeamMemberIsValid() {
    return !this.team_member_in_evaluation_session && this.singleTeamMemberToAdd.email.length > 0 && this.singleTeamMemberToAdd.process_role.length > 0 && this.singleTeamMemberToAdd.name.length > 0;
  }

  get lastEvaluationSession() {
    if (this.decision_table?.evaluation_sessions?.length > 0) {
      return this.decision_table?.evaluation_sessions[this.decision_table?.evaluation_sessions?.length - 1];
    } else {
      return null;
    }
  }

  get update_weights_is_allow() {
    return !this.decision_table?.framework?.weights_is_mandatories;
  }

  get isConsensus() {
    return this.analisysService.isConsensusByType(this.decision_table?.framework?.analysis_type);
  }

  @ViewChild('timelineTemplate', {static: true}) timelineTemplate: TemplateRef<any>;
  @ViewChild('contestsTemplate', {static: true}) contestsTemplate: TemplateRef<any>;
  @ViewChild('invitePages', {static: true}) invitePages: TemplateRef<any>;
  @ViewChild('currentEvaluationSessionTemplate', {static: true}) currentEvaluationSessionTemplate: TemplateRef<any>;
  @ViewChild('proposalsTemplate', {static: true}) proposalsTemplate: TemplateRef<any>;
  @ViewChild('detailsTemplate', {static: true}) detailsTemplate: TemplateRef<any>;

  //  icons
  faEnvelope = faEnvelope;
  faBullhorn = faBullhorn;
  faTimes = faTimes;

  @ViewChild('dialogTemplate') dialogTemplate: TemplateRef<any>;
  contestDisplayedColumns: string[] = ['title', 'created_at', 'closing_date', 'actions'];
  contestDataSource: MatTableDataSource<unknown>;

  invitationPagesDisplayedColumns: string[] = ['title', 'created_at', 'status', 'actions'];
  invitationPagesDataSource: MatTableDataSource<unknown>;
  public invitationPagesPaginator: MatPaginator;

  joinRequestsDisplayedColumns: string[] = ['name', 'email', 'number_phone', 'status', 'actions'];
  joinRequestsDataSource: MatTableDataSource<unknown>;
  public joinRequestsPaginator: MatPaginator;

  teamMemberDisplayedColumns: string[] = ['name', 'email', 'role', 'response_status', 'email_status'];
  currentTeamMembersDataSource: MatTableDataSource<unknown>;

  private contestsPaginator: MatPaginator;

  private teamPaginator: MatPaginator;

  private _activeRouteSubscription: Subscription;

  loading = false;

  id: any;
  decision_table: any = {};
  emails: any = [];

  professionalRoles: any = [];
  process_roles: any = [];
  evaluation_sessions_by_row = 5;
  private evaluation_sessions_array: any[] = [];
  evaluation_sessions_row_1 = [];
  evaluation_sessions_row_2 = [];

  currentTeamMemberRolesOnActiveSession = [];
  evaluationSessionActiveObject: any;
  currentEvaluationSession = 0;
  suggestions: any = ([] = []);
  proposals: any = ([] = []);
  organizationCharts = [];

  startNewEvaluationChoice = false;
  startNewEvaluation = false;

  locked = false;

  currentTeamMembers = [];
  currentOrganization: any;

  //  model per l'avvio di una nuova sessione di valutazione
  modelStartNewEvaluation: any;
  modelStartNewEvaluation_empty: any = {
    evaluation_start: null,
    evaluation_end: null,
    settings: {
      provide_new_proposal_enabled: false,
      weights_provided_users: false,
      review_enabled: false,
      degree_of_confidence_enabled: false,
      provide_feedback_enabled: false,
      anonymous_survey_enabled: false,
      can_abstain: false,
      template_email_selected: null
    },
    proposal_selected: [],
    team_members: [],
    closing_date: null,
    create_session: true,
  };

  singleTeamMemberToAdd = {
    name: '',
    email: '',
    process_role: ''
  };
  singleTeamMemberToAddActive = false;

  //  templare per le email
  templateEmail: any = [];

  sections = null;

  protected readonly faPlusCircle = faPlusCircle;

  protected readonly add = add;

  //  blocco tutte le date al di sotto di quella corrente
  startEvaluationFilter = (d: Date | null): boolean => {
    const date = d || new Date();
    const lockedDate = new Date();
    lockedDate.setDate(lockedDate.getDate() - 1);
    return date > lockedDate;
  }

  //  blocco tutte le date al di sotto di quella attualmente nell'avvio della valutazione
  endEvaluationFilter = (d: Date | null): boolean => {
    const date = d || new Date();
    const lockedDate = this.modelStartNewEvaluation.evaluation_start;
    lockedDate.setDate(lockedDate.getDate());
    return date > lockedDate;
  }

  ngOnInit(): void {
    this._activeRouteSubscription = this.activeRoute.params.subscribe(
      (routeParams) => {
        this.id = routeParams.id_decision;
        if (this.id) {
          this.getDecisionTable();
        } else {
          this.init();
        }
      }
    );
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    if (this._activeRouteSubscription) {
      this._activeRouteSubscription.unsubscribe();
    }
  }

  /** Recupera le info del tavolo decisionale */
  getDecisionTable() {
    this.loading = true;

    //  setto un model vuoto
    this.modelStartNewEvaluation = JSON.parse(
      JSON.stringify(this.modelStartNewEvaluation_empty)
    );

    this.decisionService.get(
      this.id,
      (result: any) => {
        this.decision_table = JSON.parse(JSON.stringify(result));

        //  recupero i template email per l'oeganizzazione che ha creato il tavolo
        this.organizationService.allOrganizationTemplatesEmail(this.decision_table.organization_id, (data) => {
          this.templateEmail = data;
        });

        this.evaluation_sessions_array = JSON.parse(
          JSON.stringify(this.decision_table.evaluation_sessions)
        );

        // this.suggestions = this.decision_table.suggestions;
        this.proposals = this.decision_table.proposals;
        this.modelStartNewEvaluation.closing_date =
          this.decision_table.closing_date;
        if (!this.modelStartNewEvaluation.closing_date) {
          this.modelStartNewEvaluation.closing_date = new Date().toISOString();
        }

        //  recupero le informazioni per far partire una nuova valutazione
        this.currentEvaluationSession =
          this.decision_table.evaluation_sessions[
          this.decision_table.evaluation_sessions.length - 1
            ].evaluation_session;
        this.modelStartNewEvaluation.team_members = JSON.parse(
          JSON.stringify(
            this.decision_table.evaluation_sessions[
            this.currentEvaluationSession - 1
              ].team_members
          )
        );
        this.currentTeamMembers = JSON.parse(JSON.stringify(this.modelStartNewEvaluation.team_members));

        this.init();
      },
      () => (this.loading = false)
    );
  }

  initSections() {
    let sections = this.sections = [
      {
        section: 'DETAILS',
        title: 'generic.details',
        template: this.detailsTemplate,
      },
      {
        section: 'TIMELINE',
        title: 'components.decision-table.show-decision-table.project-timeline',
        template: this.timelineTemplate,
        expanded: true
      },
      {
        section: 'CONTESTS',
        title: 'components.decision-table.show-decision-table.contests-title',
        template: this.contestsTemplate,
      },
      {
        section: 'INVITE_PAGES',
        title: 'components.decision-table.show-decision-table.invite-pages-title',
        template: this.invitePages,
      },
      {
        section: 'CURRENT_EVALUATION_SESSION',
        title: 'components.decision-table.show-decision-table.last-evaluation-session-info',
        template: this.currentEvaluationSessionTemplate,
      },
      {
        section: 'PROPOSALS',
        title: 'components.decision-table.show-decision-table.evaluation-session-management',
        template: this.proposalsTemplate,
        expanded: true
      },
    ];

    if (this.userService.currentOrganization?.customs?.profile === 'alexion') {
      sections = sections.filter((section) => !['CONTESTS', 'CURRENT_EVALUATION_SESSION', 'INVITE_PAGES'].includes(section.section));
    }

    if (!this.evaluationSessionActiveObject) {
      sections = sections.filter((section) => section.section !== 'CURRENT_EVALUATION_SESSION');
    }

    this.sections = sections;
  }

  init() {
    this.startNewEvaluationChoice = false;
    this.startNewEvaluation = false;

    //  setta il titolo della pagina
    this.headerService.setTitle(
      this.decision_table.decision_name +
      ' - ' +
      this.userService.getOrganizationByID(
        this.decision_table.organization_id
      )?.name
    );

    this.currentOrganization = this.userService.currentOrganization;

    this.organizationService.allOrganizationCharts(this.userService.currentOrganization.id, (result) => {
      this.organizationCharts = result;
    });

    //  inizializzo i ruoli nei processi decisionali e i ruoli professionali dell'azienda in questione
    this.process_roles = this.processRolesService.getAllProcessRoles();
    this.professionalRoles =
      this.userService.getAllProfessionalRolesForCurrentOrganization();

    //  preparo la timeline delle sessioni di valutazione
    this.evaluation_sessions_row_1 = this.evaluation_sessions_array.splice(
      0,
      this.evaluation_sessions_by_row
    );
    this.evaluation_sessions_row_2 = this.evaluation_sessions_array;

    //  controllo che ci siano dei suggerimenti nelle proposte che sono state completate
    this.prepareSuggestionForProposals();
    this.evaluationSessionActiveObject = this.getActiveEvaluationSession();

    this.isLocked();
    this.loading = false;

    this.initCurrentTeamMembersDataSource();
    this.initContestDataSource();
    this.initInvitationPagesDataSource();
    this.initJoinRequestsPagesDataSource();

    this.initSections();
  }

  public initCurrentTeamMembersDataSource() {
    this.currentTeamMembersDataSource = new MatTableDataSource(this.currentTeamMembers);
    setTimeout(() => {
      this.currentTeamMembersDataSource.paginator = this.teamPaginator;
    }, 100);
  }

  public initContestDataSource() {
    this.contestDataSource = new MatTableDataSource(this.decision_table.contests.reverse());
    setTimeout(() => {
      this.contestDataSource.paginator = this.contestsPaginator;
    }, 100);
  }

  public initJoinRequestsPagesDataSource() {
    this.joinRequestsDataSource = new MatTableDataSource(this.decision_table.join_requests.reverse());
    setTimeout(() => {
      this.joinRequestsDataSource.paginator = this.joinRequestsPaginator;
    }, 100);
  }

  public initInvitationPagesDataSource() {
    this.invitationPagesDataSource = new MatTableDataSource(this.decision_table.invitation_pages.reverse());
    setTimeout(() => {
      this.invitationPagesDataSource.paginator = this.invitationPagesPaginator;
    }, 100);
  }

  updateJoinRequestStatus(requestId: number, newStatus: 'approved' | 'refused') {
    const model: any = {};

    const successCallback = (response: any) => {
      const request = this.decision_table?.join_requests?.find(req => req.id === requestId);
      if (request) {
        request.status = newStatus;
      }
      this.loading = false;
      if (newStatus === 'approved') {
        this.toast.success(
          this.translateService.instant('generic.success'),
          this.translateService.instant(
            'components.decision-table.show-decision-table.user-added-to-team-successfully'
          )
        );
        this.getDecisionTable();
      } else {
        this.initJoinRequestsPagesDataSource();
      }
    };

    const errorCallback = (error: any) => {
      this.loading = false;
    };

    const handleApprove = (processRole: string) => {
      if (processRole) {
        model.process_role = processRole; // Aggiungi il process_role selezionato al modello
        this.joinRequestsService.approveJoinRequest(requestId.toString(), model, successCallback, errorCallback);
      }
    };

    this.loading = true;

    if (newStatus === 'approved') {
      const dialogRef = this.dialog.open(ModalSelectProcessRoleComponent, {
        panelClass: 'medium-dialog',
      });

      dialogRef.afterClosed().subscribe((processRole) => {
        console.log(processRole);
        if (processRole) {
          handleApprove(processRole);
        } else {
          this.loading = false; // L'utente ha annullato
        }
      });
    } else if (newStatus === 'refused') {
      this.joinRequestsService.refuseJoinRequest(requestId.toString(), model, successCallback, errorCallback);
    }
  }

  updateInvitationPageStatus(id, status = 'active') {
    this.loading = true;
    this.decisionService.updateStatusInvitationPage(id, {status}, () => {
      if (status === 'active') {
        this.decision_table.decision_table_invitation_page_id = id;
      } else {
        this.decision_table.decision_table_invitation_page_id = null;
      }
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  closeContest(contest) {
    this.loading = true;
    this.decisionService.closeContest(this.decision_table.id, contest.id, () => {
      contest.closing_date = new Date();
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }


  public getActiveEvaluationSession() {
    let evalSession: any = null;
    this.decision_table.evaluation_sessions?.forEach((evaluation_session) => {
      if (evaluation_session?.collecting) {
        evalSession = evaluation_session;
      }
    });
    return evalSession;
  }

  public isLocked() {
    if (this.evaluationSessionActiveObject != null) {
      this.evaluationSessionActiveObject.team_members.forEach(team_member => {
        if (team_member.user_id === this.userService.userInfo.id) {
          this.currentTeamMemberRolesOnActiveSession.push(team_member);
        }
      });
    }
  }

  /**
   * Restituisce la classe che colora il box
   */
  getClassSuggestion(proposal) {
    switch (proposal.result) {
      case 'DISCARD':
        return 'bg-red';
      case 'GOOD':
        return 'bg-green';
      case 'CHANGE':
        return 'bg-orange';
      case 'EVALUATE':
        return '';
      case 'INEVALUATION':
        return '';
    }
  }

  getIconSuggestion(proposal) {
    switch (proposal.result) {
      case 'DISCARD':
        return 'icon-Close';
      case 'GOOD':
        return 'icon-Yes';
      case 'CHANGE':
        return 'icon-punto_esclamativo';
      case 'EVALUATE':
        return '';
      case 'INEVALUATION':
        return '';
    }
  }

  getTextForSuggestion(proposal) {
    switch (proposal.result) {
      case 'DISCARD':
        return 'proposta da scartare';
      case 'GOOD':
        return 'accetta la proposta';
      case 'CHANGE':
        return 'revisiona la proposta';
      case 'EVALUATE':
        return 'Non ancora valutata';
      case 'INEVALUATION':
        return 'In valutazione';
      default:
        return 'Non ancora valutata';
    }
  }

  /** Evento di pressione sui pulsanti di scelta se cominciare o meno una nuova sessione di valutazione */
  onClickStartNewSession(clickedValue) {
    this.startNewEvaluationChoice = clickedValue;

    if (this.startNewEvaluationChoice == false) {
      this.startNewEvaluation = false;
      this.modelStartNewEvaluation = {
        settings: [],
        team_members: [],
        closing_date: new Date().toISOString(),
        proposal_selected: [],
      };
    }
  }

  /** Evento di pressione sul tasto di chiusura del tavolo decisionale */
  onClickCloseDecisionTable() {
    this.alert.showQuestion(
      this.translateService.instant('generic.warning'),
      this.translateService.instant(
        'components.decision-table.show-decision-table.alert-close-table'
      ),
      (ok) => {
        this.decisionService.closeCurrentDecisionTable(this.id, () => {
          //  recupero nuovamente le informazioni del tavolo decisionale
          this.getDecisionTable();
          Scroll.toTop();
        });
        return;
      }
    );
  }

  /** Evento di pressione sul tasto di riapertura del tavolo decisionale */
  onClickReopenDecisionTable() {
    this.loading = true;
    this.decisionService.reopenCurrentDecisionTable(this.id, () => {
      this.loading = false;
      this.toast.success(
        this.translateService.instant('generic.table'),
        this.translateService.instant(
          'components.decision-table.show-decision-table.alert-project-reopened'
        )
      );
      this.decision_table.closing_date = null;
      this.getDecisionTable();
      Scroll.toTop();
    }, () => {
      this.loading = false;
    });
  }

  /** Evento di pressione sul tasto di chiusura della sessione di valutazione */
  onClickCloseEvaluationSession() {
    this.alert.showQuestion(
      this.translateService.instant('generic.warning'),
      this.translateService.instant(
        'components.decision-table.show-decision-table.alert-close-session'
      ),
      (ok) => {
        if (this.evaluationSessionActive) {
          this.decisionService.closeCurrentDecisionSession(this.id, () => {
            //  recupero nuovamente le informazioni del tavolo decisionale
            this.getDecisionTable();
            Scroll.toTop();
          });
          return;
        }
        this.toast.alert(
          this.translateService.instant('generic.evaluation-session'),
          this.translateService.instant(
            'components.decision-table.show-decision-table.alert-session-end'
          )
        );
      }
    );
  }

  /** Conferma di avvio di una nuova sessione di valutazione */
  onClickConfirmStartNewSession() {
    const checkAndStart = () => {
      if (!this?.modelStartNewEvaluation?.proposal_selected?.length) {
        this.toast.error(
          this.translateService.instant('generic.warning'),
          this.translateService.instant(
            'components.decision-table.show-decision-table.error-session-start-first'
          )
        );
        return;
      }
      if (this.startNewEvaluationChoice == true) {
        //  controllo che non ci siano sessioni attive
        if (this.evaluationSessionActive) {
          this.toast.error(
            this.translateService.instant('generic.warning'),
            this.translateService.instant(
              'components.decision-table.show-decision-table.error-session-start-second'
            )
          );
          return;
        }

        this.modelStartNewEvaluation.evaluation_start = new Date();

        if (this.isAlexion) {
          this.modelStartNewEvaluation.settings.weights_provided_users = false;
          this.onClickSaveAndStartSession();
        } else {
          this.startNewEvaluation = true;
        }
      }
    };

    if (this.userService.currentOrganization.customs?.profile === 'alexion') {
      if (
        !this?.modelStartNewEvaluation?.proposal_selected?.length &&
        !this.evaluationSessionActive
      ) {
        this.loading = true;
        this.decisionService.addNewRevision(
          this.decision_table.id,
          this.proposals[this.proposals.length - 1],
          (result) => {
            this.loading = false;
            this.proposals.push(result?.proposal);
            this.proposalCheckedOnChange(this.proposals[this.proposals.length - 1]);
            checkAndStart();
          },
          () => (this.loading = false)
        );
      } else {
        checkAndStart();
      }
    } else {
      checkAndStart();
    }
  }

  /** Evento di cambio della data di fine sessione */
  startEvaluationDateOnChange() {
    this.modelStartNewEvaluation.evaluation_end = null;
    this.toast.alert(
      this.translateService.instant('generic.warning'),
      this.translateService.instant(
        'components.decision-table.show-decision-table.alert-data-end-session'
      )
    );
  }

  /** Valida e lancia la nuova sessione */
  onClickSaveAndStartSession() {
    // Controlla se c'è almeno un membro consulted nel team
    if (!this.teamMembersContainsConsulted()) {
      this.toast.error(
        'Team',
        this.translateService.instant(
          'components.decision-table.show-decision-table.error-validation-team-consulted'
        )
      );
      return;
    }

    //  valida i membri del team
    if (!this.teamMembersValid) {
      this.toast.error(
        'Team',
        this.translateService.instant(
          'components.decision-table.show-decision-table.error-validation-team'
        )
      );
      return;
    }
    //  cerco duplicati nel team
    if (!this.teamMemberDuplicates) {
      this.toast.error(
        'Team',
        this.translateService.instant(
          'components.decision-table.show-decision-table.error-duplicate-team'
        )
      );
      return;
    }

    if (!this.decision_table?.weights_is_setted && !this.modelStartNewEvaluation?.settings.weights_provided_users && !this.isConsensus) {
      swal.fire({
        title: this.translateService.instant('components.decision-table.show-decision-table.alert-weights-not-setted-title'),
        text: this.translateService.instant('components.decision-table.show-decision-table.alert-weights-not-setted-text'),
        icon: 'error',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: this.translateService.instant('components.decision-table.show-decision-table.alert-weights-allow-consulted'),
        denyButtonText: this.translateService.instant('components.decision-table.show-decision-table.alert-weights-set-now'),
        cancelButtonText: this.translateService.instant('generic.cancel'),
        confirmButtonColor: '#7B55C8',
        denyButtonColor: '#7B55C8',
      }).then((result) => {
        if (result?.isConfirmed) {
          this.modelStartNewEvaluation.settings.weights_provided_users = true;
        }
        if (result?.isDenied) {
          this.editSessionWeights();
        }
      });
      return;
    }

    //  se la sessione che sto avviando è stata precedentemente creata allora non devo crearla
    if (
      this.decision_table.evaluation_sessions[
      this.decision_table.evaluation_sessions.length - 1
        ].evaluation_start == null
    ) {
      this.modelStartNewEvaluation.create_session = false;
    }

    this.loading = true;
    this.decisionService.startNewDecisionSession(
      this.id,
      this.modelStartNewEvaluation,
      (result: any) => {
        this.loading = false;
        this.toast.success(
          this.translateService.instant('generic.evaluation-session'),
          this.translateService.instant(
            'components.decision-table.show-decision-table.success-evaluation-session'
          )
        );
        this.startNewEvaluationChoice = false;
        this.startNewEvaluation = false;

        //  recupero nuovamente le informazioni del tavolo decisionale
        this.getDecisionTable();
        Scroll.toTop();
      },
      () => (this.loading = false)
    );
  }

  private teamMembersContainsConsulted(): boolean {
    for (let i = 0; i < this.modelStartNewEvaluation.team_members.length; i++) {
      const member = this.modelStartNewEvaluation.team_members[i];
      if (member.process_role === 'CONSULTED') {
        return true;
      }
    }
    return false;
  }

  /** Scarica i dati in formato Excel */
  onClickDownloadExcelData() {
    this.decisionService.donwloadData(this.decision_table.id);
  }

  /** Apre una dialogue per inserire una nuova proposal */
  newProposal() {
    const dialogRef = this.dialog.open(ModalNewProposalComponent, {
      panelClass: 'fullscreen-dialog',
      data: {
        newProposal: true,
        decision_table: this.decision_table,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      // console.log(`Dialog result: ${result}`);
      //  se arrivano risultati dal salvataggio devo aggiornare le informazioni sul tavolo devisionale
      if (result != null) {
        this.proposals.push(result.proposal);
      }
    });
  }

  editSessionWeights(edit_mode = true) {
    const dialogRef = this.dialog.open(ModalEditWeightsComponent, {
      panelClass: 'fullscreen-dialog',
      data: {
        decision_table: this.decision_table,
        edit_mode
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result != null) {
        this.decision_table.weights_is_setted = true;
      }
    });
  }

  /** Apre una dialogue per editare una proposal */
  editProposal(proposal: any, extra = null) {
    const dialogRef = this.dialog.open(ModalNewProposalComponent, {
      panelClass: 'fullscreen-dialog',
      data: {
        newProposal: false,
        decision_table: this.decision_table,
        proposal,
        current_organization: this.currentOrganization,
        extra,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== null && result !== undefined) {
        if (result?.is_deleted) {
          this.proposals = this.proposals.filter((x) => x.id !== result.proposal_id);
        } else {
          this.proposals.push(result.proposal);
        }
      }
    });
  }

  showContest(contest: any) {
    const dialogRef = this.dialog.open(ModalShowContestComponent, {
      panelClass: 'fullscreen-dialog',
      data: {
        contest,
        decision_table: this.decision_table,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.ngOnInit();
    });
  }

  addNewInvitationPage(invitation_page_id = null) {
    const dialogRef = this.dialog.open(ModalAddInvitationPageComponent, {
      panelClass: 'fullscreen-dialog',
      data: {
        decision_table_id: this.decision_table.id,
        invitation_page_id
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== null && result !== undefined) {
        if (result.isDeleted) {
          this.decision_table.invitation_pages = this.decision_table.invitation_pages.filter((x) => x.id !== invitation_page_id);
          if (this.decision_table.decision_table_invitation_page_id === invitation_page_id) {
            this.decision_table.decision_table_invitation_page_id = null;
          }
        } else {
          if (invitation_page_id) {
            const index = this.decision_table.invitation_pages.findIndex(page => page.id === result.id);
            if (index !== -1) {
              this.decision_table.invitation_pages[index] = result;
            }
          } else {
            this.decision_table.invitation_pages.unshift(result);
          }
          if (this.decision_table?.invitation_pages?.length === 1) {
            this.decision_table.decision_table_invitation_page_id = result.id;
          }
        }
        this.initInvitationPagesDataSource();
      }
    });
  }

  addNewContest() {
    const dialogRef = this.dialog.open(ModalAddContestComponent, {
      panelClass: 'medium-dialog',
      data: {
        decision_table_id: this.decision_table.id
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== null && result !== undefined) {
        result.contest.closing_date = null;
        this.decision_table.contests.push(result.contest);
        this.initContestDataSource();
      }
    });
  }

  contestIsCollecting() {
    return this.decision_table?.contests.some((contest) => contest.closing_date === null) || null;
  }

  copyLink(link) {
    navigator.clipboard.writeText(link);
    this.toast.success(this.translateService.instant('components.contests.contest-link'), this.translateService.instant('generic.link-copied'));
  }

  getContestProposalById(id) {
    return this.decision_table.contests.find((contest) => contest.id === id);
  }

  proposalCheckedOnChange(proposal: any) {
    const findProposalIndex =
      this.modelStartNewEvaluation.proposal_selected.findIndex(
        (x) => x == proposal.id
      );
    if (findProposalIndex < 0) {
      this.modelStartNewEvaluation.proposal_selected.push(proposal.id);
    } else {
      this.modelStartNewEvaluation.proposal_selected.splice(
        findProposalIndex,
        1
      );
    }
  }

  /** Genera automaticamente una nuova proposta */
  generateProposal() {
    if (this.id == 1) {
      this.toast.alert(
        this.translateService.instant('generic.warning'),
        this.translateService.instant(
          'components.decision-table.show-decision-table.alert-generate-proposal'
        )
      );
      return;
    }

    this.dialog.open(this.dialogTemplate, {minWidth: '600px'});
  }

  confirmGenerateProposal() {
    // this.dialog.closeAll();
    // this.demoService.setProposal(this.proposalGenerated);
    // this.init();
  }

  /** Calcola i risultati per ogni proposta */
  prepareSuggestionForProposals() {
    for (const proposal of this.proposals) {
      if (!proposal.evaluation_session) {
        proposal.result = 'EVALUATE';
        continue;
      }

      //  controllo che la sessione di valutazione sia terminata
      const session = this.decision_table.evaluation_sessions.find(
        (x) => x.evaluation_session == proposal.evaluation_session
      );
      if (session && session.collecting == true) {
        proposal.result = 'INEVALUATION';
        continue;
      }

      //  calolo i punteggi e li assegno
      let result_score = 0;
      let result_score_total = 0;

      proposal.criteria.forEach((criterio) => {
        if (criterio.results) {
          result_score_total++;
          if (
            this.suggestionService.isSuggestAccept(criterio.results.suggestion)
          ) {
            result_score++;
          }
        }
      });

      proposal.score = result_score;
      proposal.score_total = result_score_total;

      //  setto il suggerimento in base al punteggio
      if (proposal.score / proposal.score_total == 1) {
        proposal.result = 'GOOD';
      } else if (proposal.score / proposal.score_total <= 0.5) {
        proposal.result = 'DISCARD';
      } else {
        proposal.result = 'CHANGE';
      }
    }
  }

  /** Agiungi un membro del team */
  addNewTeamMember() {
    this.modelStartNewEvaluation.team_members.push({
      complete_process_enabled: true,
      review_enabled: true,
      provide_new_proposal_enabled: true,
      email: '',
    });
  }

  /** Rimuovi unb membro dal team */
  removeTeamMember(team_member) {
    const index = this.modelStartNewEvaluation?.team_members.indexOf(team_member);

    if (index !== -1) {
      if (team_member?.user_id === this.decision_table.createdby_user_id && this.processRolesService.isResponsible(team_member?.process_role)) {
        this.toast.alert(
          'Team',
          this.translateService.instant(
            'components.decision-table.show-decision-table.alert-remove-team-member'
          )
        );
      } else {
        this.modelStartNewEvaluation?.team_members.splice(index, 1);
      }
    }
  }

  public getProcessRolesService() {
    return this.processRolesService;
  }

  openChartsModal() {

    const dialogRef = this.dialog.open(ModalImportOrganizationChartsComponent, {
      panelClass: 'medium-dialog',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.modelStartNewEvaluation.team_members = this.modelStartNewEvaluation.team_members.filter((team_member) => {
          return team_member.name !== null && team_member.email !== null;
        });
        result.forEach((org_usr) => {
          const team_member = this.modelStartNewEvaluation.team_members.find((team_member) => team_member.email === org_usr?.user?.email);
          if (team_member) {
            team_member.imported_by_organization_chart_id = org_usr.imported_by_organization_chart_id;
          } else {
            this.modelStartNewEvaluation.team_members.push({
              name: org_usr?.user?.name,
              email: org_usr?.user?.email,
              organization_professional_role_id: null,
              imported_by_organization_chart_id: org_usr.imported_by_organization_chart_id,
              process_role: null,
              complete_process_enabled: true,
              review_enabled: true,
              provide_new_proposal_enabled: true
            });
          }
        });
      }
    });
  }

  addNewTeamMemberToCurrentSessionEvaluation() {
    this.loading = true;
    this.decisionService.addNewTeamMemberToSessionEvaluation(this.decision_table.id, this.singleTeamMemberToAdd, () => {
      this.loading = false;
      this.singleTeamMemberToAddActive = false;
      this.currentTeamMembers.push(this.singleTeamMemberToAdd);
      this.singleTeamMemberToAdd = {
        email: '',
        process_role: '',
        name: '',
      };
      this.initCurrentTeamMembersDataSource();
      this.toast.success(
        this.translateService.instant('generic.evaluation-session'),
        this.translateService.instant(
          'components.decision-table.new-decision-table.compile-proposal.team-member-added-successfully'
        )
      );
    }, () => {
      this.loading = false;
    });
  }

  toggleAddNewTeamMemberMode() {
    this.singleTeamMemberToAddActive = !this.singleTeamMemberToAddActive;

    //  pulisco il modello
    if (this.singleTeamMemberToAddActive == false) {
      this.singleTeamMemberToAdd = {
        email: '',
        process_role: '',
        name: '',
      };
    }
  }


  //  invia nuovamente una mail di invito / sollecito
  resendInvitation(row) {
    if (row.id) {
      this.alert.showQuestion(
        this.translateService.instant('generic.warning'),
        this.translateService.instant(
          'components.decision-table.show-decision-table.alert.resend-invitation'
        ),
        (ok) => {
          this.decisionService.resendInvitation(this.decision_table.id, row.id, () => {
            this.toast.success(
              this.translateService.instant(
                'components.decision-table.show-decision-table.alert.invitation-sent'
              )
            );
            this.getDecisionTable();
          });
          return;
        }
      );
    }
  }

  //  resituisce le informazioni di colore per mostrare lo stato di un invio email
  emailColorByTeamMember(row) {
    if (row?.email_responses && row?.email_responses.length > 0) {
      return HelperService.getColorByEmailEvent(row.email_responses[0].event);
    }
    return '';
  }

  //  resituisce le informazioni dell'ultimo stato di invio delle email
  emailStatusByTeamMember(row) {
    if (row?.email_responses && row?.email_responses.length > 0) {
      return row.email_responses[0].event;
    }
    return '';
  }

  //  mostra la modale con i dettagli delle email inviate
  showEmailResponses(row) {

    //  se non ci sono risposte lascio perdere la visualizzazione della modale
    if (row?.email_responses && row?.email_responses.length > 0) {
      const dialogRef = this.dialog.open(ModalEmailResponsesComponent, {
        panelClass: 'medium-dialog',
        data: {
          team_member_id: row.id,
          origin_id: this.decision_table.id,
          origin: 'DECISION_TABLE',
        }
      });
    }
  }

  get invitationPageUrl() {
    if (this.decision_table?.decision_table_invitation_page_id) {
      return this.configService.generics?.frontendBaseUrl + '/#/decision/' + this.decision_table.id + '/join-request';
    }
    return null;
  }

  extractTeamData() {
    this.loading = true;
    this.decisionService.extractTeamMembersCSV(this.getActiveEvaluationSession()?.id, (response) => {
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  protected readonly faDownload = faDownload;
}
