<div class="my-4" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<mat-card class="mt-4" [ngClass]="{'pb-0': survey?.responses?.length > 0}" *ngIf="survey">
  <mat-card-content>
    <div class="row justify-content-between mx-1">
      <ng-container *ngIf="survey?.responses?.length > 0">
        <span class="label-box font-weight-bold mt-auto mb-auto">
        {{ survey?.responses?.length }}
          <ng-container *ngIf="survey?.responses?.length == 1">{{ "generic.response" | translate }}</ng-container>
        <ng-container *ngIf="survey?.responses?.length > 1">{{ "generic.responses" | translate }}</ng-container>
        </span>
      </ng-container>
      <ng-container *ngIf="survey?.responses?.length === 0">
        <span class="mt-auto mb-auto">{{ 'pages.surveys.responses.no-responses' | translate }}</span>
      </ng-container>
      <mat-button-toggle-group [(ngModel)]="survey.status" (ngModelChange)="onChangeStatus()" style="height: 38px;
  align-items: center;">
        <mat-button-toggle [value]="'EVALUATING'">{{ "generic.accept-answers" | translate }}</mat-button-toggle>
        <mat-button-toggle [value]="'CLOSED'">{{ "generic.block-answers" | translate }}</mat-button-toggle>
      </mat-button-toggle-group>
    </div>


    <mat-tab-group *ngIf="survey?.responses?.length > 0" mat-stretch-tabs color="primary" mat-align-tabs="center" style="border-bottom: none;" class="mt-5 mb-0 p-0" #tabGroup (selectedTabChange)="tabChanged($event)">
      <mat-tab [label]="'generic.summary' | translate">
      </mat-tab>
<!--      <mat-tab [label]="'generic.question' | translate">-->
<!--      </mat-tab>-->
<!--      <mat-tab [label]="'generic.individuals' | translate">-->
<!--      </mat-tab>-->
    </mat-tab-group>
  </mat-card-content>
</mat-card>
<div class="mt-5" *ngIf="survey?.responses?.length > 0">
  <ng-container *ngIf="tabIndex == 0">
    <div
      *ngFor="let question of survey?.questions; let i = index;"
      class="container-fluid accordion-container mt-3 accordion-inner"
    >
      <mat-accordion class="accordion" multi >
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header class="py-2">
            <mat-panel-title>
              <span>{{ question.question }}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-single-question-responses [survey]="survey" [question]="question" [survey_responses]="survey?.responses" [showPrivateInfo]="showPrivateInfo"/>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </ng-container>

  <ng-container *ngIf="tabIndex == 1">

  </ng-container>

  <ng-container *ngIf="tabIndex == 2">

  </ng-container>

</div>

<div class="anonymize-button" *ngIf="survey?.responses?.length > 0" (click)="toggleAnonymousData()">
  <span *ngIf="showPrivateInfo">{{ 'generic.hide-private-data' | translate }}</span>
  <span *ngIf="!showPrivateInfo">{{ 'generic.show-private-data' | translate }}</span>
</div>
