<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>
<ng-template #details>
  <mat-card class="mb-4">
    <mat-card-content>
      <div class="row justify-content-center" *ngIf="!createMode">
        <div class="col-12 row justify-content-center">
          <button
            *ngIf="currentUserInTeam"
            class="btn-custom pink mt-4 mb-3 mr-4"
            [routerLink]="['/surveys/'+survey?.id+'/response']"
            mat-flat-button
          >
            {{ 'components.responses.ask-for-response' | translate }}
          </button>
          <button class="btn-custom border-0 d-flex align-items-center px-5 py-2 mt-4 mb-3" (click)="copyLink()">
            <mat-icon class="mr-2">share</mat-icon>
            {{ 'generic.buttons.copy-link' | translate }}
          </button>
        </div>
        <div class="col-12 row justify-content-center">
          <button class="btn-custom border-0 d-flex align-items-center px-5 py-2 mb-4" (click)="downloadResponsesCSV()"
                  *ngIf="survey?.responses?.length > 0 && userService?.userInfo?.id == survey?.created_by_user_id">
            <mat-icon class="mr-2">download</mat-icon>
            {{ 'generic.export-survey-results' | translate }}
          </button>
        </div>
      </div>
      <div class="box flex-column my-0 pb-0 pt-3 px-3 border-0">
        <mat-form-field appearance="fill">
          <mat-label>{{
              "generic.title" | translate
            }}
          </mat-label>
          <input required [disabled]="!createMode" matInput [(ngModel)]="survey.title" name="survey_title">
        </mat-form-field>
      </div>
      <div class="box flex-column mt-0 mb-2 pb-0 px-3 border-0 mt-0 pt-0">
        <mat-form-field appearance="fill">
          <mat-label>{{
              "generic.description" | translate
            }}
          </mat-label>
          <textarea matInput [disabled]="!createMode" [(ngModel)]="survey.description"
                    name="survey_description"></textarea>
        </mat-form-field>
      </div>
      <div class="my-3 row">
        <div class="col-12">
          <div class="container">
            <app-attachments-list [attachments]="survey?.attachments" [buttonToAdd]="true" [editable]="createMode"/>
          </div>
        </div>
      </div>
      <div class="box flex-column mt-0 mb-2 pb-0 px-3 border-0">
        <mat-label class="label-box font-weight-bold">
          {{
            "modals.modal-survey-details.settings"
              | translate
          }}
        </mat-label>

        <div class="row mt-3">
          <!--          <div
                      class="col-12 my-3"
                    >
                      <mat-label>{{
                          "modals.modal-survey-details.settings.edit_after_submit_description"
                            | translate
                        }}</mat-label>
                      <mat-button-toggle-group [disabled]="!createMode" (ngModelChange)="handleSurveyChanged()"  class="ml-4" [(ngModel)]="survey.edit_after_submit" name="survey_edit_after_submit" style="  height: 38px;
            align-items: center;">
                        <mat-button-toggle [disabled]="!createMode" [value]="true" (click)="getSections()">{{ "generic.answers.yes" | translate }}</mat-button-toggle>
                        <mat-button-toggle [disabled]="!createMode" [value]="false" (click)="getSections()">{{ "generic.answers.no" | translate }}</mat-button-toggle>
                      </mat-button-toggle-group>
                    </div>-->
          <div
            class="col-12 my-3"
          >
            <mat-label>{{
                "modals.modal-survey-details.settings.multiple_user_responses_is_enabled_description"
                  | translate
              }}
            </mat-label>
            <mat-button-toggle-group [disabled]="!createMode" (ngModelChange)="handleSurveyChanged()" class="ml-4"
                                     [(ngModel)]="survey.multiple_user_responses_is_enabled"
                                     name="survey_multiple_user_responses_is_enabled" style="  height: 38px;
  align-items: center;">
              <mat-button-toggle [disabled]="!createMode" [value]="true">{{ "generic.answers.yes" | translate }}
              </mat-button-toggle>
              <mat-button-toggle [disabled]="!createMode" [value]="false">{{ "generic.answers.no" | translate }}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div
            class="col-12 my-3"
          >
            <mat-label>{{
                "modals.modal-survey-details.settings.visilibility_type_description"
                  | translate
              }}
            </mat-label>
            <mat-button-toggle-group class="ml-4" (ngModelChange)="handleSurveyChanged()" [disabled]="!createMode"
                                     [(ngModel)]="survey.visibility_type" name="survey_visibility_type" style="  height: 38px;
  align-items: center;">
              <mat-button-toggle [disabled]="!createMode" [value]="'PUBLIC'">{{ "generic.public" | translate }}
              </mat-button-toggle>
              <mat-button-toggle [disabled]="!createMode" [value]="'PRIVATE'">{{ "generic.private" | translate }}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div
            class="col-12 my-3"
          >
            <mat-label>{{
                "modals.modal-survey-details.settings.attachments_enabled"
                  | translate
              }}
            </mat-label>
            <mat-button-toggle-group [disabled]="!createMode" (ngModelChange)="handleSurveyChanged()" class="ml-4"
                                     [(ngModel)]="survey.attachments_enabled" name="survey_attachments_enabled" style="  height: 38px;
  align-items: center;">
              <mat-button-toggle [disabled]="!createMode" [value]="true">{{ "generic.answers.yes" | translate }}
              </mat-button-toggle>
              <mat-button-toggle [disabled]="!createMode" [value]="false">{{ "generic.answers.no" | translate }}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div *ngIf="email_templates?.length > 0 && createMode" class="col-4 my-3" appearance="fill">
            <mat-label>{{ 'components.decision-table.select-template' | translate }}</mat-label>
            <mat-select name="email_template" [(ngModel)]="survey.email_template" required>
              <mat-option></mat-option>
              <mat-option *ngFor="let template of email_templates" [value]="template.id">{{ template.name }}
              </mat-option>
            </mat-select>
            <i class="abs-tr icon-circle_info grey-color mr-auto"></i>
          </div>
        </div>
      </div>

      <div class="box flex-column mt-0 mb-2 pb-0 px-3 border-0"
           style="background: radial-gradient(farthest-side, #F1F2F2, white);"
           *ngIf="surveyIsPrivate && (!surveyId || survey?.is_draft)">

        <mat-label class="label-box font-weight-bold">
          {{
            "generic.team"
              | translate
          }}
        </mat-label>

        <div class="row mt-3 justify-content-center">
          <app-team-members-list [members]="survey.members"/>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #questions>
  <mat-card class="my-5">
    <mat-card-content>
      <div class="row">
        <div class="col-12 col-lg-6 px-0 mb-0">
          <div class="box py-0 px-2 bg-transparent border-0 mb-0">
            <mat-label class="box-title px-3 mb-0">{{ 'generic.filters' | translate }}</mat-label>
          </div>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <mat-form-field class="select-primary col-2" appearance="fill">
          <mat-label>{{ 'generic.section-filter' | translate }}</mat-label>
          <mat-select name="filter_section" [(ngModel)]="questions_filters.selected_section">
            <mat-option value="">{{ "generic.all" | translate }}</mat-option>
            <mat-option *ngFor="let sec of question_sections" [value]="sec">{{ sec }}</mat-option>
          </mat-select>
        </mat-form-field>
        <section style="padding: 0.75em 0.75em 0 0.75em;" *ngIf="isResponse">
          <mat-checkbox class="col-3"
                        [(ngModel)]="questions_filters.only_not_answered">{{ 'generic.only-answered' | translate }}
          </mat-checkbox>
        </section>
        <section style="padding: 0.75em 0.75em 0 0.75em;">
          <mat-checkbox class="col-3"
                        [(ngModel)]="questions_filters.accordions_opened">{{ 'pages.responses.filters.show-all-open' | translate }}
          </mat-checkbox>
        </section>
      </div>
    </mat-card-content>
  </mat-card>

  <div cdkDropList (cdkDropListDropped)="createMode ? drop($event) : ''" class="question-list">
    <app-single-question *ngFor="let question of filteredQuestions; let i = index"
                         [question]="question"
                         [isDeletable]="this.survey.questions.length > 1"
                         (questionDuplicated)="addQuestion($event)"
                         (questionDeleted)="removeQuestion($event)"
                         [editable]="createMode"
                         [isResponse]="isResponse"
                         [survey]="survey"
                         cdkDrag
                         [cdkDragDisabled]="!createMode"
                         [accordionOpened]="questions_filters.accordions_opened"
                         class="question-box"
                         [ngStyle]="{'cursor': createMode ? 'move' : 'default'}"
    />
  </div>
</ng-template>

<div class="mb-5" *ngIf="!loading && survey">
  <form #form=ngForm (keydown.enter)="$event.preventDefault()" novalidate>

    <ng-container *ngIf="projectId">
      <div class="container-fluid accordion-container" *ngFor="let section of sections; let i = index">
        <div class="container">
          <mat-accordion class="accordion" multi>
            <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span class="index">{{ i + 1 }}</span>
                  {{
                    section.title  | translate
                  }}
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="row container" *ngIf="section.id === 'DETAILS' && !isResponse">
                <ng-template [ngTemplateOutlet]="details"></ng-template>
              </div>

              <div *ngIf="section.id === 'QUESTIONS'">
                <ng-template [ngTemplateOutlet]="questions"></ng-template>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!projectId">
      <ng-container *ngIf="!isResponse">
        <ng-template [ngTemplateOutlet]="details"></ng-template>
      </ng-container>
      <ng-template [ngTemplateOutlet]="questions"></ng-template>
    </ng-container>
  </form>

  <div class="row justify-content-center my-3" *ngIf="!isResponse && createMode">
    <button
      mat-flat-button
      color="primary"
      (click)="addQuestion()"
    >
      {{ 'modals.modal-survey-details.add-question' | translate }}
    </button>
  </div>

  <ng-container *ngIf="!isResponse">
    <div class="buttons-container container py-5">
      <button
        class="button-primary float-left"
        mat-button
        (click)="canceled.emit(survey)"
      >
        <i class="icon-Backward-arrow-small"></i>
        {{ "generic.buttons.go-back" | translate }}
      </button>
      <div class="float-right">
        <button
          *ngIf="createMode && survey?.is_draft && survey?.id"
          class="button-danger mr-4"
          (click)="deleteSurvey()"
          mat-button
        >
          <i class="icon-cestino"></i>
          <label>
            {{ "generic.delete-survey" | translate }}
          </label>
        </button>
        <button
          *ngIf="createMode"
          class="button-primary mr-4"
          (click)="enablePreviewMode()"
          mat-button
        >
          <label>
            {{ "generic.preview" | translate }}
          </label>
        </button>
        <button
          *ngIf="createMode"
          class="button-primary mr-4"
          (click)="onSubmit(form, true)"
          mat-button
        >
          <label>
            {{ "generic.buttons.save-draft" | translate }}
          </label>
        </button>
        <button
          *ngIf="createMode"
          class="button-primary float-right"
          mat-button
          (click)="onSubmit(form)"
        >
          <i class="icon-Forward-arrow-small"></i>
          <label>
            {{ "generic.buttons.save-and-start" | translate }}
          </label>
        </button>
      </div>
    </div>
  </ng-container>
</div>

<div class="anonymize-button" *ngIf="previewMode" (click)="disablePreviewMode()">
  <span>{{ 'modals.modal-survey-details.exit-from-preview' | translate }}</span>
</div>

