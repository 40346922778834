<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<div class="container-fluid accordion-container" *ngIf="project">
  <div class="container">
    <mat-accordion class="accordion" multi>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="index">1</span>
            {{ "pages.projects.project-details.details" | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="container my-5">
          <mat-card class="login-card">
            <mat-card-content>
              <form (submit)="updateProject()">
                <mat-form-field class="full-width">
                  <mat-label>{{ "generic.title" | translate }}</mat-label>
                  <input matInput [(ngModel)]="projectUpdateModel.title" name="title" required>
                </mat-form-field>

                <mat-form-field class="full-width">
                  <mat-label>{{ "generic.description" | translate }}</mat-label>
                  <textarea matInput [(ngModel)]="projectUpdateModel.description" name="description"></textarea>
                </mat-form-field>
                <div class="row justify-content-center my-3">
                  <a mat-button color="warn" *ngIf="projectUpdateModelIsChanged" (click)="setUpdateProjectModel()"
                     class="mr-2">{{ "generic.buttons.cancel-and-back" | translate }}</a>
                  <button mat-raised-button color="primary" type="submit">{{ "generic.update" | translate }}</button>
                </div>
              </form>
            </mat-card-content>
          </mat-card>
        </div>

      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<div class="container-fluid accordion-container" *ngIf="project">
  <div class="container">
    <mat-accordion class="accordion" multi>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="index">2</span>
            {{ "pages.projects.component.decision-table" | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="my-5">
          <app-all-decision-table [projectId]="project_id" [input_decision_tables]="project?.decision_tables"/>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<div class="container-fluid accordion-container"
     *ngIf="project && userService.currentOrganizationHasFeature('surveys')">
  <div class="container">
    <mat-accordion class="accordion" multi>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="index">3</span>
            {{ 'pages.projects.component.surveys' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="my-5">
          <app-all-surveys [projectId]="project?.id" [input_surveys]="project?.surveys"/>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<div class="container-fluid accordion-container" *ngIf="project">
  <div class="container">
    <mat-accordion class="accordion" multi>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="index">4</span>
            P.I.C.O.
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="container my-5">
          <mat-card class="login-card">
            <mat-card-content>
              <form>
                <mat-form-field class="full-width">
                  <mat-label>{{ 'components.decision-table.pico.patients' | translate }}</mat-label>
                  <input matInput [(ngModel)]=patients name="patients" required/>
                </mat-form-field>

                <mat-form-field class="full-width">
                  <mat-label>Intervention</mat-label>
                  <input matInput [(ngModel)]="intervention" name="intervention" required>
                </mat-form-field>

                <mat-form-field class="full-width">
                  <mat-label>{{ 'components.decision-table.pico.comparison'|translate }}</mat-label>
                  <input matInput [(ngModel)]="comparison" name="comparison" required>
                </mat-form-field>

                <mat-form-field class="full-width">
                  <mat-label>{{ 'components.decision-table.pico.outcome'|translate }}</mat-label>
                  <input matInput [(ngModel)]="outcomes" name="outcomes" required>
                </mat-form-field>

                <!--                class="row justify-content-center mt-5 mb-3"-->
                <div class="{{ picoQuestionStatus !== 'ready' ? 'row justify-content-center my-3' : '' }}">
                  <a mat-button color="warn" *ngIf="projectUpdateModelIsChanged" (click)="setUpdateProjectModel()"
                     class="mr-2">{{ "generic.buttons.cancel-and-back" | translate }}</a>
                  <ng-container *ngIf="!picoQuestionStatus">
                    <button mat-raised-button color="primary"
                            (click)="generatePICOQuestion()">{{ 'components.decision-table.pico.generate-pico-question'|translate }}
                    </button>
                  </ng-container>
                  <ng-container *ngIf="picoQuestionStatus === 'pending'">
                    <mat-spinner diameter="40"></mat-spinner>
                  </ng-container>
                </div>
                <mat-form-field class="full-width" *ngIf="picoQuestionStatus === 'ready' || !picoQuestionStatus">
                  <mat-label>{{ 'components.decision-table.pico.generated-pico-search-question'|translate }}</mat-label>
                  <!--                  <input matInput  [(ngModel)]="question" name="question" readonly>-->
                  <div class="box flex-column my-0 pb-0 pt-5 px-3 bordered">
                    <mat-form-field class="full-width">
                          <textarea
                            disabled
                            class="description"
                            matInput rows="4"
                            name="pico_response"
                            [(ngModel)]="question"
                            readonly
                          ></textarea>
                    </mat-form-field>
                  </div>
                </mat-form-field>
              </form>
            </mat-card-content>
          </mat-card>
        </div>

      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<div class="container-fluid accordion-container" *ngIf="project">
  <div class="container">
    <mat-accordion class="accordion" multi>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="index">5</span>
            {{ "pages.projects.component.literature-review" | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="container my-5">
          <mat-card class="login-card">

            <div class="container my-5" *ngIf="keywordsForChipList.length>0">
              <mat-card class="login-card">
                <mat-card-content>
                  <mat-chip-list aria-label="Keyword List">
                    <mat-chip
                      *ngFor="let keyword of keywordsForChipList"
                      color="accent"
                      selected>
                      {{ keyword }}
                    </mat-chip>
                  </mat-chip-list>
                </mat-card-content>
              </mat-card>
            </div>

            <mat-card-content>
              <form>
                <mat-form-field class="full-width">
                  <mat-label>{{ 'components.decision-table.generated-keywords' | translate }}</mat-label>
                  <input matInput [(ngModel)]="keywords" name="keywords" required/>
                </mat-form-field>

                <!--                <mat-form-field class="full-width">-->
                <!--                  <mat-label>Generated bibliography table</mat-label>-->
                <!--                  <textarea matInput [(ngModel)]="biblioTable" name="biblioTable" rows="15" readonly></textarea>-->
                <!--                </mat-form-field>-->


                <div class="row justify-content-center mt-5 mb-3">

                  <div *ngIf="biblioSearchStatus === 'pending'; else elseBlock">
                    <mat-spinner diameter="40"></mat-spinner>
                  </div>

                  <ng-template #elseBlock>
                    <button mat-raised-button color="primary" [disabled]="!question" (click)="searchTearmInPublmed()"
                    >{{ 'components.decision-table.search-on-pubmed-va' | translate }}
                    </button>
                  </ng-template>

                </div>
              </form>
            </mat-card-content>
          </mat-card>
        </div>

        <div class="container my-5">

          <mat-card *ngIf="searchNumItems === 0 && biblioSearchStatus !== 'pending'" class="my-5"
                    style="background-color: red; color: white;">
            {{ 'pages.projects.component.literature-review.no-items' | translate }}
          </mat-card>

          <mat-table [dataSource]="biblioSearchDataSource" matSort>
            <!-- Definizione delle colonne -->
            <ng-container matColumnDef="articleTitle">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Title</mat-header-cell>
              <mat-cell *matCellDef="let article">{{ article.articleTitle }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="authors">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Authors</mat-header-cell>
              <mat-cell *matCellDef="let article">{{ article.authors }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="year">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Year</mat-header-cell>
              <mat-cell *matCellDef="let article">{{ article.year }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="studyType">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Study Type</mat-header-cell>
              <mat-cell *matCellDef="let article">{{ article.studyType }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="mainOutcome">
              <mat-header-cell *matHeaderCellDef>Main Outcome</mat-header-cell>
              <mat-cell *matCellDef="let article">{{ article.mainOutcome }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="riskOfBias">
              <mat-header-cell *matHeaderCellDef>Risk of Bias</mat-header-cell>
              <mat-cell *matCellDef="let article">{{ article.riskOfBias }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="consistency">
              <mat-header-cell *matHeaderCellDef>Consistency</mat-header-cell>
              <mat-cell *matCellDef="let article">{{ article.consistency }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="precision">
              <mat-header-cell *matHeaderCellDef>Precision</mat-header-cell>
              <mat-cell *matCellDef="let article">{{ article.precision }}</mat-cell>
            </ng-container>

            <!--              <ng-container matColumnDef="effectSize">-->
            <!--                <mat-header-cell *matHeaderCellDef>Effect Size</mat-header-cell>-->
            <!--                <mat-cell *matCellDef="let article">{{ article.effectSize }}</mat-cell>-->
            <!--              </ng-container>-->

            <ng-container matColumnDef="gradeQuality">
              <mat-header-cell *matHeaderCellDef>Grade Quality</mat-header-cell>
              <mat-cell *matCellDef="let article">{{ article.gradeQuality }}</mat-cell>
            </ng-container>

            <!-- Header e righe della tabella -->
            <mat-header-row *matHeaderRowDef="biblioSearchDisplayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: biblioSearchDisplayedColumns;"></mat-row>
          </mat-table>

        </div>

      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<div class="container-fluid accordion-container" *ngIf="project">
  <div class="container">
    <mat-accordion class="accordion" multi>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="index">6</span>
            Simulator
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<div class="container-fluid accordion-container" *ngIf="project">
  <div class="container">
    <mat-accordion class="accordion" multi>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="index">8</span>
            Automatic reports
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<div class="buttons-container container py-4">
  <button class="button-primary float-left" mat-button [routerLink]="['/home']" routerLinkActive="router-link-active">
    <i class="icon-Backward-arrow-small"></i>
    {{ 'generic.buttons.home' | translate }}
  </button>
</div>

