import {Component, Input, OnInit} from '@angular/core';
import {SurveyService} from '../../../services/surveys.service';
import {TranslateService} from '@ngx-translate/core';
import {ScriptLoaderService} from 'angular-google-charts';
import {ToastService} from '../../../services/general/toast.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatTableDataSource} from '@angular/material/table';
import {ModalShowEvidencesComponent} from '../../../modals/modal-show-evidences/modal-show-evidences.component';
import {MatDialog} from '@angular/material/dialog';
import {ResponseTypesService} from "../../../services/config/response-types.service";
import {UserService} from '../../../services/user.service';
import {AiService} from "../../../services/ai.service";

@Component({
  selector: 'app-single-question-responses',
  templateUrl: './single-question-responses.component.html',
  styleUrls: ['./single-question-responses.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*', minHeight: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class SingleQuestionResponsesComponent implements OnInit {
  @Input() question;
  @Input() survey_responses;
  @Input() showPrivateInfo;
  @Input() survey;

  constructor(
    public surveyService: SurveyService,
    public translateService: TranslateService,
    private loaderService: ScriptLoaderService,
    private responsesTypeService: ResponseTypesService,
    private dialog: MatDialog,
    private userService: UserService,
  ) {
  }

  currentOrganization = {};
  columnsToDisplay: string[] = ['name', 'email', 'evidences', 'action'];
  responses: any = new MatTableDataSource([]);
  chartLoaded = false;
  allOpenedContent = '';
  allOpenedContentLoaded = false;
  analyticsFields = [
    {parameter: 'mean', translateString: 'generic.average'},
    {parameter: 'geometricMean', translateString: 'generic.geometric-average'},
    {parameter: 'mode', translateString: 'generic.mode'},
    {parameter: 'median', translateString: 'generic.median'},
    {parameter: 'variance', translateString: 'generic.variance'},
    {parameter: 'q1', translateString: 'Q1'},
    {parameter: 'q2', translateString: 'Q2'},
    {parameter: 'q3', translateString: 'Q3'},
    {parameter: 'q4', translateString: 'Q4'},
  ];

  ngOnInit() {
    this.init();
    const typology = this.question.typology;
    if (this.surveyService.isConsensusQuestion(typology)) {
      this.columnsToDisplay = ['name', 'sentiment', 'feedback', 'answer', 'confidence', 'evidences'];
    } else if (this.surveyService.isOpenedQuestion(typology)) {
      this.columnsToDisplay = ['name', 'sentiment', 'answer', 'feedback', 'evidences'];
    } else if (this.surveyService.isMultipleChoiceQuestion(typology) ||
      this.surveyService.isCheckboxQuestion(typology) ||
      this.surveyService.isQuantitativeQuestion(typology)) {
      this.columnsToDisplay = ['name', 'sentiment', 'answer', 'feedback', 'evidences'];
    }

    const currentOrganitazionArr = this.userService.userInfo.organizations.filter((currentOrganization) => {
      return (currentOrganization.id === this.userService.userInfo.details.current_organization_id);
    });

    this.currentOrganization = currentOrganitazionArr[0];
  }

  init() {
    this.question?.responses?.forEach((response) => {
      this.allOpenedContent += response.answer + '. ';
      response.attachments = [...response.attachment_pubmeds, ...response.attachment_files];
      const survey_response = this.survey_responses?.find((survey_response) => survey_response.id === response.survey_answer_id);
      response.name = survey_response.name;
      response.email = survey_response.email;
    });
    this.allOpenedContentLoaded = true;
    if (this.question?.typology === 'QUANTITATIVE') {
      const responses = this.question.responses.map(response => parseFloat(response.answer));
      this.question.mode = this.mode(responses).toFixed(2);
      this.question.variance = this.variance(responses).toFixed(2);
      this.question.median = this.median(responses).toFixed(2);
      this.question.mean = this.mean(responses).toFixed(2);
      this.question.geometricMean = this.geometricMean(responses).toFixed(2);
    }
    const interval = setInterval(() => {
      if (!this.chartLoaded) {
        const chartDiv = document.getElementById('chart_question_id_' + this.question.id);
        if (chartDiv) {
          this.loadChartPackages();
          this.chartLoaded = true;
          clearInterval(interval);
        }
      }
    }, 200);

    this.responses = new MatTableDataSource(this.question?.responses);
  }

  mean(arr) {
    const sum = arr.reduce((acc, val) => acc + val, 0);
    return sum / arr.length;
  }

  geometricMean(arr) {
    const product = arr.reduce((acc, val) => acc * val, 1);
    return Math.pow(product, 1 / arr.length);
  }

  mode(arr) {
    return Array.from(
      arr.reduce((m, el) => m.set(el, (m.get(el) || 0) + 1), new Map())
    ).reduce((r, [k, v]) => (v > r[1] ? [k, v] : r), [null, 0])[0];
  }

  median(arr) {
    const sorted = arr.sort((a, b) => a - b);
    const mid = Math.floor(sorted.length / 2);
    if (sorted.length % 2 === 0) {
      return (sorted[mid - 1] + sorted[mid]) / 2;
    } else {
      return sorted[mid];
    }
  }

  variance(arr) {
    const mu = this.mean(arr);
    const squaredDiffs = arr.map((val) => Math.pow(val - mu, 2));
    return this.mean(squaredDiffs);
  }

  loadChartPackages() {
    this.loaderService.loadChartPackages('corechart').subscribe(() => {
      google.charts.load('current', {packages: ['corechart', 'controls', 'bar']});
      google.charts.setOnLoadCallback(this.drawCharts);
    });
  }

  getRandomColor() {
    const letters = '0123456789ABCDEF'.split('');
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  getAbstainedByQuestion(question) {
    return question?.responses?.filter((response) => response.status === 'ABSTAINED');
  }

  drawCharts = () => {
    if (this.surveyService.isMultipleChoiceQuestion(this.question.typology)) {
      const tmp = [
        [this.translateService.instant('generic.option'), this.translateService.instant('components.responses.responses')],
      ];
      this.question?.choices?.forEach(option => {
        tmp.push([option.title, option.responses.length]);
      });

      const abstainedAnswers = this.getAbstainedByQuestion(this.question);

      if (abstainedAnswers.length > 0) {
        tmp.push([this.translateService.instant('generic.users-abstained'), abstainedAnswers.length]);
      }

      const data = google.visualization.arrayToDataTable(tmp);
      const options = {};

      const chart = new google.visualization.PieChart(document.getElementById('chart_question_id_' + this.question.id));
      chart.draw(data, options);
    }
    if (this.surveyService.isCheckboxQuestion(this.question.typology)) {
      const tmp = [
        [this.translateService.instant('generic.option'), this.translateService.instant('components.responses.responses'), {role: 'style'}],
      ];
      this.question?.choices?.forEach(option => {
        tmp.push([option.title, option.responses.length, this.getRandomColor()]);
      });

      const abstainedAnswers = this.getAbstainedByQuestion(this.question);

      if (abstainedAnswers.length > 0) {
        tmp.push([this.translateService.instant('generic.users-abstained'), abstainedAnswers.length, this.getRandomColor()]);
      }

      const data = google.visualization.arrayToDataTable(tmp);
      const maxValue = Math.max(...tmp.slice(1).map(row => row[1]));
      const options = {
        legend: 'none',
        vAxis: {
          minValue: 0,
          maxValue,
          format: '#',
        }
      };

      const chart = new google.visualization.BarChart(document.getElementById('chart_question_id_' + this.question.id));
      // @ts-ignore
      chart.draw(data, options);
    }
    if (this.surveyService.isQuantitativeQuestion(this.question.typology)) {
      const tmp = [
        [this.translateService.instant('generic.value'), this.translateService.instant('components.responses.responses')],
      ];

      const max_range = parseFloat(this.question?.customs?.max);
      const min_range = parseFloat(this.question?.customs?.min);
      const step = parseFloat(this.question?.customs?.step) || 1;  // Default to 1 if step is not defined

      const answerCount = {};

      // Fill answerCount with response data
      this.question?.responses.forEach(response => {
        if (response.status === 'ANSWERED') {
          const answer = parseFloat(response.answer).toFixed(2);  // Ensure answer is a string with two decimals
          answerCount[answer] = (answerCount[answer] || 0) + 1;
        }
      });

      // Fill in missing ranges with zero counts
      for (let i = min_range; i <= max_range; i += step) {
        const key = i.toFixed(2);  // Keep key as a string with two decimals
        if (!(key in answerCount)) {
          answerCount[key] = 0;
        }
      }

      // Convert answerCount to sorted array format for visualization
      Object.keys(answerCount).sort((a, b) => parseFloat(a) - parseFloat(b)).forEach(key => {
        tmp.push([parseFloat(key), answerCount[key]]);
      });

      const data = google.visualization.arrayToDataTable(tmp);
      const options = {
        legend: 'none',
        vAxis: {
          format: '#',
        }
      };
      const chart = new google.visualization.ColumnChart(document.getElementById('chart_question_id_' + this.question.id));
      // @ts-ignore
      chart.draw(data, options);

      // Calculate box plot values
      const answers = this.question?.responses?.filter(response => response.status === 'ANSWERED').map(response => parseFloat(response.answer));
      const {min, q1, median, q3, max} = this.calculateBoxPlotValues(answers);

      this.question.q1 = q1;
      this.question.q2 = median;
      this.question.q3 = q3;
      this.question.q4 = max;

      const boxPlotData = google.visualization.arrayToDataTable([
        ['Label', 'Min', 'Q1', 'Median', 'Q3', 'Max'],
        ['Data', min, q1, median, q3, max]
      ]);

      const boxPlotOptions = {
        title: 'Box Plot',
        legend: 'none',
        seriesType: 'candlesticks',
        series: {1: {type: 'line'}},
        orientation: 'horizontal',
        boxStyle: {stroke: '#888', strokeWidth: 1, fill: '#888', fillOpacity: 0.3}
      };

      const boxPlotChart = new google.visualization.CandlestickChart(document.getElementById('chart_box_plot_question_id_' + this.question.id));
      // @ts-ignore
      boxPlotChart.draw(boxPlotData, boxPlotOptions);
    }

    if (this.surveyService.isConsensusQuestion(this.question.typology)) {

      // CHART FEEDBACK
      const tmp = [
        [this.translateService.instant('generic.option'), this.translateService.instant('components.responses.responses')],
      ];

      const agreeResponsesLength = this.question.responses.filter((response) => response.answer == "AGREE").length;
      const disagreeResponsesLength = this.question.responses.filter((response) => response.answer == "DISAGREE").length;
      const abstainedResponsesLength = this.question.responses.filter((response) => response.answer == "ABSTAINED").length;


      tmp.push([this.translateService.instant('generic.agree'), agreeResponsesLength]);
      tmp.push([this.translateService.instant('generic.disagree'), disagreeResponsesLength]);
      tmp.push([this.translateService.instant('generic.abstained'), abstainedResponsesLength]);

      const data = google.visualization.arrayToDataTable(tmp);
      const options = {
        height: 200,
        pieHole: 0.4,
        enableInteractivity: false,
        pieSliceText: 'none',
        slices: {
          0: {color: '#00bc1d'},
          1: {color: '#ff0000'},
          2: {color: '#e5e3e3'},
        },
        animation: {
          startup: true,
        },
      };

      const chart = new google.visualization.PieChart(document.getElementById('chart_question_id_' + this.question.id));
      chart.draw(data, options);

      // FINE CHART FEEDBACK

      // INIZIO CHART PARAMETERS


      const data2 = google.visualization.arrayToDataTable([
        ['Element', 'Density', {role: 'style'}],
        [
          this.translateService.instant('generic.certainty-degree'),
          {
            v: this.question.results.certainty_degree,
            f: this.question.results.certainty_degree + '%',
          },
          '#1b61a1',
        ],
        [
          this.translateService.instant('generic.contradiction-degree'),
          {
            v: this.question.results.contradiction_degree,
            f: this.question.results.contradiction_degree + '%',
          },
          '#ff0000',
        ],
      ]);

      const view = new google.visualization.DataView(data2);
      view.setColumns([
        0,
        1,
        {
          calc: 'stringify',
          sourceColumn: 1,
          type: 'string',
          role: 'annotation',
        },
        2,
      ]);

      const options2 = {
        height: 200,
        pieHole: 0.4,
        enableInteractivity: false,
        pieSliceText: 'none',
        slices: {
          0: {color: '#00bc1d'},
          1: {color: '#ff0000'},
          2: {color: '#e5e3e3'},
        },
        animation: {
          startup: true,
        },
      };

      const chart2 = new google.visualization.BarChart(
        document.getElementById('chart_parameters_div_' + this.question.id)
      );

      chart2.draw(view, options2);
    }
  }

  calculateBoxPlotValues(answers) {
    let numericAnswers = answers.map(Number);

    numericAnswers.sort((a, b) => a - b);

    function median(arr) {
      const mid = Math.floor(arr.length / 2);
      if (arr.length % 2 === 0) {
        return (arr[mid - 1] + arr[mid]) / 2;
      } else {
        return arr[mid];
      }
    }

    function quartile(arr, q) {
      const pos = ((arr.length - 1) * q);
      const base = Math.floor(pos);
      const rest = pos - base;
      if ((arr[base + 1] !== undefined)) {
        return arr[base] + rest * (arr[base + 1] - arr[base]);
      } else {
        return arr[base];
      }
    }

    const min = numericAnswers[0];
    const max = numericAnswers[numericAnswers.length - 1];
    const q1 = quartile(numericAnswers, 0.25);
    const q2 = median(numericAnswers);
    const q3 = quartile(numericAnswers, 0.75);

    return {min, q1, median: q2, q3, max};
  }


  showExpertEvideces(element) {
    const dialogRef = this.dialog.open(ModalShowEvidencesComponent, {
      panelClass: 'medium-dialog',
      data: {
        userFullName: element?.name,
        attachments: element?.attachments,
      },
    });

    dialogRef.afterClosed().subscribe((event) => {
    });
  }

  getResponsesTypeService() {
    return this.responsesTypeService;
  }
}

