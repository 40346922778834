import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ScriptLoaderService} from 'angular-google-charts';
import {ProcessRolesService} from 'src/app/services/config/process-roles.service';
import {SuggestionService} from 'src/app/services/config/suggestion.service';
import {HelperService} from 'src/app/services/general/helper.service';
import {ModalShowEvidencesComponent} from '../../../../modals/modal-show-evidences/modal-show-evidences.component';
import {MatDialog} from '@angular/material/dialog';
import {ModalShowProposalsComponent} from '../../../../modals/modal-show-proposals/modal-show-proposals.component';
import {UserService} from '../../../../services/user.service';
import {TranslateService} from '@ngx-translate/core';
import {ECharts} from "echarts";
import {DecisionService} from "../../../../services/decision.service";
import {ToastService} from "../../../../services/general/toast.service";

@Component({
  selector: 'app-multicriteria-decision-table-dashboard',
  templateUrl: './multicriteria-decision-table-dashboard.component.html',
  styleUrls: ['./multicriteria-decision-table-dashboard.component.scss']
})
export class MulticriteriaDecisionTableDashboardComponent implements OnInit, OnChanges {

  @Input() decision_table: any;
  @Input() evaluation_session_dashboard: any;

  currentDecisionTable: any;
  currentEvaluationSessionDashboard: any;

  constructor(
    private loaderService: ScriptLoaderService,
    private userService: UserService,
    private translateService: TranslateService,
    private suggestionService: SuggestionService,
    private processRoleService: ProcessRolesService,
    private dialog: MatDialog,
    private decisionService: DecisionService,
    private toastService: ToastService,
  ) {
  }


  loadingGraphics = true;

  // feedbackData:[] = [];
  // criteriaData: [] = [];

  displayedColumnsCriteria: string[] = ['criteriatitle', 'suggest', 'risk', 'contradiction'];
  displayedColumns: string[] = ['expert', 'answers', 'time', 'cost', 'total_cost', 'new_proposal', 'evidence'];
  relapseRiskChartOptions = null;
  relapseRiskChartSingleChecksOptions = null;
  singleCheckRelapseChart = null;
  criteriaMap = new Map<string, number[]>();
  a = null;
  b = null;
  c = null;

  ngOnInit(): void {
    //  inizializzo i componenti
    if (this.decision_table && this.evaluation_session_dashboard) {
      this.currentDecisionTable = JSON.parse(JSON.stringify(this.decision_table));
      this.currentEvaluationSessionDashboard = JSON.parse(JSON.stringify(this.evaluation_session_dashboard));
    }

    //  recupero le informazioni per questo tavolo decisionale
    this.getDecisionTableData();

    //  carico i grafici
    this.loaderService.loadChartPackages('corechart', 'gauge', 'timeline').subscribe(() => {

      this.loadingGraphics = false;
    });
  }


  ngOnChanges(changes: SimpleChanges) {
    this.currentDecisionTable = JSON.parse(JSON.stringify(this.decision_table));
    this.currentEvaluationSessionDashboard = JSON.parse(JSON.stringify(this.evaluation_session_dashboard));
    this.init();
  }


  getDecisionTableData() {
    this.init();
  }

  showExpertEvideces(data) {
    const dialogRef = this.dialog.open(ModalShowEvidencesComponent, {
      panelClass: 'medium-dialog',
      data: {
        userFullName: data?.name || data?.email,
        attachments: data.attachment_expert_pubmed.concat(data.attachment_expert_files)
      },
    });

    dialogRef.afterClosed().subscribe((event) => {
    });
  }


  // tslint:disable-next-line:typedef
  init() {

    if (this.loadingGraphics) {
      setTimeout(() => {
        this.init();
      }, 200);
      return;
    } else {
      if (this.isAlexion()) {
        // this.drawAlexionCharts();
        this.drawTimeLine();
      } else {
        this.prepareAnswers();
        this.prepareChartContradiction();
        this.prepareChartResponse();

        this.drawChartTargets();
        this.drawChartWeightTargets();
      }
    }
  }

  isAlexion() {
    return this.userService.currentOrganization?.customs?.profile === 'alexion';
  }

  /**
   * Prepara le risposte filtrando solo quelle che sono state completate dagli esperti
   */
  prepareAnswers() {

    this.currentEvaluationSessionDashboard.proposals.forEach(proposal => {
      proposal.criteria.forEach(criterio => {
        const responseFiltered: any = [];
        criterio.response.forEach(response => {
          if (response?.team_member?.response_stamps && response?.team_member?.response_stamps.completed == true) {
            responseFiltered.push(response);
          }
        });
        criterio.response_filtered = responseFiltered;
      });
    });
  }

  showSuggestedProposals(proposals) {
    const data = {
      suggestedProposals: proposals,
      decisionTable: this.decision_table,
    };
    this.dialog.open(ModalShowProposalsComponent, {
      panelClass: 'medium-dialog',
      data
    });
  }


  /**
   * Prepara il grafico delle risposte in percentuale alla scelta fatta dagli utenti
   */
  prepareChartContradiction() {

    this.currentEvaluationSessionDashboard.proposals.forEach(proposal => {
      proposal.criteria.forEach(criterio => {
        if (criterio.response.length > 0) {
          criterio.response_agree_count = criterio.response_filtered.filter(x => x.consent_value == 'AGREE').length;
          criterio.response_disagree_count = criterio.response_filtered.filter(x => x.consent_value == 'DISAGREE').length;
          criterio.response_abstained_count = criterio.response_filtered.filter(x => x.consent_value == 'ABSTAINED').length;
          criterio.total_response = criterio.response_filtered.length;

          if (criterio.total_response > 0) {
            criterio.response_agree_perc = Math.floor((criterio.response_agree_count / criterio.total_response) * 100);
            criterio.response_disagree_perc = Math.floor((criterio.response_disagree_count / criterio.total_response) * 100);
            criterio.response_abstained_perc = Math.floor((criterio.response_abstained_count / criterio.total_response) * 100);
          }
        }
      });
    });
  }

  /**
   * Prepara il grafico delle delle risposte complessive da parte degli esperti
   */
  prepareChartResponse() {

    //  setto l'array con tutti i membri presenti e vedo proposta per proposta se hanno risposto a tutte le domande
    this.currentEvaluationSessionDashboard.proposals.forEach(proposal => {
      //  TODO_MICHELE: dal backend devono arrivare solo i membri che sono consulted
      proposal.team_members = proposal.team_members.filter(x => this.processRoleService.isConstulted(x.process_role));

      // proposal.team_members = proposal.team_members.filter(x => x.process_role == 'CONSULTED');
      // proposal.criteria.forEach(criterio => {
      //   criterio.response.forEach(response => {
      //     if (response.team_member.process_role == "CONSULTED") {
      //       if (proposal.team_members.filter(x => x.email == response.team_member.email).length <= 0)
      //         proposal.team_members.push(response.team_member);
      //     }
      //   });
      // });
    });
  }

  drawAlexionCharts() {
    this.currentDecisionTable = JSON.parse(JSON.stringify(this.decision_table));
    this.currentEvaluationSessionDashboard = JSON.parse(JSON.stringify(this.evaluation_session_dashboard));

    interface SessionResults {
      results_on_target: {
        score_perc: number;
      }[];
    }

    let riskCurrentSession = null;

    const data = Object.values(this.currentDecisionTable?.decisionTableResult?.sessionResults)?.map((sessionResult: SessionResults, index: number) => {
      if (this.currentEvaluationSessionDashboard?.evaluation_session === (index + 1)) {
        riskCurrentSession = sessionResult?.results_on_target[0]?.score_perc;
      }
      return [
        this.translateService.instant('generic.session') + ' ' + (index + 1),
        sessionResult?.results_on_target ? sessionResult?.results_on_target[0]?.score_perc : null,
      ];
    });

    const gauge_data = google.visualization.arrayToDataTable([
      ['Label', 'Value'],
      ['Risk', riskCurrentSession],
    ]);

    const gauge_options = {
      redFrom: 90, redTo: 100,
      yellowFrom: 75, yellowTo: 90,
      minorTicks: 5
    };

    const gauge_chart = new google.visualization.Gauge(document.getElementById('chart_div_current_evaluation_session_risk'));

    gauge_chart.draw(gauge_data, gauge_options);

    const line_data = google.visualization.arrayToDataTable([
      ['Session', 'Risk score'],
      ...data,
    ]);

    const options = {
      title: this.translateService.instant('components.decision-table.decision-table-dashboard.index-risk-sessions'),
      hAxis: {
        title: this.translateService.instant('generic.sessions')
      },
      vAxis: {
        title: this.translateService.instant('generic.risk-index-perc'),
        minValue: 0,
        maxValue: 100
      },
    };

    const line_chart = new google.visualization.LineChart(document.getElementById('chart_div_evaluation_session_risks'));

    line_chart.draw(line_data, options);

    const div = document.getElementById('chart_div_current_evaluation_session_risk');

    const table = div.querySelector('table');

    if (table) {
      table.classList.add('mr-auto', 'ml-auto');
    }


    // Chart index

    const current_evaluation_session_proposals = this.decision_table?.proposals?.filter((proposal) => proposal.evaluation_session_id === this.evaluation_session_dashboard.id);
    const criteriaTitles = ['title', ...this.decision_table.framework.criteria.map(criterion => {
      return {
        id: criterion.id,
        title: criterion.title
      };
    })];
    const values = current_evaluation_session_proposals?.map((proposal) => {
      const tmp = [proposal.title];
      for (let i = 1; i < criteriaTitles.length; i++) {
        const value = proposal?.criteria?.find((criterion) => criterion.framework_criteria_id === criteriaTitles[i]?.id)?.results?.decision_score;
        tmp.push({
          v: value,
          f: value + '%',
        });
      }
      return tmp;
    });
    const chartData = [
      [
        'title',
        ...this.decision_table.framework.criteria.map(criterion => criterion.title)
      ],
      ...values
    ];

    const data2 = google.visualization.arrayToDataTable(chartData);

    const view = new google.visualization.DataView(data2);

    const chart = new google.visualization.BarChart(document.getElementById('chart_div_target'));

    chart.draw(view, {
      height: 350,
      legend: {position: 'top', maxLines: 3},
      bar: {groupWidth: '75%'},
      isStacked: true
    });
  }

  /** Disegna i grafici delle proposte */
  drawChartTargets() {

    //  controllo che ci siano risultati da mostrare
    if (!this.currentDecisionTable?.decisionTableResult && this.currentDecisionTable?.decisionTableResult?.results_criteria_on_target.length <= 0) {
      return;
    }

    //  recupero i punteggi per i pesi standard
    const groupedSTD = HelperService.groupBy(this.currentDecisionTable.decisionTableResult.results_criteria_on_target, 'weight_system');

    //  raggruppo per proposte e per criteri tutti i punteggi del sistema di peso "standard"
    const groupedCriteria = HelperService.groupBy(groupedSTD['Expert (STD)'], 'criteria');
    const groupedTarget = HelperService.groupBy(groupedSTD['Expert (STD)'], 'target');

    //  prendo il primo elemento dell'array e costruisco i nomi dei criteri
    const chartData: any = [[...['Title'], ...Object.keys(groupedCriteria), ...[{role: 'annotation'}]]];
    Object.keys(groupedTarget).forEach(targetKey => {

      //  inizializzo l'array con il nome della proposta
      const arrayOfTargetData: any = [targetKey];

      //  itero i criteri per questa proposata creando l'array dei dati
      groupedTarget[targetKey].forEach(target => {
        arrayOfTargetData.push({v: target.weight_perc, f: `${target.weight_perc}%`});
      });

      //   //  aggiungo una stringa vuota per poter disegnare il grafico
      arrayOfTargetData.push('');

      //   //  assegno all'array del grafico i dati appena completati
      chartData.push(arrayOfTargetData);
    });

    const data = google.visualization.arrayToDataTable(chartData);

    const view = new google.visualization.DataView(data);

    const chart = new google.visualization.BarChart(document.getElementById('chart_div_target'));

    chart.draw(view, {
      height: 350,
      legend: {position: 'top', maxLines: 3},
      bar: {groupWidth: '75%'},
      isStacked: true
    });
  }


  /** Disegna i grafici dei pesi degli obiettivi */
  drawChartWeightTargets() {

    //  controllo che ci siano risultati da mostrare
    if (!this.currentDecisionTable?.decisionTableResult && this.currentDecisionTable?.decisionTableResult?.results_on_target.length <= 0) {
      return;
    }

    //  recupero i punteggi per i pesi standard
    const groupedSTD = HelperService.groupBy(this.currentDecisionTable.decisionTableResult.results_on_target, 'weight_system');

    //  raggruppo per proposte e per criteri tutti i punteggi del sistema di peso "standard"
    const groupedTarget = HelperService.groupBy(groupedSTD['Expert (STD)'], 'target');

    //  costruisco i nomi dei titoli di riferimento per il grafico
    const chartData: any = [['Proposal', 'Score']];

    //  ciclo i target per settare i datisul diagramma
    Object.keys(groupedTarget).forEach(key => {
      Object.keys(groupedTarget[key]).forEach(proposalKey => {
        chartData.push([groupedTarget[key][proposalKey].proposal, groupedTarget[key][proposalKey].score_perc]);
      });
    });

    const data = google.visualization.arrayToDataTable(chartData);

    const view = new google.visualization.DataView(data);

    const chart = new google.visualization.PieChart(document.getElementById('chart_div_weight_target'));

    chart.draw(view, {
      height: 350,
      legend: {position: 'top', maxLines: 3}
    });
  }


  public getSuggestionService() {
    return this.suggestionService;
  }

  drawTimeLine() {

    const therapies = this.decision_table?.decisionTableResult?.tasks?.filter((task) => task.additional_text === 'therapy');
    const visits = this.decision_table?.decisionTableResult?.tasks?.filter((task) => task.additional_text === 'visit');
    const evaluation_sessions = this.decision_table?.evaluation_sessions;

    const getEvaluationRisk = (evaluation_session_id) => {
      const ev_session = evaluation_sessions?.find((session) => session.id === evaluation_session_id);
      if (ev_session && ev_session?.proposals) {
        return ev_session?.proposals[0]?.criteria.reduce((acc, el) => acc + el?.results?.decision_score, 0);
      }
      return 0;
    };

    const sessionData = evaluation_sessions?.filter((session) => session.evaluation_end).sort((session) => session.evaluation_session).map((session) => {
      return {
        risk: getEvaluationRisk(session.id),
        closeDate: new Date(session.evaluation_end),
        visitDate: visits?.find((visit) => visit?.customs?.session?.id === session.id)?.customs?.visit_date
      };
    });

    const points = sessionData.map(session => {
      return {
        x: session.visitDate ? session.visitDate : session.closeDate,
        y: session.risk,
        type: session.visitDate ? 'visit' : 'session',
      };
    }).sort((session1, session2) => new Date(session1.x) > new Date(session2.x));

    this.relapseRiskChartOptions = {
      title: {
        text: 'Risk of Relapse Over Time',
        subtext: this.decision_table.decision_name
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        },
        formatter: function (params) {
          const point = params[0].data;
          const date = new Date(point[0]);
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const year = date.getFullYear();
          const type = points.find(p => new Date(p.x).getTime() === point[0].getTime()).type;

          return `${day}/${month}/${year}<br/><br/>Risk: ${point[1]}%<br/>Type: ${type === 'visit' ? 'Visit' : 'Session'}`;
        }
      },
      toolbox: {
        show: true,
        feature: {
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'time',
        boundaryGap: false,
        data: points.map(point => new Date(point.x)),
      },
      yAxis: {
        type: 'value',
        min: 0,
        max: 100,
        axisLabel: {
          formatter: '{value} %'
        },
        axisPointer: {
          snap: true
        }
      },
      visualMap: {
        show: false,
        dimension: 1,
        pieces: [
          {gte: 0, lte: 33.33, color: 'green'},
          {gt: 33.33, lte: 66.66, color: 'yellow'},
          {gt: 66.66, lte: 100, color: 'red'}
        ],
        outOfRange: {
          color: ['red']
        }
      },
      series: [
        {
          name: 'Relapse Risk',
          type: 'line',
          smooth: true,
          data: points.map(point => [new Date(point.x), point.y]),
          markArea: {
            itemStyle: {
              color: 'rgba(173, 216, 230, 0.4)'
            },
            data: therapies?.map(therapy => {
              const end_date = therapy.end_date ? new Date(therapy.end_date) : new Date();
              return [
                {
                  name: therapy.action,
                  xAxis: new Date(therapy.start_date)
                },
                {
                  xAxis: end_date
                }
              ];
            }),
          },
          markPoint: {
            data: points.map(point => ({
              name: point.type === 'visit' ? 'Visit' : 'Session',
              value: point.type === 'visit' ? 'Visit' : 'Session',
              coord: [new Date(point.x), point.y],
              label: {
                formatter: '{b}'
              }
            }))
          }
        }
      ],
      grid: {
        top: '20%',
        bottom: '10%'
      }
    };

    // SINGLE CHECKS RISK RELAPSE CHART
    const sessions = evaluation_sessions.map(session => `Session ${session.evaluation_session}`);
    evaluation_sessions.forEach(session => {
      const criteria = session.proposals[0].criteria;
      criteria.forEach(criterion => {
        const title = criterion.title;
        const score = criterion.results?.decision_score || 0;

        if (!criterion.results && !session.score_recalculated) {
          session.score_recalculated = true;
          this.decisionService.calculateDashboardResults(session.id);
          this.toastService.info(
            this.translateService.instant('generic.dashboard-results-calculus'),
            this.translateService.instant('components.decision-table.decision-table-dashboard.recalculating-score-info', {session_number: session.evaluation_session})
          );
        }

        if (!this.criteriaMap.has(title)) {
          this.criteriaMap.set(title, []);
        }
        this.criteriaMap.get(title).push(score);
      });
    });
    // @ts-ignore
    // tslint:disable-next-line:radix
    const maxScore = Math.max(
      ...Array.from(this.criteriaMap.values())
        .reduce((acc, val) => acc.concat(val), []));

    this.relapseRiskChartSingleChecksOptions = {
      grid: {top: '55%'},
      tooltip: {
        trigger: 'axis',
        showContent: false
      },
      xAxis: {
        data: sessions
      },
      yAxis: {
        type: 'value',
        min: 0,
        max: maxScore
      },
      series: [
        ...Array.from(this.criteriaMap.entries()).map(([title, scores]) => ({
          name: title,
          type: 'line',
          data: scores,
          smooth: true,
          emphasis: {focus: 'series'}
        })),
        {
          type: 'pie',
          id: 'pie',
          radius: '30%',
          center: ['50%', '25%'],
          emphasis: {focus: 'self'},
          label: {
            formatter: '{b}: {@[0]} ({d}%)'
          },
          encode: {
            itemName: 'session',
            value: 0
          },
          data: Array.from(this.criteriaMap.entries()).map(([title, scores]) => ({
            name: title,
            value: scores[0]
          }))
        }
      ]
    };

  }

  onSingleCheckRelapseChartInit(chart: ECharts) {
    this.singleCheckRelapseChart = chart;
    chart.on('updateAxisPointer', (event) => this.handleUpdateAxisPointer(event));
  }

  handleUpdateAxisPointer(event: any) {
    const xAxisInfo = event.axesInfo[0];
    if (xAxisInfo) {
      const dimension = xAxisInfo.value;
      const updatedData = Array.from(this.criteriaMap.entries()).map(([title, scores]) => ({
        name: title,
        value: scores[dimension]
      }));

      this.singleCheckRelapseChart.setOption({
        series: [{
          id: 'pie',
          data: updatedData,
          label: {
            formatter: `{b}: {@[${dimension}]} ({d}%)`
          },
          encode: {
            value: dimension,
            tooltip: dimension
          }
        }]
      });
    }
  }

}
