import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private userService: UserService) { }

  ngOnInit(): void {
  }

  public getUserService() {
    return this.userService;
  }

  public getDecisionRoute() {
    return this.userService.currentOrganizationHasFeature('projects') ? '/projects' : '/decision/all';
  }

  get customs() {
    return JSON.parse(this.userService?.getCurrentOrganizationUser()?.customs);
  }

}
