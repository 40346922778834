<div class="container-fluid accordion-container" *ngIf="evaluation_session">
  <mat-accordion class="accordion" hideToggle>
    <mat-expansion-panel expanded disabled>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="index" *ngIf="show_indexes">1</span>
          <span class="font-weight-bold">{{ decision_table?.decision_name }}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="container">
        {{ 'pages.responses.welcome.row-1' | translate }}, {{ 'pages.responses.welcome.row-2-step-1' | translate }}
        <span
          style="text-transform: uppercase">"{{ decision_table?.decision_name }}
          "</span> {{ 'pages.responses.welcome.row-2-step-2' | translate }}
        . {{ 'pages.responses.welcome.row-3' | translate }}{{ evaluation_session?.evaluation_session }}
        . {{ 'pages.responses.welcome.row-4' | translate }}
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div class="container-fluid accordion-container">
  <mat-accordion class="accordion" hideToggle>
    <mat-expansion-panel expanded disabled>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="index" *ngIf="show_indexes">
            <span *ngIf="evaluation_session">2</span>
            <span *ngIf="!evaluation_session">1</span>
          </span>
          <span class="font-weight-bold">{{ 'pages.responses.welcome.problem-statement-thesis' | translate }}</span>
        </mat-panel-title>
        <div class="edit-icon-container" *ngIf="!isResponse">
          <button mat-icon-button (click)="editText(decision_table?.problem_statement?.thesis, 'thesis')">
            <mat-icon>edit</mat-icon>
          </button>
        </div>
      </mat-expansion-panel-header>
      <div class="container">
        <!--        <p>{{ decision_table?.problem_statement?.thesis }}</p>-->
        <app-formatted-text-preview [content]="decision_table?.problem_statement?.thesis"></app-formatted-text-preview>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div class="container-fluid accordion-container">
  <mat-accordion class="accordion mt-3" hideToggle>
    <mat-expansion-panel expanded disabled>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="index" *ngIf="show_indexes">
                        <span *ngIf="evaluation_session">3</span>
            <span *ngIf="!evaluation_session">2</span>
          </span>
          <span class="font-weight-bold">{{ 'pages.responses.welcome.problem-statement-context' | translate }}</span>
        </mat-panel-title>
        <div class="edit-icon-container" *ngIf="!isResponse">
          <button mat-icon-button (click)="editText(decision_table?.problem_statement?.context, 'context')">
            <mat-icon>edit</mat-icon>
          </button>
        </div>
      </mat-expansion-panel-header>
      <div class="container">
        <!--        <p>{{ decision_table?.problem_statement?.context }}</p>-->
        <app-formatted-text-preview [content]="decision_table?.problem_statement?.context"></app-formatted-text-preview>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div class="container-fluid accordion-container" *ngIf="decision_table?.customs?.thankYouPage && !isResponse">
  <mat-accordion class="accordion mt-3" hideToggle>
    <mat-expansion-panel expanded disabled>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="index" *ngIf="show_indexes">
                        <span *ngIf="evaluation_session">4</span>
            <span *ngIf="!evaluation_session">3</span>
          </span>
          <span class="font-weight-bold">{{ 'generic.thank-you-page-questionnaire' | translate }}</span>
        </mat-panel-title>
        <div class="edit-icon-container">
          <button mat-icon-button color="warn" (click)="removeThankYouPage()">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
        <div class="edit-icon-container">
          <button mat-icon-button (click)="editText(decision_table?.customs?.thankYouPage, 'thankYouPage')">
            <mat-icon>edit</mat-icon>
          </button>
        </div>
      </mat-expansion-panel-header>
      <div class="container">
        <app-formatted-text-preview [content]="decision_table?.customs?.thankYouPage"></app-formatted-text-preview>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div class="container-fluid accordion-container" *ngIf="getAttachmentsEvidences()?.length > 0">
  <mat-accordion class="accordion mt-3" hideToggle>
    <mat-expansion-panel expanded disabled>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="index" *ngIf="show_indexes">
                        <span *ngIf="evaluation_session">4</span>
            <span *ngIf="!evaluation_session">3</span>
          </span>
          <span class="font-weight-bold">{{ "generic.attachments" | translate }}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="container">
        <table *ngIf="getAttachmentsEvidences().length > 0" mat-table [dataSource]="getAttachmentsEvidences()"
               class="mat-elevation-z8 w-100 my-4">

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> {{
                "generic.title"
                  | translate
              }}
            </th>
            <td mat-cell *matCellDef="let element">
              <a [href]="element.link" target="_blank">{{ element.title }}</a></td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef> {{
                "generic.type"
                  | translate
              }}
            </th>
            <td mat-cell *matCellDef="let element"> {{ element.type }}</td>
          </ng-container>

          <ng-container matColumnDef="loaded_at">
            <th mat-header-cell *matHeaderCellDef> {{
                "generic.upload_date"
                  | translate
              }}
            </th>
            <td mat-cell *matCellDef="let element"> {{ element.created_at | formatdate | date : 'short' }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="attachmentsDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: attachmentsDisplayedColumns;"></tr>
        </table>

        <div class="row mt-2">
          <div class="col-4 mb-3" *ngFor="let image of getAttachmentImages()">
            <mat-card class="pb-0" style="cursor: pointer">
              <a [href]="image.link" target="_blank">
                <img
                  mat-card-image
                  [src]="image.link"
                />
              </a>
            </mat-card>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<ng-container *appHideIfOrganization="'alexion'">
  <div class="row justify-content-center mt-4" *ngIf="!isResponse && !decision_table?.customs?.thankYouPage">
    <button mat-button color="primary" (click)="editText(decision_table?.customs?.thankYouPage, 'thankYouPage')">
      <i class="icon-upload mr-2"></i>
      {{ 'components.decision-table.show-decision-table.congrats-page-add' | translate }}
    </button>
  </div>
</ng-container>
